import request from '@/utils/request'

export function page(params) {
    return request({
        url: '/api/app/game/pageAdvertise',
        method: 'get',
        params: params
    })
}

export function create(data) {
    return request({
        url: '/api/app/game/createAdvertise',
        method: 'post',
        data: data
    })
}


export function update(data) {
    return request({
        url: '/api/app/game/updateAdvertise',
        method: 'post',
        data: data
    })
}


export function remove(id) {
    return request({
        url: '/api/app/game/deleteAdvertise/' + id,
        method: 'delete'
    })
}
