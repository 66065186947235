import {userTreeView} from "@/api/menu";

const state = {
    userMenus: [],
    accountStatus: [
        {id: 'ACTIVE', value: 1, title: '正常'},
        {id: 'BANNED', value: 3, title: '禁用'},
        {id: 'DELETED', value: 4, title: '删除'},
        {id: 'INACTIVATED', value: 2, title: '申请中'}
    ],

    employeeType: [
        {id: 'SALEMAN', value: 1, title: '业务员'},
        {id: 'SURVEY', value: 2, title: '勘察员'},
        {id: 'WORKER', value: 3, title: '施工员'},
    ],

    userType: [
        {id: 'USER', value: 4, title: '用户'},
        {id: 'MESSENGER', value: 5, title: '信息员'},
    ],

    identityType: [
        {id: 'SALEMAN', value: 1, title: '业务员'},
        {id: 'SURVEY', value: 2, title: '勘察员'},
        {id: 'WORKER', value: 3, title: '施工员'},
        {id: 'USER', value: 4, title: '用户'},
        {id: 'MESSENGER', value: 5, title: '信息员'},
    ],

    orderState: [
        {id: 'WAIT_ASSIGN_SURVEY', value: 1, title: '待分配勘查员'},
        {id: 'WAIT_SURVEY', value: 2, title: '待勘查'},
        {id: 'WAIT_PAY_FRONT_MONEY', value: 3, title: '待付定金'},
        {id: 'WAIT_ASSIGN_BUILDER', value: 4, title: '待分配施工员'},
        {id: 'WAIT_BUILD', value: 5, title: '待施工'},
        {id: 'WAIT_PAY_BALANCE', value: 6, title: '待支付尾款'},
        {id: 'WAIT_EVAL', value: 7, title: '待评价'},
        {id: 'FINISH', value: 8, title: '完结'},
    ],

    withdrawStatus: [
        {id: 'CREATE', value: 1, title: '申请中'},
        {id: 'APPROVE', value: 2, title: '通过'},
        {id: 'DENY', value: 3, title: '拒绝'},
        {id: 'SUCCESS', value: 4, title: '成功'},
        {id: 'FAIL', value: 5, title: '失败'},
    ],
    /**
     * 标签导航栏
     */
    headerTabs: [],
    /**
     * 标签导航栏选中key
     */
    selectedHeaderTabName: null,
};

const mutations = {
    setUserMenus: function (state, data) {
        state.userMenus = data;
    },
    addHeaderTab: function (state, route) {
        let exist = state.headerTabs.find(r => r.path === route.path);
        if (!exist) {
            state.headerTabs.push(route);
        }
        state.selectedHeaderTabName = route.path;
    },
    removeHeaderTab: function (state, name) {
        if (state.headerTabs.length <= 1 && name === '/home') {
            return;
        }
        let index = state.headerTabs.findIndex(r => r.path === name);
        state.headerTabs = state.headerTabs.filter(r => r.path !== name);

        // TODO 非选中页签关闭，不能影响当前的选中页签
        index = Math.max(0, Math.min(state.headerTabs.length - 1, index));
        state.selectedHeaderTabName = state.headerTabs[index].path
    },
    cleanHeaderTabs: function (state) {
        state.headerTabs = [];
    },
};

const actions = {
    fetchUserMenu: function ({commit}) {
        userTreeView().then(response => {
            commit('setUserMenus', response);
        })
    },
    addHeaderTab: function ({commit}, route) {
        commit('addHeaderTab', route);
    },
    removeHeaderTab: function ({commit}, name) {
        commit('removeHeaderTab', name);
        return state.selectedHeaderTabName;
    },
    cleanHeaderTabs: function ({commit}) {
        commit('cleanHeaderTabs');
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
