<template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">
          <el-button class="action-button" @click="toAdd()" type="primary" size="medium"
                     style="margin-left: 4px">
            增加
          </el-button>
        </div>
      </div>
      <el-table :data="roomConfigList" border stripe class="table" ref="multipleTable" height="700">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-descriptions class="margin-top" title="牌局操作" :column="3" size="mini" border
                             style="margin-left: 10px; width: 1200px" :lable-style="{ width:'100px' }">
              <el-descriptions-item label="偷偷看">{{ props.row.exposeCardsFee }}</el-descriptions-item>
              <el-descriptions-item label="发发看">{{ props.row.rabbitHuntFee }}</el-descriptions-item>
              <el-descriptions-item label="增加时间">{{ props.row.addTimeFee }}</el-descriptions-item>
            </el-descriptions>

            <el-descriptions class="margin-top" title="Mining" :column="3" size="mini" border label-width="150px"
                             style="margin-left: 10px;width: 1200px" :lable-style="LS">
              <el-descriptions-item label="ChipsChance">{{ props.row.chipsChance }}</el-descriptions-item>
              <el-descriptions-item label="ChipsUpperLimit">{{ props.row.chipsUpperLimit }}</el-descriptions-item>
              <el-descriptions-item label="ChipsLowerLimit">{{ props.row.chipsLowerLimit }}</el-descriptions-item>

              <el-descriptions-item label="DiamondsChance">{{ props.row.diamondsChance }}</el-descriptions-item>
              <el-descriptions-item label="DiamondsUpperLimit">{{ props.row.diamondsUpperLimit }}</el-descriptions-item>
              <el-descriptions-item label="DiamondsLowerLimit">{{ props.row.diamondsLowerLimit }}</el-descriptions-item>

              <el-descriptions-item label="PusdLuckyChance">{{ props.row.pusdLuckyChance }}</el-descriptions-item>
              <el-descriptions-item label="PusdLuckyUpperLimit">{{
                  props.row.pusdLuckyUpperLimit
                }}
              </el-descriptions-item>
              <el-descriptions-item label="PusdLuckyLowerLimit">{{
                  props.row.pusdLuckyLowerLimit
                }}
              </el-descriptions-item>

              <el-descriptions-item label="PusdHitChance">{{ props.row.pusdHitChance }}</el-descriptions-item>
              <el-descriptions-item label="PusdHitBonus">{{ props.row.pusdHitBonus }}</el-descriptions-item>
            </el-descriptions>

            <el-descriptions class="margin-top" title="Rake" :column="3" size="mini" border
                             style="margin-left: 10px; width: 1200px" :lable-style="{ width:'100px' }">
              <el-descriptions-item label="RakePercent">{{ props.row.rakePercent }}</el-descriptions-item>
              <el-descriptions-item label="Rake 2-4 Players">{{ props.row.rakeLe4ChipsCap }}</el-descriptions-item>
              <el-descriptions-item label="Rake 4+ Players">{{ props.row.rakeGt4ChipsCap }}</el-descriptions-item>
              <el-descriptions-item label="PotLimit">{{ props.row.potLimit }}</el-descriptions-item>
            </el-descriptions>

          </template>
        </el-table-column>
        <el-table-column prop="id" label="ID" width="80" align="left"></el-table-column>
        <el-table-column prop="roomName" width="200" label="房间名称"></el-table-column>
        <el-table-column prop="currencyType" width="90" label="货币类型"></el-table-column>
        <el-table-column prop="clientSocketAddress" width="180" label="客户端socket地址"></el-table-column>
        <el-table-column prop="robotSocketAddress" width="180" label="机器人socket地址"></el-table-column>
        <el-table-column prop="apiAddress" width="200" label="ApiAddress"></el-table-column>
        <el-table-column prop="roomType" width="90" label="房间分类"></el-table-column>
        <el-table-column prop="maxNum" width="120" label="最多房间数量"></el-table-column>
        <el-table-column prop="fullCreateCid" width="140" label="满员创建房间ID"></el-table-column>
        <el-table-column prop="sb" width="100" label="小盲注"></el-table-column>
        <el-table-column prop="bb" width="100" label="大盲注"></el-table-column>
        <el-table-column prop="straddle" width="100" label="抓头"></el-table-column>
        <el-table-column prop="minChaips" width="100" label="最小筹码"></el-table-column>
        <el-table-column prop="maxChaips" width="100" label="最大筹码"></el-table-column>
        <el-table-column prop="minPlayers" width="100" label="开局人数"></el-table-column>
        <el-table-column prop="maxPlayers" width="110" label="最多开局人数"></el-table-column>
        <el-table-column prop="keepLive" width="110" label="保活时间(秒)"></el-table-column>
        <el-table-column prop="actionTimeOut" width="110" label="超时时间(秒)"></el-table-column>
        <el-table-column prop="robotAmount" width="100" label="机器人数量"></el-table-column>
        <el-table-column prop="robotAiList" width="120" label="机器人AI数量">
          <template slot-scope="props">
            {{ props.row.robotAiList }}
          </template>

        </el-table-column>
        <el-table-column
            width="100"
            label="操作"
            fixed="right">
          <template slot-scope="scope">
            <el-button
                @click="toUpdate(scope.row)"
                type="text" size="small">编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog :before-close="handleClose" :modal="true" :title="dialogState.title"
               :visible.sync="dialogState.show" v-if="dialogState.show">
      <div style="width: 96%;">
        <el-form label-position="right" ref="objItem" :model="objItem" :rules="rules" label-width="160px" size="mini"
                 :inline="true">
          <el-form-item label="ID" prop="id" v-if="dialogState.update">
            <el-input v-model="objItem.id" disabled></el-input>
          </el-form-item>
          <el-form-item label="房间名" prop="roomName">
            <el-input v-model="objItem.roomName"></el-input>
          </el-form-item>
          <el-form-item label="客户端ws地址" prop="clientSocketAddress">
            <el-input v-model="objItem.clientSocketAddress"></el-input>
          </el-form-item>
          <el-form-item label="机器人ws地址" prop="robotSocketAddress">
            <el-input v-model="objItem.robotSocketAddress"></el-input>
          </el-form-item>
          <el-form-item label="Api地址" prop="apiAddress">
            <el-input v-model="objItem.apiAddress"></el-input>
          </el-form-item>
          <el-form-item label="货币类型" prop="currencyType">
            <el-select v-model="objItem.currencyType" placeholder="选择" :disabled="dialogState.update">
              <el-option
                  v-for="item in currencyType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="筛选类型" prop="roomType">
            <el-select v-model="objItem.roomType" clearable placeholder="选择">
              <el-option
                  v-for="item in roomType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="房间数量" prop="maxNum">
            <el-input v-model="objItem.maxNum"></el-input>
          </el-form-item>
          <el-form-item label="满员创建房间ID" prop="fullCreateCid">
            <el-input v-model="objItem.fullCreateCid"></el-input>
          </el-form-item>
          <el-form-item label="前注" prop="ante">
            <el-input v-model="objItem.ante"></el-input>
          </el-form-item>
          <el-form-item label="小盲注" prop="sb">
            <el-input v-model="objItem.sb"></el-input>
          </el-form-item>
          <el-form-item label="大盲注" prop="bb">
            <el-input v-model="objItem.bb"></el-input>
          </el-form-item>
          <el-form-item label="抓头" prop="straddle">
            <el-input v-model="objItem.straddle"></el-input>
          </el-form-item>
          <el-form-item label="最小筹码" prop="minChaips">
            <el-input v-model="objItem.minChaips"></el-input>
          </el-form-item>
          <el-form-item label="最大筹码" prop="maxChaips">
            <el-input v-model="objItem.maxChaips"></el-input>
          </el-form-item>
          <el-form-item label="最小人数" prop="minPlayers">
            <el-input v-model="objItem.minPlayers"></el-input>
          </el-form-item>
          <el-form-item label="最大人数" prop="maxPlayers">
            <el-input v-model="objItem.maxPlayers"></el-input>
          </el-form-item>
          <el-form-item label="旁观人数" prop="maxGuests">
            <el-input v-model="objItem.maxGuests"></el-input>
          </el-form-item>
          <el-form-item label="房间保活时间" prop="keepLive">
            <el-input v-model="objItem.keepLive"></el-input>
          </el-form-item>
          <el-form-item label="动作超时时间" prop="actionTimeOut">
            <el-input v-model="objItem.actionTimeOut"></el-input>
          </el-form-item>

          <el-form-item label="偷偷看Fee" prop="exposeCardsFee">
            <el-input v-model="objItem.exposeCardsFee"></el-input>
          </el-form-item>
          <el-form-item label="发发看Fee" prop="rabbitHuntFee">
            <el-input v-model="objItem.rabbitHuntFee">
              <template slot="append">List列表</template>
            </el-input>
          </el-form-item>
          <el-form-item label="增加时间Fee" prop="addTimeFee">
            <el-input v-model="objItem.addTimeFee">
              <template slot="append">List列表</template>
            </el-input>
          </el-form-item>

          <el-form-item label="ChipsChance" prop="chipsChance">
            <el-input v-model="objItem.chipsChance"></el-input>
          </el-form-item>
          <el-form-item label="ChipsUpperLimit" prop="chipsUpperLimit">
            <el-input v-model="objItem.chipsUpperLimit"></el-input>
          </el-form-item>
          <el-form-item label="ChipsLowerLimit" prop="chipsLowerLimit">
            <el-input v-model="objItem.chipsLowerLimit"></el-input>
          </el-form-item>
          <el-form-item label="DiamondsChance" prop="diamondsChance">
            <el-input v-model="objItem.diamondsChance"></el-input>
          </el-form-item>
          <el-form-item label="DiamondsUpperLimit" prop="diamondsUpperLimit">
            <el-input v-model="objItem.diamondsUpperLimit"></el-input>
          </el-form-item>
          <el-form-item label="DiamondsLowerLimit" prop="diamondsLowerLimit">
            <el-input v-model="objItem.diamondsLowerLimit"></el-input>
          </el-form-item>
          <el-form-item label="PusdLuckyChance" prop="pusdLuckyChance">
            <el-input v-model="objItem.pusdLuckyChance"></el-input>
          </el-form-item>
          <el-form-item label="PusdLuckyUpperLimt" prop="pusdLuckyUpperLimit">
            <el-input v-model="objItem.pusdLuckyUpperLimit"></el-input>
          </el-form-item>
          <el-form-item label="PusdLuckyLowerLimit" prop="pusdLuckyLowerLimit">
            <el-input v-model="objItem.pusdLuckyLowerLimit"></el-input>
          </el-form-item>
          <el-form-item label="PusdHitChance" prop="pusdHitChance">
            <el-input v-model="objItem.pusdHitChance"></el-input>
          </el-form-item>
          <el-form-item label="PusdHitBonus" prop="pusdHitBonus">
            <el-input v-model="objItem.pusdHitBonus"></el-input>
          </el-form-item>

          <el-form-item label="RakePercent" prop="rakePercent">
            <el-input v-model="objItem.rakePercent"></el-input>
          </el-form-item>
          <el-form-item label="Rake 2-4 ChipsCap" prop="rakeLe4ChipsCap">
            <el-input v-model="objItem.rakeLe4ChipsCap"></el-input>
          </el-form-item>
          <el-form-item label="Rake 4+ ChipsCap" prop="rakeGt4ChipsCap">
            <el-input v-model="objItem.rakeGt4ChipsCap"></el-input>
          </el-form-item>
          <el-form-item label="PotLimit" prop="potLimit">
            <el-input v-model="objItem.potLimit"></el-input>
          </el-form-item>
          <el-form-item label="机器人数量" prop="robotAmount">
            <el-input v-model="objItem.robotAmount"></el-input>
          </el-form-item>
          <el-form-item label="机器人AI数量" prop="robotAiList">
            <el-input v-model="objItem.robotAiList">
              <template slot="append">List列表</template>
            </el-input>
          </el-form-item>
          <el-form-item label="描述" prop="details">
            <el-input v-model="objItem.details"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">
          <el-button size="mini" @click="handleClose">取消</el-button>
          <el-button v-if="dialogState.update" size="mini" @click="updateRoomConfig()" type="primary">修改</el-button>
          <el-button v-if="!dialogState.update" size="mini" @click="addConfig()" type="primary">增加</el-button>
        </div>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import moment from "moment";
// import {regionDataPlus} from 'element-china-area-data'
import {regionDataPlus} from 'element-china-area-data'
import {addRoomConfig, roomConfigList, updateRoomConfig} from "../../../api/hall";

export default {
  name: "RoomConfigList",

  data() {
    return {
      options: regionDataPlus,
      roomConfigList: [],

      // searchObj: {
      //   userId: null,
      //   isChain: null,
      //   mtSn: null
      // },
      // pagingOption: {
      //   page: 1,
      //   limit: 10,
      //   total: 0,
      // },

      objItem: {
        sb: 0,
      },

      // delMtItem: {
      //   id: null,
      //   mtSn: null,
      //   userId: null,
      //   reason: false,
      // },

      dialogState: {
        show: false,
        title: "修改房间配置",
        update: false
      },

      currencyType: [{
        value: 'COIN',
        label: 'COIN'
      }, {
        value: 'GOLD',
        label: 'GOLD'
      }],

      roomType: [
        {value: 'FREE', label: 'FREE'},
        {value: 'SMALL', label: 'SMALL'},
        {value: 'MIDDLE', label: 'MIDDLE'},
        {value: 'LARGE', label: 'LARGE'},
      ],

      // delDialogState: {
      //   show: false,
      //   title: "删除MT",
      //   update: false
      // },
      rules: {
        ante: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        // ante: [{required: true, message: '不能为空', trigger: 'blur'}],
        sb: [{required: true, message: '不能为空', trigger: 'blur'}],
        bb: [{required: true, message: '不能为空', trigger: 'blur'}],
        straddle: [{required: true, message: '不能为空', trigger: 'blur'}],
        minChaips: [{required: true, message: '不能为空', trigger: 'blur'}],
        maxChaips: [{required: true, message: '不能为空', trigger: 'blur'}],
        chipsChance: [{required: true, message: '不能为空', trigger: 'blur'}],
        chipsUpperLimit: [{required: true, message: '不能为空', trigger: 'blur'}],
        chipsLowerLimit: [{required: true, message: '不能为空', trigger: 'blur'}],
        diamondsChance: [{required: true, message: '不能为空', trigger: 'blur'}],
        diamondsUpperLimit: [{required: true, message: '不能为空', trigger: 'blur'}],
        diamondsLowerLimit: [{required: true, message: '不能为空', trigger: 'blur'}],
        pusdLuckyChance: [{required: true, message: '不能为空', trigger: 'blur'}],
        pusdLuckyLowerLimit: [{required: true, message: '不能为空', trigger: 'blur'}],
        pusdLuckyUpperLimit: [{required: true, message: '不能为空', trigger: 'blur'}],
        pusdHitChance: [{required: true, message: '不能为空', trigger: 'blur'}],
        pusdHitBonus: [{required: true, message: '不能为空', trigger: 'blur'}],
        rakePercent: [{required: true, message: '不能为空', trigger: 'blur'}],
        rakeLe4ChipsCap: [{required: true, message: '不能为空', trigger: 'blur'}],
        potLimit: [{required: true, message: '不能为空', trigger: 'blur'}],
        rakeGt4ChipsCap: [{required: true, message: '不能为空', trigger: 'blur'}],
        robotAmount: [{required: true, message: '不能为空', trigger: 'blur'}],
        details: [{required: true, message: '不能为空', trigger: 'blur'}],
        roomName: [{required: true, message: '不能为空', trigger: 'blur'}],
        socketAddress: [{required: true, message: '不能为空', trigger: 'blur'}],
        apiAddress: [{required: true, message: '不能为空', trigger: 'blur'}],
        currencyType: [{required: true, message: '不能为空', trigger: 'blur'}],
        maxNum: [{required: true, message: '不能为空', trigger: 'blur'}],
        minPlayers: [{required: true, message: '不能为空', trigger: 'blur'}],
        maxPlayers: [{required: true, message: '不能为空', trigger: 'blur'}],
        keepLive: [{required: true, message: '不能为空', trigger: 'blur'}],
        actionTimeOut: [{required: true, message: '不能为空', trigger: 'blur'}],
        exposeCardsFee: [{required: true, message: '不能为空', trigger: 'blur'}],
        rabbitHuntFee: [{required: true, message: '不能为空', trigger: 'blur'}],
        addTimeFee: [{required: true, message: '不能为空', trigger: 'blur'}],
        roomType: [{required: true, message: '不能为空', trigger: 'blur'}],
        maxGuests: [{required: true, message: '不能为空', trigger: 'blur'}],
        robotAiList: [{required: true, message: '不能为空', trigger: 'blur'}],
        fullCreateCid: [{required: true, message: '不能为空', trigger: 'blur'}],
        clientSocketAddress: [{required: true, message: '不能为空', trigger: 'blur'}],
        robotSocketAddress: [{required: true, message: '不能为空', trigger: 'blur'}],

      },
      LS: {
        'color': '#000',
        'text-align': 'center',
        'font-weight': '600',
        'height': '40px',
        'background-color': 'rgba(255, 97, 2, 0.1)',
        'min-width': '110px',
        'word-break': 'keep-all'
      }

    };
  },
  created() {
    this.getRoomConfigList();
  },

  methods: {
    handleChange(value) {
      console.log(value)
    },
    search: function () {
      this.getRoomConfigList();
    },
    getRoomConfigList: function () {
      this.listLoading = true;

      return roomConfigList().then((response) => {
        console.log(response.rows);
        this.roomConfigList = response;
        this.listLoading = false;
      });
    },

    toUpdate(obj) {
      this.dialogState.show = true;
      this.dialogState.update = true;
      this.dialogState.title = "修改数据";
      this.objItem = Object.assign({}, obj);
    },
    toAdd(obj) {
      this.dialogState.title = "增加数据";
      this.dialogState.show = true;
      this.dialogState.update = false;
      this.objItem = Object.assign({}, obj);
    },

    updateRoomConfig() {

      this.$refs['objItem'].validate((valid) => {
        if (valid) {
          this.$confirm("确定更新？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.objItem)

            try {
              req.rabbitHuntFee = this.objItem.rabbitHuntFee.toString().split(",");// JSON.parse(this.objItem.rabbitHuntFee);
              req.addTimeFee = this.objItem.addTimeFee.toString().split(","); //JSON.parse(this.objItem.addTimeFee);
              req.robotAiList = this.objItem.robotAiList.toString().split(","); //JSON.parse(this.objItem.addTimeFee);
            } catch (e) {
              console.log(e);
              this.$message({
                type: 'error',
                message: '偷偷看或增加时间List格式配置异常!',
              });
              return;
            }
            updateRoomConfig(req).then(() => {
              this.$message({
                type: 'success',
                message: '更新成功!',
              });
              this.dialogState.show = false;
              this.getRoomConfigList();
            });
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    addConfig() {
      this.$refs['objItem'].validate((valid) => {
        if (valid) {
          this.$confirm("确定增加？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.objItem)
            console.log(req);

            try {
              req.rabbitHuntFee = JSON.parse(this.objItem.rabbitHuntFee);
              req.addTimeFee = JSON.parse(this.objItem.addTimeFee);
            } catch (e) {
              this.$message({
                type: 'error',
                message: '偷偷看或增加时间List格式配置异常!',
              });
            }

            console.log(req);
            addRoomConfig(req).then(() => {
              this.$message({
                type: 'success',
                message: '成功!',
              });
              this.dialogState.show = false;
              this.getRoomConfigList();
            });
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
    handleClose() {
      this.dialogState.show = false
    },
  }
}
</script>
<style>

.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>
