<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card shadow="hover" class="mgb20">
          <div class="user-info">
            <el-avatar :size="100"
                       src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"/>
            <div class="user-info-cont">
              <div class="user-info-name">{{ (user || {}).name }}</div>
              <!--              <div>{{ role }}</div>-->
            </div>
          </div>
          <div class="user-info-list">
            平台管理后台地址(内网)：
            <a href="http://192.168.10.179:8801/dashboard" target="_blank">http://192.168.10.179:8801/dashboard</a>
          </div>
          <div class="user-info-list" style="margin-top: 10px">
            平台管理后台地址(正式)：
            <a href="http://dashboard.moontouch.io:8801/login" target="_blank">http://dashboard.moontouch.io:8801/login</a>
          </div>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-row :gutter="20" class="mgb20">
          <el-col :span="8">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-1">
                <i class="grid-con-icon el-icon-user-solid">
                </i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{ countInfo.userCount }}</div>
                  <div>玩家数量</div>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-2">
                <i class="grid-con-icon el-icon-s-platform">
                </i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{ countInfo.chainAmount }}</div>
                  <div>上链MT数量</div>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-3">
                <i class="grid-con-icon el-icon-monitor">
                </i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{ countInfo.mtAmountCount }}</div>
                  <div>MT总数量</div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import {homeIndex} from "@/api/count";

export default {
  name: "Welcome",
  data() {
    return {
      countInfo: {
        userCount: 0,
        mtAmountCount: 0,
        chainAmount: 0
      }
    };
  },

  created() {
    this.getCountInfo();
  },
  methods: {
    getCountInfo: function () {
      this.listLoading = true;
      return homeIndex().then((response) => {
        this.countInfo = response
        this.listLoading = false;
      });
    },

  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  }
}
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.grid-content {
  display: flex;
  align-items: center;
  height: 100px;
}

.grid-cont-right {
  flex: 1;
  text-align: center;
  font-size: 14px;
  color: #999;
}

.grid-num {
  font-size: 30px;
  font-weight: bold;
}

.grid-con-icon {
  font-size: 20px;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  color: #fff;
}

.grid-con-1 .grid-con-icon {
  background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
  background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
  color: rgb(100, 213, 114);
}

.grid-con-3 .grid-con-icon {
  background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
  color: rgb(242, 94, 67);
}

.user-info {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
}

.user-info-cont {
  padding-left: 50px;
  flex: 1;
  font-size: 14px;
  color: #999;
}

.user-info-cont div:first-child {
  font-size: 30px;
  color: #222;
}

.user-info-list {
  font-size: 14px;
  color: #999;
  line-height: 25px;
}

.mgb20 {
  margin-bottom: 20px;
}

.todo-item {
  font-size: 14px;
}

.todo-item-del {
  text-decoration: line-through;
  color: #999;
}

.schart {
  width: 100%;
  height: 300px;
}
</style>
