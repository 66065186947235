<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>角色管理</span>
        <el-button class="action-button" type="text" @click="toCreate"
          >新增角色</el-button
        >
      </div>
      <SearchPanel :keyword="keyword" @onSearch="onSearch" />
      <el-table :data="list" row-key="id" border stripe style="width: 100%">
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="title" label="名称" width="180">
        </el-table-column>
        <el-table-column prop="opIds" label="拥有权限">
          <template slot-scope="scope">
            <el-tag
              style="margin: 4px"
              :type="searchMenu(id).isMenuGroup ? '' : 'info'"
              v-for="id in scope.row.opIds"
              :key="id"
              size="mini"
            >
              {{ searchMenu(id).title }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!--                        <el-button @click="detail(scope.row)" type="primary" round size="small">查看</el-button>-->
            <el-button
              @click="toEdit(scope.row)"
              type="warning"
              round
              size="small"
              >编辑</el-button
            >
            <el-button
              @click="remove(scope.row)"
              type="danger"
              round
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <pagination
      v-show="pagingOption.total > 0"
      :total="pagingOption.total"
      :page.sync="pagingOption.page"
      :limit.sync="pagingOption.limit"
      @pagination="getList"
    />
    <el-dialog title="新增角色" :visible.sync="showCreateDialog">
      <div>
        <el-form
          label-position="left"
          :model="newRoleItem"
          ref="roleCreateForm"
          :rules="rules"
          label-width="80px"
        >
          <el-form-item label="名称" prop="title">
            <el-input v-model="newRoleItem.title"></el-input>
          </el-form-item>
          <el-form-item label="关联菜单">
            <div style="clear: both">
              <SysMenuTreeCtrl
                :nodes="menuTree"
                :multiple-check="true"
                :check-enable="true"
                :expand-by-default="true"
                :checkedNodeIdsByDefault="associatedMenuIds"
                @onNodeSelectChange="handleCheckChange"
              />
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCreateDialog = false">取 消</el-button>
        <el-button type="primary" @click="create">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="编辑权限" :modal="true" :visible.sync="showEditDialog">
      <div>
        <el-form
          label-position="left"
          :model="newRoleItem"
          ref="roleEditForm"
          :rules="rules"
          label-width="80px"
        >
          <el-form-item label="标题" prop="title">
            <el-input v-model="newRoleItem.title"></el-input>
          </el-form-item>
          <el-form-item label="关联菜单">
            <div style="clear: both">
              <SysMenuTreeCtrl
                :nodes="menuTree"
                :multiple-check="true"
                :check-enable="true"
                :expand-by-default="true"
                :checkedNodeIdsByDefault="associatedMenuIds"
                @onNodeSelectChange="handleCheckChange"
              />
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showEditDialog = false">取 消</el-button>
        <el-button type="primary" @click="edit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { menuList, treeView } from "@/api/menu";
import SysMenuTreeCtrl from "@/components/system/SysMenuTreeControl";
import { createRole, removeRole, rolePage, updateRole } from "@/api/role";
import SearchPanel from "@/components/common/SearchPanel";
import Pagination from "@/components/common/Pagination";
export default {
  name: "RoleList",
  components: { SearchPanel, SysMenuTreeCtrl, Pagination },
  data() {
    return {
      keyword: null,
      menuList: [],
      menuTree: [],
      listLoading: false,
      list: [],

      pagingOption: {
        page: 1,
        limit: 10,
        total: 0,
      },

      newRoleItem: {},
      // 用于菜单子组件数据同步
      associatedMenuIds: [],
      showCreateDialog: false,
      showEditDialog: false,

      rules: {
        title: [
          { required: true, message: "请输入名称！", trigger: "blur" },
          { min: 1, max: 20, message: "长度有误[3~20]", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getMenusTree().then(() => {
      this.getMenuList().then(() => this.getList());
    });
  },
  methods: {
    onSearch: function (keyword) {
      this.getList(null, keyword);
    },
    getMenusTree() {
      return treeView().then((response) => {
        this.menuTree = response;
      });
    },
    getMenuList() {
      return menuList().then((response) => {
        this.menuList = response;
      });
    },
    getList(pageNumber, keyword) {
      this.listLoading = true;
      let pageRequest = {
        pageNumber: this.pagingOption.page,
        pageSize: this.pagingOption.limit,
        keyword: keyword,
      };
      return rolePage(pageRequest).then((response) => {
        this.list = response.rows;
        this.pagingOption.total = response.total;
        this.listLoading = false;
      });
    },
    toCreate: function () {
      this.newRoleItem = {};
      this.showCreateDialog = true;
      this.associatedMenuIds = [];
      // console.log('set pid ' + this.editMenuItemParentId);
    },
    toEdit: function (obj) {
      this.newRoleItem = Object.assign({}, obj);
      this.associatedMenuIds = this.newRoleItem.opIds;
      this.showEditDialog = true;
    },
    create: function () {
      this.$confirm("确定创建角色？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        this.$refs.roleCreateForm.validate((result) => {
          if (result) {
            if (
              !this.newRoleItem.title ||
              this.newRoleItem.title.trim().length <= 0
            ) {
              this.$message.error("需要名称！");
              return;
            }
            if (!this.newRoleItem.opIds) {
              this.newRoleItem.opIds = [];
            }
            createRole(this.newRoleItem).then(() => {
              this.$message({
                type: "success",
                message: "创建成功!",
              });
              this.$store.dispatch("sys/fetchUserMenu");
              this.getList();
              this.newRoleItem = {};
              this.associatedMenuIds = [];
              this.showCreateDialog = false;
            });
          }
        });
      });
    },
    edit: function () {
      this.$confirm("确定提交修改？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        this.$refs.roleEditForm.validate((result) => {
          if (result) {
            if (
              this.newRoleItem.title === undefined ||
              this.newRoleItem.title.trim().length <= 0
            ) {
              this.$message.error("需要名称！");
              return;
            }
            if (!this.newRoleItem.opIds) {
              this.newRoleItem.opIds = [];
            }
            updateRole(this.newRoleItem).then(() => {
              this.$message({
                type: "success",
                message: "修改成功!",
              });
              this.$store.dispatch("sys/fetchUserMenu");
              this.getList();
              this.newRoleItem = {};
              this.showEditDialog = false;
            });
          }
        });
      });
    },
    remove: function (obj) {
      this.$confirm("确定删除？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        center: true,
      })
        .then(() => {
          removeRole(obj.id).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.$store.dispatch("sys/fetchUserMenu");
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleCheckChange: function (node, checked, selected) {
      if (!selected) {
        selected = [];
      }
      this.newRoleItem.opIds = selected
        .filter((m) => !m.isMenuGroup)
        .map((m) => m.id);
    },
    searchMenu: function (id) {
      // for (let i = 0; i < this.menuTree.length; i++) {
      //     let menu = this.menuTree[i];
      //     let searchResult = this.searchMenuItemById(id, menu);
      //     if (searchResult) {
      //         return searchResult;
      //     }
      // }
      // return null;
      return this.menuList.find((m) => m.id === id) || {};
    },
    // searchMenuItemById: function (id, searchRoot) {
    //     if (searchRoot.id === id) {
    //         return searchRoot;
    //     }
    //     if (!searchRoot.children) {
    //         return null;
    //     }
    //     for (let i = 0; i < searchRoot.children.length; i++) {
    //         let child = searchRoot.children[i];
    //         let searchResult = this.searchMenuItemById(id, child);
    //         if (searchResult) {
    //             return searchResult;
    //         }
    //     }
    //     return null;
    // },
  },
};
</script>

<style>
</style>
