import Router from 'vue-router'
import MenuList from "../views/menu/MenuList";
import RoleList from "@/views/role/RoleList";
import UserList from "@/views/user/UserList";
import Login from "@/Login";
import Home from "@/Home";
import Welcome from "@/views/Welcome";
import OperationList from "@/views/operation/OperationList";
import Time from "@/views/time/time";

import NoticeList from "@/views/agent/noticeList";
import VersionList from "@/views/agent/versionList";
import RedirectDeviceWhitelist from "@/views/agent/redirectDeviceWhitelist";
import ClientException from "@/views/agent/clientException";

import AppUserList from "@/views/appUser/appUserList";
import UserMtList from "@/views/appUser/userMtList";
import UserBoxesList from "@/views/appUser/userBoxesList";
import UserIntegralList from "@/views/appUser/userIntegralList";
import UserWalletAddressList from "@/views/appUser/userWalletAddressList";

import UserCurrencyRecord from "@/views/appUser/userCurrencyRecord";
import UserDepositNftRecord from "@/views/appUser/userDepositNftRecord";
import UserWithdrawCurrencyRecord from "@/views/appUser/userWithdrawCurrencyRecord";
import App2Platform from "@/views/sync/app2Platform";
import Platform2App from "@/views/sync/platform2App";
import SendMtToUser from "@/views/appUser/sendMtToUser";
import WithdrawLimitUserList from "@/views/appUser/withdrawUserLimitList";
import UserInviteCode from "@/views/appUser/userInviteCode";
import WithdrawCurrencyProgress from "@/views/appUser/withdrawCurrencyProgress";
import SystemWithdrawList from "@/views/game/systemWithdrawList";
import SystemExchangeUsdtList from "@/views/game/systemExchangeUsdtList";
import WithdrawSetting from "@/views/game/withdrawSetting";

import GameConfig from "@/views/game/gameConfig";
import MailList from "@/views/game/mailList";
import WhiteUserList from "@/views/agent/whiteUserList.vue";
import DeviceWhitelist from "@/views/agent/deviceWhitelist.vue";
import RedisList from "@/views/game/redisList.vue";
import BurnSetting from "@/views/game/burnSetting.vue";
import SnifferList from "@/views/game/snifferList.vue";
import Statistics from "@/views/statistics/statistics.vue";
import Cdk from "@/views/cdk/cdk.vue";
import CdkUsage from "@/views/cdk/cdkUsage.vue";
import Advertise from "@/views/advertise/advertise";
import RoomConfigList from "../views/gc/hall/roomConfigList";
import RechargeList from "../views/gc/payment/rechargeList";
import ExchangeList from "../views/gc/payment/exchangeList";
import ActivityItemList from "../views/gc/hall/activityItemList";
import CheckinConfigList from "../views/gc/hall/checkinConfigList";
import RoomRecordList from "../views/gc/hall/roomRecordList";
import ExchangeOrderList from "@/views/gc/payment/exchangeOrderList";
import PlayerList from "@/views/gc/auth/playerList";

const routes = [
    {
        // 登录
        path: '/login',
        component: Login,
        name: 'login',
        meta: {
            skipAuth: true,
        }
    },
    {
        // 主页
        path: '/home',
        component: Home,
        name: 'home',
        children: [
            {
                // 模板列表
                path: '/',
                component: Welcome,
                name: '系统首页',
            },
            {
                // 菜单列表
                path: '/menu',
                component: MenuList,
                name: 'menu',
            },
            {
                // 角色列表
                path: '/role',
                component: RoleList,
                name: 'role',
            },
            {
                // dashboard用户列表
                path: '/user',
                component: UserList,
                name: 'user',
            },
            {
                // 权限列表
                path: '/op',
                component: OperationList,
                name: 'op',
            },

            {
                // 时间管理
                path: '/time',
                component: Time,
                name: 'time',
            },
            {
                //公告列表
                path: '/noticeList',
                component: NoticeList,
                name: 'noticeList',
            },
            {
                //公告列表
                path: '/versionList',
                component: VersionList,
                name: 'versionList',
            },
            {
                //app用户管理
                path: '/appUserList',
                component: AppUserList,
                name: 'appUserList',
            },
            {
                path: '/userMtList',
                component: UserMtList,
                name: '用户MT列表',
            },
            {
                path: '/userBoxesList',
                component: UserBoxesList,
                name: '用户盲盒列表',
            },
            {
                path: '/userIntegralList',
                component: UserIntegralList,
                name: '积分记录',
            },
            {
                path: '/userWalletAddressList',
                component: UserWalletAddressList,
                name: '收货地址',
            },
            {
                path: '/sendMtToUser',
                component: SendMtToUser,
                name: '发送MT给用户',
            },
            {
                path: '/app2Platform',
                component: App2Platform,
                name: '应用至平台',
            },

            {
                path: '/platform2App',
                component: Platform2App,
                name: '平台至应用',
            },

            {
                path: '/userCurrencyRecord',
                component: UserCurrencyRecord,
                name: '用户货币记录',
            },

            {
                path: '/userDepositNftRecord',
                component: UserDepositNftRecord,
                name: '导入NFT记录',
            },

            {
                path: '/userWithdrawCurrencyRecord',
                component: UserWithdrawCurrencyRecord,
                name: '货币提取记录',
            },

            {
                path: '/mailList',
                component: MailList,
                name: 'mailList',
            },

            {
                path: '/whiteUserList',
                component: WhiteUserList,
                name: 'whiteUserList',
            },

            {
                path: '/deviceWhitelist',
                component: DeviceWhitelist,
                name: 'deviceWhitelist',
            },

            {
                //游戏配置
                path: '/gameConfig',
                component: GameConfig,
                name: 'gameConfig',
            },
            {
                //redis数据
                path: '/redisList',
                component: RedisList,
                name: 'redisList',
            },
            {
                //游戏配置
                path: '/burnSetting',
                component: BurnSetting,
                name: 'burnSetting',
            },
            {
                //游戏配置
                path: '/snifferList',
                component: SnifferList,
                name: 'snifferList',
            },
            {
                path: '/withdrawUserLimitList',
                component: WithdrawLimitUserList,
                name: '提取进度限制',
            },
            {
                path: '/withdrawCurrencyProgress',
                component: WithdrawCurrencyProgress,
                name: '提取货币进度列表',
            },
            {
                path: '/redirectDeviceWhitelist',
                component: RedirectDeviceWhitelist,
                name: '重定向设备白名单',
            },
            {
                path: '/clientException',
                component: ClientException,
                name: '客户端错误收集',
            },
            {
                path: '/statistics',
                component: Statistics,
                name: '数据统计',
            },
            {
                path: '/userInviteCode',
                component: UserInviteCode,
                name: '用户邀请码',
            },
            {
                path: '/cdk',
                component: Cdk,
                name: 'CDK',
            },
            {
                path: '/cdkUsage',
                component: CdkUsage,
                name: 'CDK使用情况',
            },
            {
                path: '/advertise',
                component: Advertise,
                name: '广告配置',
            },
            {
                path: '/systemWithdrawList',
                component: SystemWithdrawList,
                name: '系统提取',
            },
            {
                path: '/withdrawSetting',
                component: WithdrawSetting,
                name: '提取设置',
            },
            {
                path: '/systemExchangeUsdtList',
                component: SystemExchangeUsdtList,
                name: '系统兑换',
            },

            {
                path: '/roomConfigList',
                component: RoomConfigList,
                name: '房间配置',
            },
            {
                path: '/roomRecordList',
                component: RoomRecordList,
                name: '房间列表',
            },
            {
                path: '/rechargeList',
                component: RechargeList,
                name: '充值级别列表',
            },
            {
                path: '/exchangeList',
                component: ExchangeList,
                name: '兑换列表',
            },
            {
                path: '/activityItemList',
                component: ActivityItemList,
                name: '活动列表',
            },
            {
                path: '/checkinConfigList',
                component: CheckinConfigList,
                name: '兑换列表',
            },
            {
                path: '/exchangeOrderList',
                component: ExchangeOrderList,
                name: '兑换订单列表',
            },
            {
                path: '/playerList',
                component: PlayerList,
                name: '玩家列表',
            },

        ]
    },
    {path: '/', name: 'base', redirect: '/login'},
];

const router = new Router({
    mode: 'history',
    routes: routes
});

export default router;
