<template>
  <div>
    <el-card class="box-card">
      <!--      <div style="display: flex;justify-content: space-between;">-->
      <!--        <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">-->

      <!--          <el-input-->
      <!--              size="medium"-->
      <!--              placeholder="输入用户ID"-->
      <!--              v-model="searchObj.userId" clearable-->
      <!--              style=" margin-left: 4px">-->
      <!--          </el-input>-->

      <!--          <el-input-->
      <!--              size="medium"-->
      <!--              placeholder="输入MT序号"-->
      <!--              v-model="searchObj.mtSn" clearable-->
      <!--              style="margin-left: 4px">-->
      <!--          </el-input>-->

      <!--          <el-select v-model="searchObj.isChain" clearable placeholder="请选择MT上链状态"-->
      <!--                     style="width: 500px; margin-left: 4px" size="medium">-->
      <!--            <el-option-->
      <!--                v-for="item in chainOptions"-->
      <!--                :key="item.value"-->
      <!--                :label="item.label"-->
      <!--                :value="item.value">-->
      <!--            </el-option>-->
      <!--          </el-select>-->
      <!--          <el-button class="action-button" @click="search()" type="primary" icon="el-icon-search" size="medium"-->
      <!--                     style="margin-left: 4px">-->
      <!--            搜索-->
      <!--          </el-button>-->
      <!--        </div>-->

      <!--      </div>-->
      <el-table :data="list" border stripe class="table" ref="multipleTable" height="700" size="mini">
        <el-table-column prop="id" label="ID" width="80" align="left"></el-table-column>
        <el-table-column prop="activityId" width="150" label="活动ID"></el-table-column>
        <el-table-column prop="itemTitle" width="180" label="活动标题"></el-table-column>
        <el-table-column prop="itemDetails" width="250" label="活动描述"></el-table-column>
        <el-table-column prop="condType" width="150" label="条件类型"></el-table-column>
        <el-table-column prop="condValue" width="150" label="条件值"></el-table-column>
        <el-table-column prop="condParams" width="150" label="条件参数"></el-table-column>
        <el-table-column prop="coinReward" width="100" label="Chips奖励"></el-table-column>
        <el-table-column prop="diamondReward" width="100" label="钻石奖励"></el-table-column>
        <el-table-column prop="pusdReward" width="100" label="PUSD奖励"></el-table-column>
        <el-table-column prop="goldReward" width="100" label="GOLD奖励"></el-table-column>
        <el-table-column prop="receiveCond" width="100" label="领取条件"></el-table-column>
        <el-table-column prop="receiveCondParams" width="100" label="领取条件参数"></el-table-column>
        <el-table-column prop="sort" width="100" label="排序"></el-table-column>
        <el-table-column
            label="操作"
            fixed="right">
          <template slot-scope="scope">
            <el-button
                @click="toUpdate(scope.row)"
                type="text" size="small">编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog :before-close="handleClose" :modal="true" :title="dialogState.title"
               :visible.sync="dialogState.show" v-if="dialogState.show">
      <div style="width: 96%;">
        <el-form label-position="right" ref="objItem" :model="objItem" :rules="rules" label-width="160px" size="mini"
        >
          <el-form-item label="ID" prop="id">
            <el-input v-model="objItem.id" disabled></el-input>
          </el-form-item>
          <el-form-item label="活动ID" prop="activityId">
            <el-input v-model="objItem.activityId" disabled></el-input>
          </el-form-item>
          <el-form-item label="活动标题" prop="itemTitle">
            <el-input v-model="objItem.itemTitle"></el-input>
          </el-form-item>
          <el-form-item label="活动描述" prop="itemDetails">
            <el-input v-model="objItem.itemDetails"></el-input>
          </el-form-item>
          <el-form-item label="条件类型" prop="condType">
            <el-input v-model="objItem.condType" disabled></el-input>
          </el-form-item>
          <el-form-item label="条件值" prop="condValue">
            <el-input v-model="objItem.condValue"></el-input>
          </el-form-item>
          <el-form-item label="条件参数" prop="condParams">
            <el-input v-model="objItem.condParams"></el-input>
          </el-form-item>
          <el-form-item label="Chips奖励" prop="coinReward">
            <el-input v-model="objItem.coinReward"></el-input>
          </el-form-item>
          <el-form-item label="钻石奖励" prop="diamondReward">
            <el-input v-model="objItem.diamondReward"></el-input>
          </el-form-item>
          <el-form-item label="PUSD奖励" prop="pusdReward">
            <el-input v-model="objItem.pusdReward"></el-input>
          </el-form-item>
          <el-form-item label="GOLD奖励" prop="goldReward">
            <el-input v-model="objItem.goldReward"></el-input>
          </el-form-item>
          <el-form-item label="领取条件" prop="receiveCond">
            <el-input v-model="objItem.receiveCond" disabled></el-input>
          </el-form-item>
          <el-form-item label="领取条件参数" prop="receiveCondParams">
            <el-input v-model="objItem.receiveCondParams"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input v-model="objItem.sort"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">
          <el-button size="mini" @click="handleClose">取消</el-button>
          <el-button size="mini" @click="updateConfig()" type="primary">确认</el-button>
        </div>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import moment from "moment";
// import {regionDataPlus} from 'element-china-area-data'
import {regionDataPlus} from 'element-china-area-data'
import {activityItemConfigs, updateActivityItem} from "../../../api/hall";

export default {
  name: "ActivityItemList",

  data() {
    return {
      options: regionDataPlus,
      list: [],

      // searchObj: {
      //   userId: null,
      //   isChain: null,
      //   mtSn: null
      // },
      // pagingOption: {
      //   page: 1,
      //   limit: 10,
      //   total: 0,
      // },

      objItem: {
        sb: 0,
      },

      // delMtItem: {
      //   id: null,
      //   mtSn: null,
      //   userId: null,
      //   reason: false,
      // },

      dialogState: {
        show: false,
        title: "修改配置",
        update: false
      },

      // delDialogState: {
      //   show: false,
      //   title: "删除MT",
      //   update: false
      // },
      rules: {
        ante: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        // ante: [{required: true, message: '不能为空', trigger: 'blur'}],
        itemTitle: [{required: true, message: '不能为空', trigger: 'blur'}],
        itemDetails: [{required: true, message: '不能为空', trigger: 'blur'}],
        condType: [{required: true, message: '不能为空', trigger: 'blur'}],
        condValue: [{required: true, message: '不能为空', trigger: 'blur'}],
        coinReward: [{required: true, message: '不能为空', trigger: 'blur'}],
        diamondReward: [{required: true, message: '不能为空', trigger: 'blur'}],
        pusdReward: [{required: true, message: '不能为空', trigger: 'blur'}],
        goldReward: [{required: true, message: '不能为空', trigger: 'blur'}],
        sort: [{required: true, message: '不能为空', trigger: 'blur'}],

      },

    };
  },
  created() {
    this.getList();
  },

  methods: {
    handleChange(value) {
      console.log(value)
    },
    search: function () {
      this.getList();
    },
    getList: function () {
      this.listLoading = true;

      return activityItemConfigs().then((response) => {
        this.list = response;
        this.listLoading = false;
      });
    },

    toUpdate(obj) {
      this.dialogState.show = true;
      this.objItem = Object.assign({}, obj);
    },

    updateConfig() {
      this.$refs['objItem'].validate((valid) => {
        if (valid) {
          this.$confirm("确定更新？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.objItem)
            updateActivityItem(req).then(() => {
              this.$message({
                type: 'success',
                message: '更新成功!',
              });
              this.dialogState.show = false;
              this.getList();
            });
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
    handleClose() {
      this.dialogState.show = false
    },
  }
}
</script>
<style>

.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>
