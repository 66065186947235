<template>
  <div>
    <el-card class="box-card">

      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">
          <el-input
              size="medium"
              placeholder="输入订单号"
              v-model="searchObj.orderSn"
              style=" margin-left: 4px" clearable>
          </el-input>
          <el-input
              size="medium"
              placeholder="输入钱包地址"
              v-model="searchObj.address"
              style=" margin-left: 4px" clearable>
          </el-input>
          <el-select v-model="searchObj.execStatus" clearable placeholder="选择提取状态"
                     style="width:400px; margin-left: 4px" size="medium">
            <el-option
                v-for="item in withdrawExecStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <el-button class="action-button" @click="search()" type="primary" icon="el-icon-search" size="medium"
                     style="margin-left: 4px">搜索
          </el-button>

          <el-button class="action-button" @click="toAddWithdrawPlan()" type="primary" size="medium"
                     style="margin-left: 20px">添加提取计划
          </el-button>
        </div>
      </div>

      <el-table :data="systemWithdrawList" border stripe class="table" ref="multipleTable" height="650">
        <el-table-column prop="id" label="ID" width="80" align="left"></el-table-column>
        <el-table-column prop="orderSn" min-width="280" label="订单号"></el-table-column>
        <el-table-column prop="title" min-width="200" label="备注"></el-table-column>
        <el-table-column prop="planId" width="110" label="提取计划ID"></el-table-column>
        <el-table-column prop="planName" min-width="200" label="提取计划名称"></el-table-column>

        <el-table-column prop="status" width="120" label="状态">
          <template slot-scope="scope">
            <el-tag size="mini" :type="withdrawExecStatus.find(r => r.value === scope.row.execStatus) ?
                     withdrawExecStatus.find(r => r.value === scope.row.execStatus).state : 'success'">
              {{
                withdrawExecStatus.find(r => r.value === scope.row.execStatus) ? withdrawExecStatus.find(r => r.value === scope.row.execStatus).label : "暂无"
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="addressList" label="发送地址" width="150">
          <template slot-scope="scope">
            <el-popover
                placement="bottom"
                title="地址"
                width="200"
                trigger="click"
                :content=JSON.stringify(scope.row.addressList)>
              <el-button slot="reference" size="mini" type="primary">点击查看地址</el-button>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column prop="expireTime" width="170" label="开始时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.startTime) }}
          </template>
        </el-table-column>

        <el-table-column prop="createTime" width="170" label="创建时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>

        <el-table-column
            label="操作"
            width="150"
            fixed="right">
          <template slot-scope="scope">
            <el-button v-if="scope.row.execStatus == 'CREATE'" @click="toUpdateWithdrawPlan(scope.row)" type="text"
                       size="small">修改
            </el-button>
            <el-button v-if="scope.row.execStatus == 'CREATE'" @click="removeSystemWithdraw(scope.row.id)" type="text"
                       size="small">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination v-if="systemWithdrawList.length > 0" @current-change="getSystemWithdrawPage"
                     :current-page.sync="pagingOption.page"
                     :page-size="pagingOption.limit" layout="total, prev, pager, next" :total="pagingOption.total">
      </el-pagination>
    </el-card>

    <el-dialog :before-close="handleClose" :modal="true" :title="dialogState.title"
               :visible.sync="dialogState.show" v-if="dialogState.show">
      <div style="width: 80%;">
        <el-form label-position="left" ref="systemWithdrawItem" :model="systemWithdrawItem"
                 :rules="systemWithdrawItemRules"
                 label-width="120px"
                 size="small ">

          <el-form-item label="ID" prop="id" v-show="!dialogState.create">
            <el-input v-model="systemWithdrawItem.id" disabled></el-input>
          </el-form-item>
          <el-form-item label="订单号" prop="orderSn" v-show="!dialogState.create">
            <el-input v-model="systemWithdrawItem.orderSn" disabled></el-input>
          </el-form-item>

          <el-form-item label="备注" prop="title">
            <el-input v-model="systemWithdrawItem.title"></el-input>
          </el-form-item>

          <el-form-item label="选择提取计划" prop="planId">
            <el-select v-model="systemWithdrawItem.planId" clearable>
              <el-option v-for="item in withdrawPlanList" :key="item.id" :label="item.name" :value="item.id"/>
            </el-select>
          </el-form-item>

          <el-form-item label="提取地址" prop="addressList">
            <el-input type="textarea" :autosize="{ minRows: 10, maxRows: 15}" v-model="systemWithdrawItem.addressList"
                      :disabled="!dialogState.create"
                      placeholder="输入地址，换行隔开，最多只能发送给20个地址"/>
          </el-form-item>

          <el-form-item label="开始时间" prop="startTime">
            <el-date-picker type="datetime" placeholder="选择开始执行时间"
                            v-model="systemWithdrawItem.startTime"
                            style="width: 100%;"
                            align="center"></el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">
          <el-button @click="handleClose">取消</el-button>
          <el-button v-show="dialogState.create" type="primary" @click="addWithdrawPlan">添加计划</el-button>
          <el-button v-show="!dialogState.create" type="primary" @click="updateWithdrawPlan">修改计划</el-button>
        </div>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import moment from "moment";
import {
  addSystemWithdraw,
  removeSystemWithdraw,
  systemWithdrawPage,
  updateSystemWithdraw,
  withdrawOpenList
} from "@/api/game";

export default {
  name: "SystemWithdrawList",

  data() {
    return {
      searchObj: {
        orderSn: null,
        execStatus: null,
        address: null,
      },

      systemWithdrawList: [],
      pagingOption: {
        page: 1,
        limit: 10,
        total: 0,
      },

      withdrawPlanList: [],

      withdrawExecStatus: [
        {value: 'CREATE', label: "创建", state: 'success'},
        {value: 'EXEC_ING', label: "执行中", state: 'danger'},
        {value: 'FINISH', label: "执行完", state: 'success'}
      ],

      dialogState: {
        title: "", // dialog 标题
        show: false, // dialog 显示控制
        disabled: false, // dialog 可编辑
        create: false, // dialog 是否为创建新用户（固定部分行不可编辑）
        action: null,
      },

      systemWithdrawItem: {
        id: null,
        orderSn: null,
        title: null,
        addressList: null,
        planId: null,
        planName: null,
        startTime: null,
      },

      systemWithdrawItemRules: {
        title: [
          {required: true, message: "不能为空！", trigger: "blur"}
        ],
        startTime: [
          {required: true, message: "不能为空！", trigger: "blur"}
        ],
        planId: [
          {required: true, message: "不能为空！", trigger: "blur"}
        ],
        addressList: [
          {required: true, message: "不能为空！", trigger: "blur"},
          {min: 1, max: 800, message: "长度有误[1~1500]", trigger: "blur"}
        ]
      },
    };
  },
  created() {
    this.getWithdrawPlanList();
    this.getSystemWithdrawPage();
  },

  methods: {

    handleChange(value) {
      console.log(value)
    },
    search: function () {
      this.getSystemWithdrawPage()
    },

    getSystemWithdrawPage: function () {
      this.listLoading = true;
      let req = {
        pageNumber: this.pagingOption.page,
        pageSize: this.pagingOption.limit,
        orderSn: this.searchObj.orderSn,
        execStatus: this.searchObj.execStatus,
        address: this.searchObj.address
      };

      return systemWithdrawPage(req).then((response) => {
        console.log(response.rows);
        this.systemWithdrawList = response.rows;
        this.pagingOption.total = response.total;
        this.listLoading = false;
      });
    },

    getWithdrawPlanList: function () {
      this.listLoading = true;
      return withdrawOpenList().then((response) => {
        console.log(response)
        this.withdrawPlanList = response;
        this.listLoading = false;
      });

    },

    toAddWithdrawPlan(obj) {
      this.systemWithdrawItem = Object.assign({}, obj);
      this.dialogState = Object.assign(
          {},
          {
            title: "添加系统提取计划",
            create: true,
            disabled: false,
            show: true,
          }
      );
    },

    toUpdateWithdrawPlan(obj) {
      this.systemWithdrawItem = Object.assign({}, obj);
      this.systemWithdrawItem.addressList = JSON.stringify(obj.addressList)
      console.log(this.systemWithdrawItem)
      this.dialogState = Object.assign(
          {},
          {
            title: "修改系统提取计划",
            create: false,
            disabled: false,
            show: true,
          }
      );
    },

    updateWithdrawPlan: function () {
      this.$refs['systemWithdrawItem'].validate((valid) => {
        if (valid) {
          this.$confirm("确定修改系统提取计划？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.systemWithdrawItem)
            req.startTime = new Date(this.systemWithdrawItem.startTime).getTime();

            let addressList = []
            this.systemWithdrawItem.addressList.split("\n").forEach(code => {
              code.split(",").forEach(c => {
                if (c && c.length > 0) {
                  addressList.push(c.trim())
                }
              })
            })
            req.addressList = addressList
            req.planName = this.withdrawPlanList.find(r => r.id === this.systemWithdrawItem.planId).name;

            updateSystemWithdraw(req).then(() => {
              this.$message({
                type: 'success',
                message: '发送成功!',
              });
              this.getSystemWithdrawPage();
            });
            this.close()
          }).catch((e) => {
            console.log(e)
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    addWithdrawPlan: function () {
      this.$refs['systemWithdrawItem'].validate((valid) => {
        if (valid) {
          this.$confirm("确定添加系统提取计划？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.systemWithdrawItem)
            req.startTime = new Date(this.systemWithdrawItem.startTime).getTime();

            let addressList = []
            this.systemWithdrawItem.addressList.split("\n").forEach(code => {
              code.split(",").forEach(c => {
                if (c && c.length > 0) {
                  addressList.push(c.trim())
                }
              })
            })
            req.addressList = addressList
            req.planName = this.withdrawPlanList.find(r => r.id === this.systemWithdrawItem.planId).name;

            addSystemWithdraw(req).then(() => {
              this.$message({
                type: 'success',
                message: '发送成功!',
              });
              this.getSystemWithdrawPage();
            });
            this.close()
          }).catch((e) => {
            console.log(e)
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    removeSystemWithdraw(id) {
      this.$confirm('确认删除 【' + id + '】？').then(() => {
        let req = {
          id: id
        }
        return removeSystemWithdraw(req).then(r => {
          if (r === true) {
            this.$message.success("删除成功");
            this.getSystemWithdrawPage();
          } else {
            this.$message.error("删除失败");
          }
          this.handleDelClose()
        }).catch(() => {
          this.handleDelClose()
        })
      })
    },

    close: function () {
      this.systemWithdrawItem = {}
      this.dialogState = {
        title: "",
        show: false,
        disabled: false,
        create: false,
        action: null,
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(() => {
            this.dialogState.show = false
            this.funcDialogState.show = false
            done();
          })
          .catch(() => {
          });
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
    selectBlur(e) {
      this.foodModel = e.target.value;
      this.$forceUpdate(); // 强制更新
    },
    selectClear() {
      this.foodModel = "";
      this.$forceUpdate(); // 强制更新
    }
  }
}
</script>

<style>
.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}

.input-new-uid {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

</style>

