import {Loading} from "element-ui";

let loadingOpt = {
    lock: true,
    text: "Loading ...",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)",
}

let loading;

function showLoading() {
    loading = Loading.service(loadingOpt);
}

function closeLoading() {
    if (loading) {
        setTimeout(() => {
            loading.close();
        }, 300);
    }
}

export {
    showLoading,
    closeLoading,
}
