<template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between;">
          <el-input v-model="searchObj.order" placeholder="排序" clearable
                    style="width: auto; margin-left: 4px"/>

          <el-input v-model="searchObj.link" placeholder="客户端链接" clearable
                    style="width: auto; margin-left: 4px"/>

          <el-input v-model="searchObj.desc" placeholder="描述" clearable
                    style="width: auto; margin-left: 4px"/>

          <el-select
              v-model="searchObj.channel"
              clearable
              placeholder="渠道"
              style="width: 160px; margin-left: 4px">
            <el-option
                v-for="item in AuthChannel"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>

          <el-select
              v-model="searchObj.show"
              filterable
              clearable
              remote
              placeholder="激活状态"
              style="width: 160px; margin-left: 4px">
            <el-option
                v-for="item in showOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>

          <el-button class="action-button" @click="search()" type="primary" icon="el-icon-search" size="mini"
                     style="margin-left: 15px">
            搜索
          </el-button>
        </div>

      </div>
      <div style="display: flex;justify-content: flex-end;">
        <el-button class="action-button" type="text" @click="toCreate">创建广告</el-button>
      </div>
      <el-table :data="list" border stripe class="table" ref="multipleTable">
        <el-table-column prop="id" label="ID" width="75" align="left"></el-table-column>
        <el-table-column prop="url" width="90" label="图片">
          <template slot-scope="scope">
            <el-image
                style="width: 50px; height: 50px"
                :preview-src-list="[scope.row.url]"
                :src="scope.row.url"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="url" label="图片url" width="250"></el-table-column>
        <el-table-column prop="desc" label="描述" width="150"></el-table-column>
        <el-table-column prop="show" width="100" label="状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.show ? 'success' : 'danger' ">
              {{ scope.row.show ? '展示' : '未展示' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="channel" width="200" label="渠道">
          <template slot-scope="scope">
            <el-tag
                style="margin: 2px"
                type="info"
                v-for="item in scope.row.channel"
                :key="item"
                size="mini">
              {{ item }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="link" label="客户端链接" width="220"></el-table-column>

        <el-table-column prop="order" width="100" label="排序"></el-table-column>

        <el-table-column prop="startTime" width="180" label="展示开始时间">
          <template slot-scope="scope">
            {{ scope.row.startTime ? dateFormat(scope.row.startTime) : "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="endTime" width="180" label="展示结束时间">
          <template slot-scope="scope">
            {{ scope.row.endTime ? dateFormat(scope.row.endTime) : "无" }}
          </template>
        </el-table-column>

        <el-table-column prop="createTime" width="180" label="创建时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>

        <el-table-column
            width="100"
            label="操作"
            fixed="right">
          <template slot-scope="scope">
            <el-button
                @click="toUpdate(scope.row)"
                type="text" size="small">编辑
            </el-button>
            <el-button
                @click="deleteById(scope.row)"
                type="text" size="small">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination v-if="list.length > 0" @current-change="getList"
                     :current-page.sync="pagingOption.page"
                     :page-size="pagingOption.limit" layout="total, prev, pager, next" :total="pagingOption.total">
      </el-pagination>
    </el-card>


    <el-dialog :before-close="handleClose" :modal="true" :title="dialogState.title"
               :visible.sync="dialogState.show" v-if="dialogState.show">
      <div style="width: 96%;">
        <el-form label-position="left" ref="objItem" :model="objItem" :rules="noticeRules" label-width="120px"
                 size="small">

          <el-form-item label="渠道" prop="channel">
            <el-select
                v-model="objItem.channel"
                multiple
                placeholder="请选择渠道"
                style="width:100%;">
              <el-option
                  v-for="item in AuthChannel"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="描述" prop="desc">
            <el-input v-model="objItem.desc"
                      placeholder="广告描述"/>
          </el-form-item>

          <el-form-item label="客户端链接" prop="link">
            <el-input v-model="objItem.link"
                      placeholder="客户端跳转链接"/>
          </el-form-item>

          <el-form-item label="图片" prop="image">
            <el-upload
                action="000"
                :http-request=uploadImage
                list-type="picture-card"
                :show-file-list="false"
                class="upload-demo"
                accept=".jpg,.jpeg,.png,.gif,.webp">
              <img v-if="objItem.imageBase64 || objItem.url"
                   :src="objItem.imageBase64 ? objItem.imageBase64 : objItem.url" style="width: 147px;height: 147px"
                   class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"/>
            </el-upload>
          </el-form-item>

          <el-form-item label="排序"
                        prop="order">
            <el-input-number style="width: 50%;" size="small" v-model="objItem.order"
                             :min="1"></el-input-number>
          </el-form-item>

          <el-form-item label="有效时间" prop="validTime">
            <el-date-picker
                style="width:100%;"
                v-model="objItem.validTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="有效开始日期"
                end-placeholder="有效结束日期"
                align="right">
            </el-date-picker>
          </el-form-item>

          <el-form-item label="状态" prop="show">
            <el-switch
                v-model="objItem.show"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="展示"
                inactive-text="未展示">
            </el-switch>
          </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">
          <el-button @click="handleClose">取消</el-button>
          <el-button @click="createOrUpdate()" type="primary">确认</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import moment from "moment";
import {closeLoading, showLoading} from "@/utils/LoadingUtil";
import {create, page, remove, update} from "@/api/advertise";
import {fileUpload} from "@/api/file";

export default {
  name: "Advertise",

  computed: {
    AuthChannel() {
      let option = this.$store.state.type.authChannel;
      let array = [{"id": 8, "name": "所有渠道", value: "ALL"}];
      option.forEach(o => {
        array.push(o);
      })
      return array;
    },

    FuncType() {
      return this.$store.state.type.funcType
    }
  },

  data() {
    return {
      list: [],
      itemList: [],
      mtQualityList: [],

      userCurrencyItem: [],

      showOptions: [
        {"name": "展示", value: true},
        {"name": "未展示", value: false}
      ],

      searchObj: {
        link: null,
        desc: null,
        channel: null,
        show: null,
        order: null,
      },

      pagingOption: {
        page: 1,
        limit: 10,
        total: 0,
      },

      dialogState: {
        show: false,
        title: "创建广告配置",
        update: false
      },

      funcForm: {},

      objItem: {
        channel: null,
        desc: null,
        link: null,
        validTime: null,
        startTime: null,
        endTime: null,
        show: true,
        order: 1,
        type: "IMAGE",
        imageBase64: null,
        file: null,
      },

      noticeRules: {
        channel: [
          {required: true, message: "不能为空！", trigger: "blur"}
        ],
        desc: [
          {required: true, message: "不能为空！", trigger: "blur"}
        ],
        link: [
          {required: true, message: "不能为空！", trigger: "blur"}
        ],
        show: [
          {required: true, message: "不能为空！", trigger: "blur"}
        ],
        validTime: [
          {required: true, message: "不能为空！", trigger: "blur"}
        ],
        order: [
          {required: true, message: "不能为空！", trigger: "blur"},
          {type: 'number', message: '必须大于0', min: 1}
        ],
      },
    };
  },
  created() {
    this.getList();
  },

  methods: {
    search: function () {
      this.pagingOption.page = 1;
      this.getList();
    },
    getList: function () {
      showLoading()
      let pageRequest = {
        pageNumber: this.pagingOption.page,
        pageSize: this.pagingOption.limit,
        link: this.searchObj.link,
        desc: this.searchObj.desc,
        channel: this.searchObj.channel ? "\"" + this.searchObj.channel + "\"" : null,
        show: this.searchObj.show,
        order: this.searchObj.order
      };
      return page(pageRequest).then((response) => {
        this.list = response.rows;
        this.pagingOption.total = response.total;
        closeLoading()
      }).catch(() => {
        closeLoading()
      });
    },

    deleteById(obj) {
      this.$confirm('确认删除？').then(() => {
        return remove(obj.id).then(r => {
          if (r === true) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error("删除失败");
          }
          closeLoading()
        }).catch(() => {
          closeLoading()
        })
      })
    },

    toUpdate(obj) {
      this.dialogState.show = true;
      this.dialogState.title = "编辑广告 (" + obj.id + ")";
      this.dialogState.update = true;
      this.objItem = JSON.parse(JSON.stringify(obj));
      this.objItem.validTime = [new Date(obj.startTime), new Date(obj.endTime)]
    },

    toCreate() {
      this.dialogState.show = true;
      this.dialogState.update = false;
      this.dialogState.title = "创建广告配置";
      this.objItem.channel = this.AuthChannel[0].value;
    },

    createOrUpdate: function () {
      this.$refs['objItem'].validate(valid => {
        if (valid) {
          let req = JSON.parse(JSON.stringify(this.objItem));
          if (this.objItem.channel.find((c) => c === "ALL")) {
            req.channel = null;
          }
          if (req.channel != null) {
            if (req.channel.find((c) => c === "BEE") && !req.channel.find((c) => c === "BEE_WALLET")) {
              req.channel.push("BEE_WALLET")
            }
            if (!req.channel.find((c) => c === "BEE") && req.channel.find((c) => c === "BEE_WALLET")) {
              req.channel.push("BEE")
            }
          }

          if (!req.url && !req.imageBase64) {
            this.$message.error("图片不能为空")
            return
          }

          req.startTime = new Date(req.validTime[0]).getTime();
          req.endTime = new Date(req.validTime[1]).getTime();
          req.endTime = new Date(req.validTime[1]).getTime();
          req.file = null;
          req.imageBase64 = null;

          this.$confirm('确认' + this.dialogState.update ? "更新" : "创建" + '？')
              .then(() => {
                showLoading()
                const formData = new FormData()
                formData.append('file', this.objItem.file)
                if (!req.url) {
                  return fileUpload(formData).then((res) => {
                    if (res && res.url) {
                      req.url = res.url
                      if (this.dialogState.update) {
                        this.update(req)
                      } else {
                        this.create(req)
                      }
                    } else {
                      closeLoading()
                      this.$message.error("上传图片失败");
                    }
                  }).catch(() => {
                    closeLoading()
                  })
                } else {
                  if (this.dialogState.update) {
                    this.update(req)
                  } else {
                    this.create(req)
                  }
                }
              })
        }
      })
    },

    update(req) {
      return update(req).then(r => {
        if (r === true) {
          this.$message.success("更新成功");
          this.dialogClose();
          this.getList();
        } else {
          this.$message.error("更新失败");
        }
        closeLoading()
      }).catch(() => {
        closeLoading()
      })
    },

    create(req) {
      return create(req).then(r => {
        if (r === true) {
          this.$message.success("创建成功");
          this.dialogClose();
          this.getList();
        } else {
          this.$message.error("创建失败");
        }
        closeLoading()
      }).catch(() => {
        closeLoading()
      })
    },


    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },

    handleClose() {
      this.$confirm('确认关闭？')
          .then(() => {
            this.dialogClose();
          })
    },

    dialogClose() {
      this.dialogState.show = false
      this.objItem = {
        channel: null,
        desc: null,
        validTime: null,
        startTime: null,
        endTime: null,
        show: true,
        order: 1,
        type: "IMAGE",
        imageBase64: null,
      }
    },

    uploadImage(res) {
      console.log(res)
      const file = res.file;
      if (file.type.indexOf("image") !== -1) {
        const reader = new FileReader();
        reader.readAsDataURL(file)
        reader.onload = () => {
          showLoading()
          this.objItem.imageBase64 = reader.result
          this.objItem.url = null;
          this.objItem = JSON.parse(JSON.stringify(this.objItem))
          this.objItem.file = file;
          closeLoading()
        }
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }
      } else {
        this.$message.error("上传文件不是图片格式")
      }

    },

  }
}
</script>
<style>

</style>
