<template>
  <el-card class="box-card">
    <div class="app-container">
      <el-form ref="dataForm" :rules="rules" :model="dataForm" status-icon label-width="300px">
        <el-form-item label="ID" prop="id">
          <el-input v-model="dataForm.id" class="input-width" disabled="true">
          </el-input>
        </el-form-item>

        <el-form-item label="是否开启" prop="opened">
          <el-switch
              v-model="dataForm.opened"
              active-text="开启中"
              inactive-text="关闭中">
          </el-switch>
        </el-form-item>

        <el-form-item label="回收地址" prop="recycleAddress">
          <el-input v-model="dataForm.recycleAddress" class="input-width">
          </el-input>
        </el-form-item>


        <el-form-item label="Proxy地址" prop="proxyUrl">
          <el-input v-model="dataForm.proxyUrl" class="input-width">
          </el-input>
        </el-form-item>

        <el-form-item label="备注" prop="details">
          <el-input v-model="dataForm.details" class="input-width">
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="editSetting">更新</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>
<script>
import {burnSetting, updateBurnSetting} from '@/api/game.js'

export default {
  name: 'BurnSetting',
  data() {
    return {
      dataForm: {
        id: 0,
        opened: 0,
        recycleAddress: undefined,
        proxyUrl: undefined,
        details: undefined,
      },
      rules: {
        id: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        opened: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        recycleAddress: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        proxyUrl: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        details: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init: function () {
      burnSetting().then(response => {
        this.dataForm = response
      })
    },
    cancel() {
      this.init()
    },
    update() {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.doUpdate()
      })
    },
    editSetting: function () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$confirm("确定更新？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.dataForm)
            updateBurnSetting(req).then(() => {
              this.$message({
                type: 'success',
                message: '更新成功!',
              });
            });
            this.close()
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>
<style scoped>
.input-width {
  width: 50%;
}
</style>
