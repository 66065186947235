  <template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">
          <el-input style=" width:400px;" size="medium" v-model="searchObj.keyword"
                    placeholder="输入Id/昵称/邮箱/充币地址/" clearable/>
          <el-input size="medium" v-model="searchObj.channelAccount" placeholder="输入平台账户" clearable
                    style=" width:400px;margin-left: 4px"/>
          <el-button class="action-button" @click="search()" type="primary" icon="el-icon-search" size="mini"
                     style="margin-left: 4px">
            搜索
          </el-button>
        </div>
      </div>

      <el-table :data="userList" border stripe class="table" ref="multipleTable">
        <el-table-column prop="id" label="ID" width="270" align="left"></el-table-column>
        <el-table-column prop="account" width="150" label="平台账号ID"></el-table-column>
        <el-table-column prop="email" width="200" label="邮箱"></el-table-column>
        <el-table-column prop="platformWallet" width="200" label="充币地址"></el-table-column>
        <el-table-column prop="nickname" width="150" label="昵称"></el-table-column>

        <el-table-column prop="status" min-width="80" label="状态">
          <template slot-scope="scope">
            <el-tag size="mini" :type="userStatus.find(r => r.value === scope.row.status) ?
                     userStatus.find(r => r.value === scope.row.status).state : 'info'">
              {{
                userStatus.find(r => r.value === scope.row.status) ? userStatus.find(r => r.value === scope.row.status).label : "暂无"
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="registerDevice" width="200" label="注册设备号"></el-table-column>
        <el-table-column prop="platformUserChannel" width="200" label="注册渠道"></el-table-column>
        <el-table-column prop="platformUserChannelAccount" width="200" label="注册账户"></el-table-column>

        <el-table-column prop="createTime" width="180" label="创建时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="lastLoginTime" width="180" label="最后登录时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.lastLoginTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="lastLoginDevice" width="200" label="最后登录设备"></el-table-column>
        <el-table-column
            label="操作"
            width="220"
            fixed="right">
          <template slot-scope="scope">
            <el-button @click="toChangeStatus(scope.row)" type="text" size="small">修改状态</el-button>
            <el-button @click="getUserCurrency(scope.row.id)" type="text" size="small">钱包</el-button>
            <el-button @click="gotoMtList(scope.row.id)" type="text" size="small">MT</el-button>
            <el-button @click="gotoBoxesList(scope.row.id)" type="text" size="small">盲盒</el-button>
            <el-button @click="gotoIntegralList(scope.row.id)" type="text" size="small">积分</el-button>
            <el-button @click="gotoAddressList(scope.row.id)" type="text" size="small">地址</el-button>

          </template>
        </el-table-column>
      </el-table>
      <el-pagination v-if="userList.length > 0" @current-change="getUserList"
                     :current-page.sync="pagingOption.page"
                     :page-size="pagingOption.limit" layout="total, prev, pager, next" :total="pagingOption.total">
      </el-pagination>
    </el-card>

    <el-dialog :modal="true" :title="userCurrencyDialogState.title"
               :close-on-click-modal=false
               :visible.sync="userCurrencyDialogState.show" v-if="userCurrencyDialogState.show">
      <div>
        <el-table :data="userCurrencyItem" border stripe class="table" ref="multipleTable">
          <el-table-column prop="id" label="ID" align="left"></el-table-column>
          <el-table-column prop="currencyType" label="货币类型"></el-table-column>
          <el-table-column prop="amount" label="数量"></el-table-column>
          <el-table-column
              label="操作"
              fixed="right">
            <template slot-scope="scope">
              <el-button @click="toRecord(scope.row.currencyType, scope.row.userId)" type="text" size="small">记录查询
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--        <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">-->
        <!--          <el-button @click="handleClose">取消</el-button>-->
        <!--          <el-button type="primary" @click="userCurrencyDialogState.action.call()">保存</el-button>-->
        <!--        </div>-->
      </div>
    </el-dialog>

    <el-dialog :modal="true" :title="changeStatusDialogState.title"
               :close-on-click-modal=false
               :visible.sync="changeStatusDialogState.show" v-if="changeStatusDialogState.show">
      <el-form label-position="left" ref="userStatusItem" :model="userStatusItem" label-width="120px"
               :rules="userStatusRules"
               size="small">
        <el-form-item label="用户ID" prop="id">
          <el-input size="medium" disabled v-model="userStatusItem.id"></el-input>
        </el-form-item>
        <el-form-item label="账号状态" prop="banned">
          <el-switch
              v-model="userStatusItem.active"
              active-text="正常"
              inactive-text="封禁中">
          </el-switch>
        </el-form-item>
        <el-form-item label="原因" prop="reason">
          <el-input size="medium" v-model="userStatusItem.reason" placeholder="输入原因，可以是中文"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeUserStatusDialog">取消</el-button>
        <el-button @click="updateUserStatus()" type="primary">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
// import {regionDataPlus} from 'element-china-area-data'
import {getUserCurrency, updateUserStatus, userPage} from "@/api/appUser";
import {regionDataPlus} from 'element-china-area-data'
import {closeLoading} from "@/utils/LoadingUtil";

export default {
  name: "AppUserList",

  data() {
    return {
      options: regionDataPlus,
      userList: [],

      userStatus: [
        {value: 'ACTIVE', label: "正常", state: 'info', active: true},
        {value: 'BANNED', label: "封禁", state: 'danger', active: false}
      ],

      userCurrencyDialogState: {
        title: "", // dialog 标题
        show: false, // dialog 显示控制
        disabled: false, // dialog 可编辑
        create: false, // dialog 是否为创建新用户（固定部分行不可编辑）
        action: null,
      },

      changeStatusDialogState: {
        title: "修改用户状态", // dialog 标题
        show: false, // dialog 显示控制
        disabled: false, // dialog 可编辑
        create: false, // dialog 是否为创建新用户（固定部分行不可编辑）
      },

      userCurrencyItem: [],

      userStatusItem: {
        id: null,
        active: null,
        reason: null,
      },

      searchObj: {
        keyword: null,
        channelAccount: null,
      },

      pagingOption: {
        page: 1,
        limit: 10,
        total: 0,
      },

      userStatusRules: {
        reason: [
          {required: true, message: "不能为空！", trigger: "blur"}
        ],
      },
    };
  },
  created() {
    // this.getUserList();
  },

  methods: {
    handleChange(value) {
      console.log(value)
    },
    search: function () {
      this.getUserList();
    },
    getUserList: function () {
      this.listLoading = true;
      // eslint-disable-next-line no-unused-vars
      let pageRequest = {
        pageNumber: this.pagingOption.page,
        pageSize: this.pagingOption.limit,
        keyword: this.searchObj.keyword,
        channelAccount: this.searchObj.channelAccount,
      };

      return userPage(pageRequest).then((response) => {
        this.userList = response.rows;
        this.pagingOption.total = response.total;
        this.listLoading = false;
      });
    },

    getUserCurrency: function (uid) {
      let req = {
        userId: uid
      };
      return getUserCurrency(req).then((response) => {
        this.userCurrencyItem = response;
        console.log(this.userCurrencyItem)
        this.userCurrencyDialogState = Object.assign(
            {},
            {
              title: "用户货币信息",
              create: false,
              disabled: false,
              show: true
            }
        );
      });
    },

    toChangeStatus: function (obj) {
      this.changeStatusDialogState.show = true
      this.userStatusItem.id = obj.id
      this.userStatusItem.active = this.userStatus.find(r => r.value === obj.status).active
    },

    updateUserStatus() {
      this.$refs['userStatusItem'].validate(valid => {
        if (valid) {
          this.$confirm('确认修改用户状态？').then(() => {
            let req = {
              id: this.userStatusItem.id,
              status: this.userStatus.find(r => r.active === this.userStatusItem.active).value,
              reason: this.userStatusItem.reason,
            }
            return updateUserStatus(req).then(r => {
              if (r === true) {
                this.$message.success("success");
              } else {
                this.$message.error("fail");
              }
              this.getUserList();
              closeLoading()
            }).catch(() => {
              closeLoading()
            })
          })
        }
      })
    },


    gotoMtList: function (uid) {
      this.$router.push({
        path: '/userMtList',
        query: {userId: uid}
      })
    },

    gotoBoxesList: function (uid) {
      this.$router.push({
        path: '/userBoxesList',
        query: {userId: uid}
      })
    },

    gotoIntegralList: function (uid) {
      this.$router.push({
        path: '/userIntegralList',
        query: {userId: uid}
      })
    },

    gotoAddressList: function (uid) {
      this.$router.push({
        path: '/userWalletAddressList',
        query: {userId: uid}
      })
    },

    toRecord(type, userId) {
      sessionStorage.setItem("userTmpCurrency", JSON.stringify({type: type, userId: userId}));
      this.$router.push({path: '/userCurrencyRecord'})
    },


    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(() => {
            this.userCurrencyDialogState.show = false
            done();
          })
          .catch(() => {
          });
    },

    closeUserStatusDialog() {
      this.changeStatusDialogState.show = false
    },
  }
}
</script>
<style>

.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>
