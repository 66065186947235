<template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">

          <el-input
              size="medium"
              placeholder="输入用户ID"
              v-model="searchObj.userId"
              style=" margin-left: 4px">
          </el-input>

          <el-input
              size="medium"
              placeholder="输入盲盒序号"
              v-model="searchObj.boxesSn"
              style="margin-left: 4px">
          </el-input>

          <el-select v-model="searchObj.isChain" clearable placeholder="请选择盲盒上链状态"
                     style="width: 500px; margin-left: 4px" size="medium">
            <el-option
                v-for="item in chainOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <el-button class="action-button" @click="search()" type="primary" icon="el-icon-search" size="medium"
                     style="margin-left: 4px">
            搜索
          </el-button>
        </div>

      </div>
      <el-table :data="userBoxesList" border stripe class="table" ref="multipleTable" height="700">
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <el-table-column prop="userId" min-width="270" label="用户ID"></el-table-column>
        <el-table-column prop="boxesSn" width="150" label="盲盒序号"></el-table-column>
        <el-table-column prop="dna" width="130" label="DNA"></el-table-column>
        <el-table-column prop="tokenId" width="100" label="tokenId"></el-table-column>
        <el-table-column prop="assetsId" width="270" label="平台资产ID"></el-table-column>
        <el-table-column prop="chainId" width="100" label="链ID"></el-table-column>
<!--        <el-table-column prop="fromWalletAddress" width="270" label="来自钱包地址"></el-table-column>-->
        <el-table-column prop="createTime" width="180" label="创建时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>

      </el-table>
      <el-pagination v-if="userBoxesList.length > 0" @current-change="getUserBoxesList"
                     :current-page.sync="pagingOption.page"
                     :page-size="pagingOption.limit" layout="total, prev, pager, next" :total="pagingOption.total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import moment from "moment";
// import {regionDataPlus} from 'element-china-area-data'
import {getUserBoxesList} from "@/api/appUser";
import {regionDataPlus} from 'element-china-area-data'

export default {
  name: "userBoxesList",

  data() {
    return {
      options: regionDataPlus,
      userBoxesList: [],

      chainOptions: [
        {value: false, label: '中心化盲盒'},
        {value: true, label: '上链盲盒'}
      ],

      searchObj: {
        userId: null,
        isChain: null,
        boxesSn: null
      },

      pagingOption: {
        page: 1,
        limit: 10,
        total: 0,
      },
    };
  },
  created() {
    this.searchObj.userId = this.$route.query.userId;

    console.log(this.searchObj.userId)
    if (this.searchObj.userId == null) {
      return;
    }
    this.getUserBoxesList();
  },

  methods: {
    handleChange(value) {
      console.log(value)
    },
    search: function () {
      this.getUserBoxesList();
    },
    getUserBoxesList: function () {
      this.listLoading = true;
      if (this.searchObj.userId == null || this.searchObj.userId == '') {
        this.$message.warning("请输入玩家ID!!!");
        return;
      }
      let req = {
        pageNumber: this.pagingOption.page,
        pageSize: this.pagingOption.limit,
        userId: this.searchObj.userId,
        isChain: this.searchObj.isChain,
        boxesSn: this.searchObj.boxesSn
      };

      return getUserBoxesList(req).then((response) => {
        console.log(response.rows);
        this.userBoxesList = response.rows;
        this.pagingOption.total = response.total;
        this.listLoading = false;
      });
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(() => {
            this.userCurrencyDialogState.show = false
            done();
          })
          .catch(() => {
          });
    },
  }
}
</script>
<style>

.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>
