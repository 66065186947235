import request from '@/utils/request'

export function page(params) {
    return request({
        url: '/api/app/cdk/page',
        method: 'get',
        params: params
    })
}

export function create(data) {
    return request({
        url: '/api/app/cdk/create',
        method: 'post',
        data: data
    })
}


export function update(data) {
    return request({
        url: '/api/app/cdk/update',
        method: 'post',
        data: data
    })
}


export function getUsage(params) {
    return request({
        url: '/api/app/cdk/getUsage',
        method: 'get',
        params: params
    })
}
