import request from '@/utils/request'

export function userLogin(data) {
    return request({
        url: '/sys/auth/login',
        method: 'post',
        data: data
    })
}

export function userLogout(data) {
    return request({
        url: '/sys/auth/logout',
        method: 'post',
        data: data
    })
}