<template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between;">
          <el-input v-model="searchObj.userId" placeholder="输入用户id" clearable
                    style="width: auto;"/>

          <el-input v-model="searchObj.code" placeholder="输入用户code" clearable
                    style="width: auto; margin-left: 4px"/>

          <el-input v-model="searchObj.inviteUserId" placeholder="输入邀请人id" clearable
                    style="width: auto; margin-left: 4px"/>

          <el-input v-model="searchObj.inviteUserCode" placeholder="输入邀请人code" clearable
                    style="width: auto; margin-left: 4px"/>

          <el-button class="action-button" @click="search()" type="primary" icon="el-icon-search" size="mini"
                     style="margin-left: 15px">
            搜索
          </el-button>
        </div>

      </div>
      <el-table :data="list" border stripe class="table" ref="multipleTable">
        <el-table-column prop="id" label="ID" width="100" align="left"></el-table-column>
        <el-table-column prop="userId" width="280" label="用户ID"></el-table-column>
        <el-table-column prop="code" width="200" label="邀请码"></el-table-column>
        <el-table-column prop="useCount" width="100" label="使用次数"></el-table-column>
        <el-table-column prop="inviteUserId" width="280" label="邀请人ID">
          <template slot-scope="scope">
            {{ scope.row.inviteUserId ? scope.row.inviteUserId : "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="inviteUserCode" width="180" label="邀请人CODE">
          <template slot-scope="scope">
            {{ scope.row.inviteUserCode ? scope.row.inviteUserCode : "无" }}
          </template>
        </el-table-column>

        <el-table-column prop="bindTime" width="180" label="绑定邀请码时间">
          <template slot-scope="scope">
            {{ scope.row.bindTime ? dateFormat(scope.row.bindTime) : "无" }}
          </template>
        </el-table-column>

        <el-table-column prop="createTime" width="180" label="创建时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>

        <!--        <el-table-column-->
        <!--            label="操作"-->
        <!--            width="180"-->
        <!--            fixed="right">-->
        <!--        </el-table-column>-->
      </el-table>
      <el-pagination v-if="list.length > 0" @current-change="getList"
                     :current-page.sync="pagingOption.page"
                     :page-size="pagingOption.limit" layout="total, prev, pager, next" :total="pagingOption.total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import moment from "moment";
import {page} from "@/api/appUserInviteCode";
import {closeLoading, showLoading} from "@/utils/LoadingUtil";

export default {
  name: "UserInviteCode",

  data() {
    return {
      list: [],

      userCurrencyItem: [],

      searchObj: {
        code: null,
        userId: null,
        inviteUserId: null,
        inviteUserCode: null,
      },

      pagingOption: {
        page: 1,
        limit: 10,
        total: 0,
      },
    };
  },
  created() {
    this.getList();
  },

  methods: {
    search: function () {
      this.pagingOption.page = 1;
      this.getList();
    },
    getList: function () {
      showLoading()
      this.listLoading = true;
      // eslint-disable-next-line no-unused-vars
      let pageRequest = {
        pageNumber: this.pagingOption.page,
        pageSize: this.pagingOption.limit,
        code: this.searchObj.code,
        userId: this.searchObj.userId,
        inviteUserId: this.searchObj.inviteUserId,
        inviteUserCode: this.searchObj.inviteUserCode,
      };

      return page(pageRequest).then((response) => {
        this.list = response.rows;
        this.pagingOption.total = response.total;
        setTimeout(() => {
          closeLoading();
        }, 500);
      }).catch(() => {
        setTimeout(() => {
          closeLoading();
        }, 500);
      });
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
  }
}
</script>
<style>

.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>
