<template>
  <div>
    <el-card class="box-card">

      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">

          <el-button class="action-button" @click="toAddWhiteUser()" type="primary" size="medium"
                     style="margin-left: 20px">添加登录绿灯用户白名单
          </el-button>
        </div>
      </div>

      <el-table :data="whiteUserList" ref="multipleTable" row-key="id" border stripe
                style="margin-top: 15px;">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="userId" label="用户ID"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>

        <!--        <el-table-column-->
        <!--            label="操作"-->
        <!--            width="99"-->
        <!--            fixed="right">-->
        <!--          <template slot-scope="scope">-->
        <!--            <el-button @click="toEditVersion(scope.row)" type="text" size="small">编辑</el-button>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column
            label="操作"
            width="140"
            fixed="right">
          <template slot-scope="scope">
            <el-button @click="delWhiteUser(scope.row.id)" type="danger" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

    </el-card>

    <el-dialog :before-close="handleClose" :modal="true" :title="dialogState.title"
               :visible.sync="dialogState.show" v-if="dialogState.show">
      <div style="width: 80%;">
        <el-form label-position="left" ref="newItem" :model="newItem" :rules="whiteRules" label-width="120px"
                 size="small">
          <el-form-item label="玩家ID" prop="userId" v-show="!dialogState.create">
            <el-input v-model="newItem.userId"/>
          </el-form-item>
          <el-form-item label="备注" prop="remark" v-show="!dialogState.create">
            <el-input v-model="newItem.remark"/>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="addWhiteUser">保存</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
// import {regionDataPlus} from 'element-china-area-data'
import {regionDataPlus} from 'element-china-area-data'
import {addWhiteUser, delWhiteUser, getWhiteUserList} from "@/api/appUser";

export default {
  name: "WhiteUserList",

  data() {
    return {

      options: regionDataPlus,
      whiteUserList: [],

      dialogState: {
        title: "", // dialog 标题
        show: false, // dialog 显示控制
        disabled: false, // dialog 可编辑
        create: false, // dialog 是否为创建新用户（固定部分行不可编辑）
        action: null,
      },

      newItem: {
        id: null,
        userId: null,
        remark: null
      },

      whiteRules: {
        userId: [
          {required: true, message: "不能为空！", trigger: "blur"},
        ],
        remark: [
          {required: true, message: "不能为空！", trigger: "blur"},
        ]
      },
    };
  },
  created() {
    this.getWhiteUserList();
  },

  methods: {
    handleChange(value) {
      console.log(value)
    },
    search: function () {
      // this.getList(null, this.keyword);
      this.getWhiteUserList()
      // this.$message.error("点击搜索按钮,搜索信息:" + JSON.stringify(this.searchObj))
    },
    getWhiteUserList: function () {
      this.listLoading = true;
      return getWhiteUserList().then((response) => {
        this.whiteUserList = response;
        console.log(response)
        this.listLoading = false;
      });

    },

    toAddWhiteUser(obj) {
      this.newItem = Object.assign({}, obj);
      this.dialogState = Object.assign(
          {},
          {
            title: "添加白名单用户",
            create: false,
            disabled: false,
            show: true,
          }
      );
    },

    delWhiteUser(obj) {
      this.$confirm("确定删除？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
        center: true,
      }).then(() => {
        let req = {
          id: obj
        }
        delWhiteUser(req).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
          this.getWhiteUserList()
        });
        this.close()
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消",
        });
      });
    },

    addWhiteUser: function () {
      this.$refs['newItem'].validate((valid) => {
        if (valid) {
          this.$confirm("确定添加？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.newItem)
            addWhiteUser(req).then(() => {
              this.$message({
                type: 'success',
                message: '添加成功!',
              });
              this.getWhiteUserList()
            });
            this.close()
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    close: function () {
      this.newItem = {}
      this.dialogState = {
        title: "",
        show: false,
        disabled: false,
        create: false,
        action: null,
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(() => {
            this.dialogState.show = false
            done();
          })
          .catch(() => {
          });
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
  }
}
</script>

<style>
.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>

