<template>
  <div>
    <el-card class="box-card">

      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">

          <el-button class="action-button" @click="toAddLimitUser()" type="primary" size="medium"
                     style="margin-left: 20px">添加白名单或者黑名单
          </el-button>
        </div>
      </div>

      <el-table :data="withdrawLimitUserList" ref="multipleTable" row-key="id" border stripe
                style="margin-top: 15px;">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="userId" label="用户ID"></el-table-column>
        <el-table-column prop="listType" label="类型">
          <template slot-scope="scope">
            <el-tag style="margin-right: 10px">
              {{
                limitType.find((s) => s.value === scope.row.listType).label
              }}
            </el-tag>

          </template>

        </el-table-column>


        <el-table-column prop="remark" label="标注"></el-table-column>

        <el-table-column
            label="操作"
            width="140"
            fixed="right">
          <template slot-scope="scope">
            <el-button @click="delLimitUser(scope.row.id)" type="danger" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

    </el-card>

    <el-dialog :before-close="handleClose" :modal="true" :title="dialogState.title"
               :visible.sync="dialogState.show" v-if="dialogState.show">
      <div style="width: 80%;">
        <el-form label-position="left" ref="newItem" :model="newItem" :rules="whiteRules" label-width="120px"
                 size="small">
          <el-form-item label="玩家ID" prop="userId" v-show="!dialogState.create">
            <el-input v-model="newItem.userId"/>
          </el-form-item>

          <el-form-item label="名单类型" prop="listType">
            <el-select v-model="newItem.listType" clearable placeholder="选择限制状态">
              <el-option
                  v-for="item in limitType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="备注" prop="remark" v-show="!dialogState.create">
            <el-input v-model="newItem.remark"/>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="addLimitUser">保存</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
// import {regionDataPlus} from 'element-china-area-data'
import {regionDataPlus} from 'element-china-area-data'
import {addWithdrawLimitUserList, delWithdrawLimitUserList, getWithdrawLimitUserList} from "@/api/appUser";

export default {
  name: "WithdrawLimitUserList",

  data() {
    return {

      options: regionDataPlus,
      withdrawLimitUserList: [],
      limitType: [{
        value: 'WHITE_LIST',
        label: '白名单'
      }, {
        value: 'BLACK_LIST',
        label: '黑名单'
      }],

      dialogState: {
        title: "", // dialog 标题
        show: false, // dialog 显示控制
        disabled: false, // dialog 可编辑
        create: false, // dialog 是否为创建新用户（固定部分行不可编辑）
        action: null,
      },

      newItem: {
        id: null,
        userId: null,
        listType: null,
        remark: null
      },

      whiteRules: {
        userId: [
          {required: true, message: "不能为空！", trigger: "blur"},
        ],
        listType: [
          {required: true, message: "不能为空！", trigger: "blur"},
        ],
        remark: [
          {required: true, message: "不能为空！", trigger: "blur"},
        ]
      },
    };
  },
  created() {
    this.getLimitUserList();
  },

  methods: {
    handleChange(value) {
      console.log(value)
    },
    search: function () {
      // this.getList(null, this.keyword);
      this.getLimitUserList()
      // this.$message.error("点击搜索按钮,搜索信息:" + JSON.stringify(this.searchObj))
    },
    getLimitUserList: function () {
      this.listLoading = true;
      return getWithdrawLimitUserList().then((response) => {
        this.withdrawLimitUserList = response;
        console.log(response)
        this.listLoading = false;
      });

    },

    toAddLimitUser(obj) {
      this.newItem = Object.assign({}, obj);
      this.dialogState = Object.assign(
          {},
          {
            title: "添加白名单或者黑名单",
            create: false,
            disabled: false,
            show: true,
          }
      );
    },

    delLimitUser(obj) {
      this.$confirm("确定删除？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
        center: true,
      }).then(() => {
        let req = {
          id: obj
        }
        delWithdrawLimitUserList(req).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
          this.getLimitUserList()
        });
        this.close()
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消",
        });
      });
    },

    addLimitUser: function () {
      this.$refs['newItem'].validate((valid) => {
        if (valid) {
          this.$confirm("确定添加？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.newItem)
            addWithdrawLimitUserList(req).then(() => {
              this.$message({
                type: 'success',
                message: '添加成功!',
              });
              this.getLimitUserList()
            });
            this.close()
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    close: function () {
      this.newItem = {}
      this.dialogState = {
        title: "",
        show: false,
        disabled: false,
        create: false,
        action: null,
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(() => {
            this.dialogState.show = false
            done();
          })
          .catch(() => {
          });
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
  }
}
</script>

<style>
.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>

