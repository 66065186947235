import request from '@/utils/request'

export function userPage(params) {
    return request({
        url: '/api/app/user/userPage',
        method: 'get',
        params: params
    })
}

export function updateUserStatus(params) {
    return request({
        url: '/api/app/user/updateUserStatus',
        method: 'get',
        params: params
    })
}

export function getUserCurrency(params) {
    return request({
        url: '/api/app/user/getUserCurrency',
        method: 'get',
        params: params
    })
}


export function getUserCurrencyRecord(params) {
    return request({
        url: '/api/app/user/getUserCurrencyRecord',
        method: 'get',
        params: params
    })
}


export function getUserDepositNftRecord(params) {
    return request({
        url: '/api/app/user/getUserDepositNftRecord',
        method: 'get',
        params: params
    })
}

export function getUserWithdrawCurrencyRecord(params) {
    return request({
        url: '/api/app/user/getUserWithdrawCurrencyRecord',
        method: 'get',
        params: params
    })
}


export function getUserMtList(params) {
    return request({
        url: '/api/app/user/userMtPage',
        method: 'get',
        params: params,
    })
}

export function getWhiteUserList(params) {
    return request({
        url: '/api/app/user/whiteUserList',
        method: 'get',
        params: params,
    })
}

export function addWhiteUser(data) {
    return request({
        url: '/api/app/user/addWhiteUser',
        method: 'post',
        data: data,
    })
}

export function delWhiteUser(params) {
    return request({
        url: '/api/app/user/delWhiteUser',
        method: 'get',
        params: params,
    })
}

export function getDeviceWhitelist(params) {
    return request({
        url: '/api/app/user/deviceWhitelist',
        method: 'get',
        params: params,
    })
}

export function addDeviceWhitelist(data) {
    return request({
        url: '/api/app/user/addDeviceWhitelist',
        method: 'post',
        data: data,
    })
}

export function delDeviceWhitelist(params) {
    return request({
        url: '/api/app/user/delDeviceWhitelist',
        method: 'get',
        params: params,
    })
}

export function getUserBoxesList(params) {
    return request({
        url: '/api/app/user/userBoxesPage',
        method: 'get',
        params: params,
    })
}

export function sendMt2User(params) {
    return request({
        url: '/api/app/user/sendMt2User',
        method: 'get',
        params: params,
    })
}

export function getWithdrawLimitUserList(params) {
    return request({
        url: '/api/app/user/getWithdrawLimitUserList',
        method: 'get',
        params: params,
    })
}

export function addWithdrawLimitUserList(data) {
    return request({
        url: '/api/app/user/addWithdrawLimitUserList',
        method: 'post',
        data: data,
    })
}

export function delWithdrawLimitUserList(params) {
    return request({
        url: '/api/app/user/delWithdrawLimitUserList',
        method: 'get',
        params: params,
    })
}

export function getWithdrawCurrencyProgress(params) {
    return request({
        url: '/api/app/user/getWithdrawCurrencyProgress',
        method: 'get',
        params: params
    })
}

export function forceWithdraw(params) {
    return request({
        url: '/api/app/user/forceWithdraw',
        method: 'get',
        params: params
    })
}

export function removeMtById(params) {
    return request({
        url: '/api/app/user/removeMtById',
        method: 'get',
        params: params
    })
}

export function updateMtOperate(data) {
    return request({
        url: '/api/app/user/updateMtOperate',
        method: 'post',
        data: data,
    })
}

export function getUserIntegralPage(params) {
    return request({
        url: '/api/app/user/getUserIntegralPage',
        method: 'get',
        params: params
    })
}

export function getUserIntegralAmount(params) {
    return request({
        url: '/api/app/user/getUserIntegralAmount',
        method: 'get',
        params: params
    })
}

export function getUserAddressList(params) {
    return request({
        url: '/api/app/user/getUserAddressList',
        method: 'get',
        params: params
    })
}

export function removeAddressById(params) {
    return request({
        url: '/api/app/user/removeAddressById',
        method: 'get',
        params: params
    })
}