<template>
    <el-row :gutter="5" class="search-bar">
        <el-col :span="18">
            <el-input v-model="newKeyword" :placeholder="newPlaceholder" @clear="search" clearable>
                <el-button slot="append" type="primary" icon="el-icon-search" @click="search"/>
            </el-input>
        </el-col>
        <el-col :span="6">
            <!--            <el-button type="primary" @click="search">搜索</el-button>-->
        </el-col>
    </el-row>
</template>

<script>
    /**
     * 通用关键字查询控件
     */
    export default {
        name: "SearchPanel",
        props: {
            keyword: String,
            placeholder: String,
        },
        data() {
            return {
                newKeyword: null,
                newPlaceholder: "请输入关键字",
            };
        },
        created() {
            this.newKeyword = this.keyword;
            if (this.placeholder) {
                this.newPlaceholder = this.placeholder;
            }
        },
        watch: {
            keyword: function (newVal) {
                this.newKeyword = newVal;
            }
        },
        methods: {
            search: function () {
                this.$emit('onSearch', this.newKeyword)
            }
        }
    }
</script>

<style scoped>
    .search-bar {
        margin: 0 4px 8px 4px;
    }
</style>