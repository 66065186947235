<template>
  <div>
    <el-card class="box-card">
      <!--      <div style="display: flex;justify-content: space-between;">-->
      <!--        <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">-->
      <!--          <el-button class="action-button" @click="toAdd()" type="primary" size="medium"-->
      <!--                     style="margin-left: 4px">-->
      <!--            增加-->
      <!--          </el-button>-->
      <!--        </div>-->
      <!--      </div>-->
      <el-table :data="exchangeOrderList" border stripe class="table" ref="multipleTable" height="700">
        <el-table-column prop="id" label="ID" width="80" align="left"></el-table-column>
        <el-table-column prop="orderNo" width="300" label="订单ID"></el-table-column>
        <el-table-column prop="userId" width="130" label="用户Id"></el-table-column>
        <el-table-column prop="orderStatus" width="200" label="订单状态"></el-table-column>
        <el-table-column prop="goodsId" label="商品ID"></el-table-column>
        <el-table-column prop="goodsName" width="200" label="商品名称"></el-table-column>
        <el-table-column prop="address" width="250" label="钱包地址"></el-table-column>
        <el-table-column prop="chainId" width="100" label="链ID"></el-table-column>
        <el-table-column prop="showUsdt" width="120" label="显示USD价格"></el-table-column>
        <el-table-column prop="realUsdt" width="120" label="实际USD价格"></el-table-column>
        <el-table-column prop="payCoin" width="90" label="花费Coin"></el-table-column>
        <el-table-column prop="payDiamond" width="90" label="花费钻石"></el-table-column>
        <el-table-column prop="payVoucher" width="90" label="花费PUSD"></el-table-column>
        <el-table-column prop="createTime" width="180" label="创建时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>

        <el-table-column
            width="100"
            label="操作"
            fixed="right">
          <template slot-scope="scope">
            <el-button
                v-if="scope.row.orderStatus ==='PAYED' || scope.row.orderStatus ==='REQUEST_ETH_FAIL' || scope.row.orderStatus ==='FAIL'"
                @click="toDeliverGoods(scope.row)"
                type="text" size="small">发货
            </el-button>

            <el-button
                v-if="scope.row.orderStatus ==='PAYED' || scope.row.orderStatus ==='REQUEST_ETH_FAIL' || scope.row.orderStatus ==='FAIL'"
                @click="toRefundGoods(scope.row)"
                type="text" size="small">退款
            </el-button>

          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog :before-close="handleClose" :modal="true" :title="dialogState.title"
               :visible.sync="dialogState.show" v-if="dialogState.show">
      <div style="width: 96%;">
        <el-form label-position="right" ref="objItem" :model="objItem" :rules="rules" label-width="160px" size="mini"
        >
          <el-form-item label="ID" prop="id">
            <el-input v-model="objItem.id" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="订单ID" prop="orderNo">
            <el-input v-model="objItem.goodsName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="用户Id" prop="userId">
            <el-input v-model="objItem.userId" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="商品名称" prop="goodsName">
            <el-input v-model="objItem.goodsName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="钱包地址" prop="address">
            <el-input v-model="objItem.address" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="USDT" prop="realUsdt">
            <el-input v-model="objItem.realUsdt" :disabled="true"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">
          <el-button size="mini" @click="handleClose">取消</el-button>
          <el-button v-if="this.dialogState.deliver" size="mini" @click="deliverGoods()" type="primary">发货</el-button>
          <el-button v-else size="mini" @click="refundGoods()" type="primary">退款</el-button>
        </div>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import moment from "moment";
// import {regionDataPlus} from 'element-china-area-data'
import {regionDataPlus} from 'element-china-area-data'
import {deliverOrderGoods, exchangeOrderList, refundOrderGoods} from "../../../api/payment";

export default {
  name: "ExchangeOrderList",

  data() {
    return {
      options: regionDataPlus,
      exchangeOrderList: [],

      // searchObj: {
      //   userId: null,
      //   isChain: null,
      //   mtSn: null
      // },
      // pagingOption: {
      //   page: 1,
      //   limit: 10,
      //   total: 0,
      // },

      objItem: {},

      // delMtItem: {
      //   id: null,
      //   mtSn: null,
      //   userId: null,
      //   reason: false,
      // },

      dialogState: {
        show: false,
        title: "商品发货",
        deliver: false
      },

      rules: {
        ante: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
      },

    };
  },
  created() {
    this.getExchangeOrderList();
  },

  methods: {
    handleChange(value) {
      console.log(value)
    },
    search: function () {
      this.getExchangeOrderList();
    },
    getExchangeOrderList: function () {
      this.listLoading = true;

      return exchangeOrderList().then((response) => {
        this.exchangeOrderList = response;
        this.listLoading = false;
      });
    },

    toDeliverGoods(obj) {
      this.dialogState.show = true;
      this.dialogState.title = '商品发货';
      this.dialogState.deliver = true;

      this.objItem = Object.assign({}, obj);
    },

    toRefundGoods(obj) {
      this.dialogState.show = true;
      this.dialogState.title = '商品退款';
      this.dialogState.deliver = false;
      this.objItem = Object.assign({}, obj);
    },

    deliverGoods() {
      this.$refs['objItem'].validate((valid) => {
        if (valid) {
          this.$confirm("确定操作？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.objItem)
            deliverOrderGoods(req).then(() => {
              this.$message({
                type: 'success',
                message: '成功!',
              });
              this.dialogState.show = false;
              this.getExchangeOrderList();
            });
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    refundGoods() {
      this.$refs['objItem'].validate((valid) => {
        if (valid) {
          this.$confirm("确定操作？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.objItem)
            refundOrderGoods(req).then(() => {
              this.$message({
                type: 'success',
                message: '成功!',
              });
              this.dialogState.show = false;
              this.getExchangeOrderList();
            });
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
    handleClose() {
      this.dialogState.show = false
    },
  }
}
</script>
<style>

.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>
