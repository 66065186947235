<template>
  <div>
    <el-card class="box-card">

      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">
          <el-input
              size="medium"
              placeholder="输入用户ID"
              v-model="searchObj.uid"
              style=" margin-left: 4px">
          </el-input>
          <el-input
              size="medium"
              placeholder="输入附件信息"
              v-model="searchObj.funcs"
              style=" margin-left: 4px">
          </el-input>
          <el-select v-model="searchObj.sendType" clearable placeholder="选择发送方式"
                     style="width:400px; margin-left: 4px" size="medium">
            <el-option
                v-for="item in sentTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <el-button class="action-button" @click="search()" type="primary" icon="el-icon-search" size="medium"
                     style="margin-left: 4px">搜索
          </el-button>

          <el-button class="action-button" @click="toSendMail()" type="primary" size="medium"
                     style="margin-left: 20px">发送邮件
          </el-button>
        </div>
      </div>

      <el-table :data="mailRecordList" border stripe class="table" ref="multipleTable" height="650">
        <el-table-column prop="id" label="ID" width="80" align="left"></el-table-column>

        <el-table-column prop="sendType" label="发送类型" width="180">
          <template slot-scope="scope">
            {{ sentTypeOptions.find((s) => s.value === scope.row.sendType).label }}
          </template>
        </el-table-column>

        <el-table-column prop="mailTxt" label="邮件内容" width="150">
          <template slot-scope="scope">
            <el-popover
                placement="bottom"
                title="邮件内容"
                width="200"
                trigger="click"
                :content=scope.row.mailTxt>
              <el-button slot="reference" size="mini" type="primary">点击查看内容</el-button>
            </el-popover>
            <!--            <el-tooltip class="item" effect="dark" :content=scope.row.mailTxt placement="bottom">-->
            <!--              <el-button type="primary" size="mini">邮件内容</el-button>-->
            <!--            </el-tooltip>-->
          </template>
        </el-table-column>
        <el-table-column prop="operator" width="120" label="操作人"></el-table-column>
        <el-table-column prop="mailIds" width="150" label="邮件ID列表">
          <template slot-scope="scope">
            {{ scope.row.mailIds ? JSON.stringify(scope.row.mailIds) : "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="uids" width="270" label="用户ID列表">
          <template slot-scope="scope">
            {{ scope.row.uids ? JSON.stringify(scope.row.uids) : "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="funcList" min-width="260" label="附件"></el-table-column>

        <el-table-column prop="expireTime" width="180" label="过期时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.expireTime) }}
          </template>
        </el-table-column>

        <el-table-column prop="createTime" width="180" label="创建时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>

      </el-table>
      <el-pagination v-if="mailRecordList.length > 0" @current-change="getMailRecordMtList"
                     :current-page.sync="pagingOption.page"
                     :page-size="pagingOption.limit" layout="total, prev, pager, next" :total="pagingOption.total">
      </el-pagination>
    </el-card>

    <el-dialog :before-close="handleClose" :modal="true" :title="dialogState.title"
               :visible.sync="dialogState.show" v-if="dialogState.show">
      <div style="width: 80%;">
        <el-form label-position="left" ref="newItem" :model="newItem" :rules="noticeRules" label-width="120px"
                 size="small ">

          <el-form-item label="发送方式" prop="sendType">
            <el-select v-model="newItem.sendType" clearable placeholder="选择发送方式"
                       style="width:100%; margin-left: 4px" size="medium">
              <el-option
                  v-for="item in sentTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item v-if="this.newItem.sendType == 2" label="用户ID" prop="uids">
            <el-input type="textarea" :autosize="{ minRows: 7, maxRows: 10}" v-model="newItem.uids"
                      placeholder="输入用户ID，换行隔开，最多只能发送给20个用户"/>
          </el-form-item>

          <el-form-item label="过期时间" prop="expireTime">
            <el-date-picker type="datetime" placeholder="选择过期时间，只针对全服邮件有效"
                            v-model="newItem.expireTime"
                            style="width: 100%;"
                            align="center"></el-date-picker>
          </el-form-item>

          <el-form-item label="内容" prop="txt">
            <el-input type="textarea" :autosize="{ minRows: 7, maxRows: 10}" v-model="newItem.txt"/>
          </el-form-item>

          <el-form-item label="附件">
            <el-tag v-for="tag in funcFormList" :key="tag.param" closable type="primary"
                    @close="handleFuncListClose(tag)">
              {{ tag }}
            </el-tag>
            <el-button type="primary" size="mini" @click="handleAddFunc">+
              增加
            </el-button>
          </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="sendMail">发送邮件</el-button>
        </div>

        <el-dialog :before-close="handleFuncClose" :modal="true" :title="funcDialogState.title"
                   :visible.sync="funcDialogState.show" v-if="funcDialogState.show" width="30%" append-to-body>
          <div style="width: 80%;">
            <el-form label-position="left" ref="funcForm" :model="funcForm" :rules="funcRules" label-width="120px"
                     size="mini ">

              <el-form-item label="类型" prop="type">
                <el-select v-model="funcForm.type" placeholder="选择类型"
                           size="medium">
                  <el-option
                      v-for="item in funcTypeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item v-if="this.funcForm.type == 1" label="选择道具" prop="param">
                <el-select v-model="funcForm.param" clearable filterable @blur="selectBlur" @clear="selectClear">
                  <el-option v-for="item in itemList" :key="item.id" :label="item.name" :value="item.id"/>
                </el-select>
              </el-form-item>

              <el-form-item v-if="this.funcForm.type == 10" label="选择MT" prop="param">
                <el-select v-model="funcForm.param" clearable filterable @blur="selectBlur" @clear="selectClear">
                  <el-option v-for="item in mtQualityList" :key="item.id" :label="item.name" :value="item.id"/>
                </el-select>
              </el-form-item>

              <el-form-item label="数量" prop="value">
                <el-input v-model.number="funcForm.value" autocomplete="off"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">
              <el-button @click="handleFuncClose">取消</el-button>
              <el-button type="primary" @click="addFunc">添加</el-button>
            </div>
          </div>
        </el-dialog>

      </div>
    </el-dialog>


  </div>
</template>

<script>
import moment from "moment";
import {itemList, mtQualityList, sendMail} from "@/api/game";
import {mailRecordPage} from "@/api/mail";

export default {
  name: "MailList",

  data() {
    return {
      searchObj: {
        sendType: null,
        uid: null,
        funcs: null
      },
      mailRecordList: [],
      pagingOption: {
        page: 1,
        limit: 10,
        total: 0,
      },

      itemList: [],
      mtQualityList: [],

      sentTypeOptions: [
        {value: 1, label: '全局邮件'},
        {value: 2, label: '指定用户邮件'}
      ],

      funcTypeOptions: [
        {value: 1, label: '道具'},
        {value: 10, label: 'MT'},

      ],

      dialogState: {
        title: "", // dialog 标题
        show: false, // dialog 显示控制
        disabled: false, // dialog 可编辑
        create: false, // dialog 是否为创建新用户（固定部分行不可编辑）
        action: null,
      },

      funcDialogState: {
        title: "添加附件", // dialog 标题
        show: false, // dialog 显示控制
        disabled: false, // dialog 可编辑
        create: false, // dialog 是否为创建新用户（固定部分行不可编辑）
        action: null,
      },

      newItem: {
        sendType: null,
        uids: null,
        txt: null,
        expireTime: null,
        funcList: []
      },

      newUidVisible: false,
      newUid: '',
      uids: [],

      funcForm: {
        type: 1, param: 0, name: '', value: 0
      },
      funcFormList: [],

      noticeRules: {
        sendType: [
          {required: true, message: "不能为空！", trigger: "blur"}
        ],
        expireTime: [
          {required: true, message: "不能为空！", trigger: "blur"}
        ],
        txt: [
          {required: true, message: "不能为空！", trigger: "blur"},
          {min: 1, max: 800, message: "长度有误[1~800]", trigger: "blur"}
        ]
      },

      funcRules: {
        type: [
          {required: true, message: "不能为空！", trigger: "blur"},
        ],
        param: [
          {required: true, message: "不能为空！", trigger: "blur"},
        ],
        value: [
          {required: true, message: "不能为空！", trigger: "blur"},
          {type: 'number', message: '必须大于0', min: 1}

        ]
      },
    };
  },
  created() {
    this.getItemList();
    this.getMtQualityList();
  },

  methods: {

    handleChange(value) {
      console.log(value)
    },
    search: function () {
      this.getMailRecordMtList()
      // this.$message.error("点击搜索按钮,搜索信息:" + JSON.stringify(this.searchObj))
    },

    getMailRecordMtList: function () {
      this.listLoading = true;
      let req = {
        pageNumber: this.pagingOption.page,
        pageSize: this.pagingOption.limit,
        sendType: this.searchObj.sendType,
        uid: this.searchObj.uid,
        funcs: this.searchObj.funcs
      };

      return mailRecordPage(req).then((response) => {
        console.log(response.rows);
        this.mailRecordList = response.rows;
        this.pagingOption.total = response.total;
        this.listLoading = false;
      });
    },

    getItemList: function () {
      this.listLoading = true;
      return itemList().then((response) => {
        this.itemList = response;
        this.listLoading = false;
      });

    },

    getMtQualityList: function () {
      this.listLoading = true;
      return mtQualityList().then((response) => {
        this.mtQualityList = response;
        this.listLoading = false;
      });

    },

    handleAddFunc() {
      this.funcForm = {
        type: 1, param: 0, name: '', value: 0
      }
      this.funcDialogState.show = true
    },

    addFunc: function () {
      this.$refs['funcForm'].validate(valid => {
        if (valid) {
          const tmpFunc = Object.assign({}, this.funcForm)

          for (var i = 0; i < this.funcFormList.length; i++) {
            if (this.funcFormList[i].param == tmpFunc.param) {
              this.$message.error("重复添加")
              return
            }
          }

          this.funcFormList.push(tmpFunc)
          this.funcDialogState.show = false
          this.newItem.funcList = this.funcFormList
        }
      })
    },

    toSendMail(obj) {
      this.newItem = Object.assign({}, obj);
      this.funcFormList = []
      this.uids = []
      this.dialogState = Object.assign(
          {},
          {
            title: "发送邮件",
            create: false,
            disabled: false,
            show: true,
          }
      );
    },

    sendMail: function () {
      this.$refs['newItem'].validate((valid) => {
        if (valid) {
          this.$confirm("确定发送邮件？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.newItem)

            if (this.newItem.sendType === 2) {
              if (!this.newItem.uids) {
                this.$message({
                  type: "info",
                  message: "请输入用户ID",
                });
                return;
              }
              let uidList = []
              this.newItem.uids.split("\n").forEach(code => {
                code.split(",").forEach(c => {
                  if (c && c.length > 0) {
                    uidList.push(c.trim())
                  }
                })
              })
              req.uids = uidList
            }


            req.expireTime = new Date(this.newItem.expireTime).getTime();
            req.funcList = this.newItem.funcList
            console.log(req)
            sendMail(req).then(() => {
              this.$message({
                type: 'success',
                message: '发送成功!',
              });
              this.getMailRecordMtList();
            });
            this.close()
          }).catch((e) => {
            console.log(e)
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    handleInputUidConfirm() {
      const newUid = this.newUid
      if (newUid) {
        for (var i = 0; i < this.uids.length; i++) {
          if (this.uids[i] == newUid) {
            this.$message.warning("userId重复添加")
            return
          }
        }
        this.uids.push(newUid)
        this.newItem.uids = this.uids
      }
      this.newUidVisible = false
      this.newUid = ''
    },

    showUidInput() {
      this.newUidVisible = true
      // eslint-disable-next-line no-unused-vars
      this.$nextTick(_ => {
        this.$refs.newUidInput.$refs.input.focus()
      })
    },

    handleUidsClose(tag) {
      this.uids.splice(this.uids.indexOf(tag), 1)
      console.log(this.newItem.uids)

    },

    close: function () {
      this.newItem = {}
      this.dialogState = {
        title: "",
        show: false,
        disabled: false,
        create: false,
        action: null,
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(() => {
            this.dialogState.show = false
            this.funcDialogState.show = false
            done();
          })
          .catch(() => {
          });
    },

    handleFuncClose(done) {
      this.$confirm('确认关闭？')
          .then(() => {
            this.funcDialogState.show = false
            done();
          })
          .catch(() => {
          });
    },

    handleFuncListClose(tag) {
      this.funcFormList.splice(this.funcFormList.indexOf(tag), 1)
      this.newItem.funcList = this.funcFormList
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
    selectBlur(e) {
      this.foodModel = e.target.value;
      this.$forceUpdate(); // 强制更新
    },
    selectClear() {
      this.foodModel = "";
      this.$forceUpdate(); // 强制更新
    }
  }
}
</script>

<style>
.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}

.input-new-uid {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

</style>

