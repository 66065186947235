<template>
  <div>
    <el-card class="box-card">
      <el-table :data="noticeList" ref="multipleTable" row-key="id" border stripe
                style="margin-top: 15px;">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="platform" label="平台"></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>

        <el-table-column
            label="操作"
            width="99"
            fixed="right">
          <template slot-scope="scope">
            <el-button @click="toEditNotice(scope.row)" type="text" size="small">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

    </el-card>

    <el-dialog :before-close="handleClose" :modal="true" :title="dialogState.title"
               :visible.sync="dialogState.show" v-if="dialogState.show">
      <div style="width: 80%;">
        <el-form label-position="left" ref="newItem" :model="newItem" :rules="noticeRules" label-width="120px"
                 size="small ">
          <el-form-item label="ID" prop="id" v-show="!dialogState.create">
            <el-input :disabled="!dialogState.create" v-model="newItem.id"/>
          </el-form-item>

          <el-form-item label="平台" prop="platform" v-show="!dialogState.create">
            <el-input :disabled="!dialogState.create" v-model="newItem.platform"/>
          </el-form-item>

          <el-form-item label="标题" prop="title">
            <el-input v-model="newItem.title"/>
          </el-form-item>

          <el-form-item label="内容" prop="txt">
            <el-input type="textarea" :autosize="{ minRows: 10, maxRows: 10}" v-model="newItem.txt"/>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="dialogState.action.call()">保存</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
// import {regionDataPlus} from 'element-china-area-data'
import {regionDataPlus} from 'element-china-area-data'
import {noticeList, updateNotice} from "@/api/agent";

export default {
  name: "NoticeList",

  data() {
    return {

      options: regionDataPlus,
      noticeList: [],

      dialogState: {
        title: "", // dialog 标题
        show: false, // dialog 显示控制
        disabled: false, // dialog 可编辑
        create: false, // dialog 是否为创建新用户（固定部分行不可编辑）
        action: null,
      },

      newItem: {
        id: null,
        platform: null,
        title: null,
        txt: null
      },

      noticeRules: {
        title: [
          {required: true, message: "请输入标题！", trigger: "blur"},
          {min: 2, max: 100, message: "长度有误[100]", trigger: "blur"},
        ],
        txt: [
          {required: true, message: "请输入公告内容！", trigger: "blur"},
        ]
      },
    };
  },
  created() {
    this.getNoticeList();
  },

  methods: {

    handleChange(value) {
      console.log(value)
    },
    search: function () {
      // this.getList(null, this.keyword);
      this.getNoticeList()
      // this.$message.error("点击搜索按钮,搜索信息:" + JSON.stringify(this.searchObj))
    },
    getNoticeList: function () {
      this.listLoading = true;
      return noticeList().then((response) => {
        this.noticeList = response;
        this.listLoading = false;
      });

    },

    toEditNotice(obj) {
      this.newItem = Object.assign({}, obj);
      this.dialogState = Object.assign(
          {},
          {
            title: "编辑公告",
            create: false,
            disabled: false,
            show: true,
            action: this.editNotice,
          }
      );
    },

    editNotice: function () {
      this.$refs['newItem'].validate((valid) => {
        if (valid) {
          this.$confirm("确定创建？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "error",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.newItem)
            updateNotice(req).then(() => {
              this.$message({
                type: 'success',
                message: '更新成功!',
              });
              this.getNoticeList()
            });
            this.close()
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    close: function () {
      this.newItem = {}
      this.dialogState = {
        title: "",
        show: false,
        disabled: false,
        create: false,
        action: null,
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(() => {
            this.dialogState.show = false
            done();
          })
          .catch(() => {
          });
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
  }
}
</script>

<style>
.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>

