<template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">
          <el-button class="action-button" @click="toAdd()" type="primary" size="medium"
                     style="margin-left: 4px">
            增加
          </el-button>
        </div>
      </div>

      <el-table :data="levelList" border stripe class="table" ref="multipleTable" height="700">
        <el-table-column prop="id" label="ID" width="80" align="left"></el-table-column>
        <el-table-column prop="levelName" width="200" label="名称"></el-table-column>
        <el-table-column prop="maxTimes" width="120" label="限制次数"></el-table-column>
        <el-table-column prop="rechargeCurrency" width="150" label="充值货币类型"></el-table-column>
        <el-table-column prop="rechargeAmount" width="180" label="充值金额"></el-table-column>
        <el-table-column prop="costCurrency" width="150" label="花费货币类型"></el-table-column>
        <el-table-column prop="costAmount" width="100" label="花费数量"></el-table-column>
        <el-table-column prop="compareUsdtAmount" width="110" label="等换USD金额"></el-table-column>
        <el-table-column prop="coinReward" width="100" label="赠送Chips"></el-table-column>
        <el-table-column prop="diamondReward" width="100" label="赠送钻石"></el-table-column>
        <el-table-column prop="pusdReward" width="100" label="赠送PUSD"></el-table-column>
        <el-table-column prop="luckyBagCoin" width="100" label="福袋Chips"></el-table-column>
        <el-table-column prop="luckyBagDiamond" width="100" label="福袋钻石"></el-table-column>
        <el-table-column prop="luckyBagPusd" width="100" label="福袋PUSD"></el-table-column>
        <el-table-column
            width="100"
            label="操作"
            fixed="right">
          <template slot-scope="scope">
            <el-button
                @click="toUpdate(scope.row)"
                type="text" size="small">编辑
            </el-button>
            <el-button
                @click="removeById(scope.row)"
                type="text" size="small">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog :before-close="handleClose" :modal="true" :title="dialogState.title"
               :visible.sync="dialogState.show" v-if="dialogState.show">
      <div style="width: 96%;">
        <el-form label-position="right" ref="objItem" :model="objItem" :rules="rules" label-width="160px" size="mini"
        >
          <el-form-item label="ID" prop="id" v-if="dialogState.update">
            <el-input v-model="objItem.id" :disabled="dialogState.update"></el-input>
          </el-form-item>
          <el-form-item label="名称" prop="levelName">
            <el-input v-model="objItem.levelName" :disabled="dialogState.update"></el-input>
          </el-form-item>
          <el-form-item label="最多充值次数" prop="maxTimes">
            <el-input v-model="objItem.maxTimes"></el-input>
          </el-form-item>
          <el-form-item label="充值货币类型" prop="rechargeCurrency">
            <el-select v-model="objItem.rechargeCurrency" clearable placeholder="选择">
              <el-option
                  v-for="item in currencyType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="充值数量" prop="rechargeAmount">
            <el-input v-model="objItem.rechargeAmount"></el-input>
          </el-form-item>
          <el-form-item label="花费货币类型" prop="costCurrency">
            <el-input v-model="objItem.costCurrency" :disabled="dialogState.update"></el-input>
          </el-form-item>
          <el-form-item label="等换USD数量" prop="compareUsdtAmount">
            <el-input v-model="objItem.compareUsdtAmount"></el-input>
          </el-form-item>
          <el-form-item label="花费数量" prop="costAmount">
            <el-input v-model="objItem.costAmount"></el-input>
          </el-form-item>
          <el-form-item label="赠送Chips" prop="coinReward">
            <el-input v-model="objItem.coinReward"></el-input>
          </el-form-item>
          <el-form-item label="赠送钻石" prop="diamondReward">
            <el-input v-model="objItem.diamondReward"></el-input>
          </el-form-item>
          <el-form-item label="赠送PUSD" prop="pusdReward">
            <el-input v-model="objItem.pusdReward"></el-input>
          </el-form-item>
          <el-form-item label="进入福袋Chips" prop="luckyBagCoin">
            <el-input v-model="objItem.luckyBagCoin"></el-input>
          </el-form-item>
          <el-form-item label="进入福袋钻石" prop="luckyBagDiamond">
            <el-input v-model="objItem.luckyBagDiamond"></el-input>
          </el-form-item>
          <el-form-item label="进入福袋PUSD" prop="luckyBagPusd">
            <el-input v-model="objItem.luckyBagPusd"></el-input>
          </el-form-item>
          <el-form-item label="描述" prop="details">
            <el-input v-model="objItem.details"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">
          <el-button size="mini" @click="handleClose">取消</el-button>
          <el-button v-if="dialogState.update" size="mini" @click="updateLevel()" type="primary">确认</el-button>
          <el-button v-if="!dialogState.update" size="mini" @click="addLevel()" type="primary">确认</el-button>
        </div>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import moment from "moment";
// import {regionDataPlus} from 'element-china-area-data'
import {regionDataPlus} from 'element-china-area-data'
import {addRechargeLevel, rechargeLevelList, removeRechargeById, updateRechargeLevel} from "../../../api/payment";

export default {
  name: "RechargeList",

  data() {
    return {
      options: regionDataPlus,
      levelList: [],
      currencyType: [{
        value: 'COIN',
        label: 'COIN'
      }, {
        value: 'DIAMOND',
        label: 'DIAMOND'
      }],
      // searchObj: {
      //   userId: null,
      //   isChain: null,
      //   mtSn: null
      // },
      // pagingOption: {
      //   page: 1,
      //   limit: 10,
      //   total: 0,
      // },

      objItem: {
        sb: 0,
      },

      // delMtItem: {
      //   id: null,
      //   mtSn: null,
      //   userId: null,
      //   reason: false,
      // },

      dialogState: {
        show: false,
        title: "修改充值数据",
        update: false
      },

      // delDialogState: {
      //   show: false,
      //   title: "删除MT",
      //   update: false
      // },
      rules: {
        ante: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        // ante: [{required: true, message: '不能为空', trigger: 'blur'}],
        maxTimes: [{required: true, message: '不能为空', trigger: 'blur'}],
        rechargeAmount: [{required: true, message: '不能为空', trigger: 'blur'}],
        costAmount: [{required: true, message: '不能为空', trigger: 'blur'}],
        compareUsdtAmount: [{required: true, message: '不能为空', trigger: 'blur'}],
        coinReward: [{required: true, message: '不能为空', trigger: 'blur'}],
        diamondReward: [{required: true, message: '不能为空', trigger: 'blur'}],
        pusdReward: [{required: true, message: '不能为空', trigger: 'blur'}],
        luckyBagCoin: [{required: true, message: '不能为空', trigger: 'blur'}],
        luckyBagDiamond: [{required: true, message: '不能为空', trigger: 'blur'}],
        luckyBagPusd: [{required: true, message: '不能为空', trigger: 'blur'}],
        levelName: [{required: true, message: '不能为空', trigger: 'blur'}],
        rechargeCurrency: [{required: true, message: '不能为空', trigger: 'blur'}],
        costCurrency: [{required: true, message: '不能为空', trigger: 'blur'}],
        details: [{required: true, message: '不能为空', trigger: 'blur'}],

      },

    };
  },
  created() {
    this.getRechargeLevelList();
  },

  methods: {
    handleChange(value) {
      console.log(value)
    },
    search: function () {
      this.getRechargeLevelList();
    },
    getRechargeLevelList: function () {
      this.listLoading = true;

      return rechargeLevelList().then((response) => {
        this.levelList = response;
        this.listLoading = false;
      });
    },

    toUpdate(obj) {
      this.dialogState.show = true;
      this.dialogState.update = true;
      this.objItem = Object.assign({}, obj);
    },

    toAdd(obj) {
      this.dialogState.title = "增加数据";
      this.dialogState.show = true;
      this.dialogState.update = false;
      this.objItem = Object.assign({}, obj);
    },

    updateLevel() {
      this.$refs['objItem'].validate((valid) => {
        if (valid) {
          this.$confirm("确定更新？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.objItem)
            updateRechargeLevel(req).then(() => {
              this.$message({
                type: 'success',
                message: '更新成功!',
              });
              this.dialogState.show = false;
              this.getRechargeLevelList();
            });
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    addLevel() {
      this.$refs['objItem'].validate((valid) => {
        if (valid) {
          this.$confirm("确定增加？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.objItem)
            addRechargeLevel(req).then(() => {
              this.$message({
                type: 'success',
                message: '成功!',
              });
              this.dialogState.show = false;
              this.getRechargeLevelList();
            });
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    removeById(obj) {
      this.$confirm("确定删除？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
        center: true,
      }).then(() => {
        let req = {
          id: obj.id,
        };
        removeRechargeById(req).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
          this.getRechargeLevelList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
    handleClose() {
      this.dialogState.show = false
    },
  }
}
</script>
<style>

.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>
