const state = {
    chainIdEnum: [
        {value: 'Ethereum'},
        {value: 'Terra'},
        {value: 'BSC'},
        {value: 'Polygon'},
        {value: 'ImmutableX'},
        {value: 'Localhost'},
        {value: 'Flow'},
        {value: 'TEST_BSC'},
        {value: 'TEST_Ethereum'},
        {value: 'TEST_Terra'},
        {value: 'TEST_Solana'},
        {value: 'TEST_Polygon'},
        {value: 'TEST_ImmutableX'},
        {value: 'TEST_Cardano'},
        {value: 'TEST_Flow'}
    ],
    authChannel: [
        {"id": 1, "name": "邮箱", value: "ORIGIN"},
        {"id": 2, "name": "小狐狸", value: "META_MASK"},
        {"id": 3, "name": "BEE授权", value: "BEE"},
        {"id": 4, "name": "谷歌", value: "GOOGLE"},
        {"id": 5, "name": "IOS", value: "IOS"},
        {"id": 6, "name": "BEE钱包", value: "BEE_WALLET"},
        {"id": 7, "name": "OKX", value: "OKX"}
    ],
    funcType: [
        {value: 1, label: '道具'},
        {value: 10, label: 'MT'},
    ],
    currencyType: [
        {value: 'MTS'},
        {value: 'MTS_FEE'},
        {value: 'MTH'},
        {value: 'USD'},
        {value: 'GAME_USDT'}
    ],
};

const mutations = {};

const actions = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
