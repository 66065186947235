<template>
  <!--    <img alt="Vue logo" src="./assets/logo.png">-->
  <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  <el-container style="height: 100%">
    <el-aside width="200px" class="menu-bar">
      <div class="logo-container">
        <!--        <div style="display: flex; justify-content: end; padding-right: 50px">-->
        <!--          <div style="padding-right: 20px">-->

        <!--            <el-tag>{{ (user||{}).name }}</el-tag>-->
        <!--          </div>-->
        <!--          <el-popconfirm-->
        <!--              confirmButtonText="确定"-->
        <!--              cancelButtonText="取消"-->
        <!--              icon="el-icon-info"-->
        <!--              iconColor="red"-->
        <!--              title="确定退出？"-->
        <!--              @confirm="logout">-->
        <!--            <el-button slot="reference" type="danger" size="small">logout</el-button>-->
        <!--          </el-popconfirm>-->
        <!--        </div>-->
      </div>
      <SysMenu :menus="menus" :is-collapse="isCollapse"/>
      <!--                    <router-link to="/template">模板列表</router-link>-->
      <!--                    <router-link to="/template_create">创建模板</router-link>-->
    </el-aside>
    <el-container>
      <el-header
          style="height: 55px; background-color: #2c3e50;text-align: center; color: #ecf8ff">
        <span style="line-height:55px; font-size: 20px;">MOON TOUCH 后台管理系统</span>
        <div style="float: right; margin-top: 10px">
          <el-tag style="margin-right: 20px">当前登录: {{ (user || {}).name }}</el-tag>
          <el-popconfirm
              confirmButtonText="确定"
              cancelButtonText="取消"
              icon="el-icon-info"
              iconColor="red"
              title="确定退出？"
              @confirm="logout">
            <el-button slot="reference" type="danger" size="small">点击登出</el-button>
          </el-popconfirm>
        </div>
      </el-header>
      <el-row style="margin-bottom: -20px;">
        <el-col :span="24">
          <el-tabs
              v-model="selectedTab"
              type="card"
              closable
              @tab-remove="removeTab"
              @tab-click="clickTab">
            <el-tab-pane
                v-for="(item, index) in headerTabs"
                :key="index"
                :name="item.path"
                :label="findTabLabel(item)"/>
          </el-tabs>
        </el-col>
      </el-row>
      <el-main >
        <transition name="fade-transform" mode="out-in">
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
        </transition>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script name="Home">
import SysMenu from "@/components/system/SysMenu";
import store from "@/store";

export default {
  // name: "Home",
  components: {
    SysMenu,
    // HelloWorld
  },
  data() {
    return {
      isCollapse: false,
      selectedTab: null,
    };
  },
  created() {
    // TODO vuex 检查登录状态
    this.$store.dispatch("sys/fetchUserMenu");
    this.getUserInfo().then(u => {
      u
    });
  },
  computed: {
    menus() {
      return this.$store.getters.userMenus;
    },
    user() {
      return this.$store.state.auth.user;
    },
    apps() {
      let u = this.$store.state.auth.user;
      return (u === null) ? [] : u.apps;
    },
    headerTabs() {
      return this.$store.getters.headerTabs;
    },
    selectedHeaderTabName() {
      return this.$store.getters.selectedHeaderTabName;
    },
  },
  watch: {
    selectedHeaderTabName(newVal) {
      if (newVal) {
        this.selectedTab = newVal;
      } else {
        this.selectedTab = null;
      }
    },
    headerTabs(newHeaderTabs) {
      if (newHeaderTabs.length <= 0) {
        this.$router.push("/home");
      }
    },
  },
  methods: {
    getUserInfo: function () {
      return this.$store.dispatch("auth/getUserInfo");
    },
    logout: function () {
      store.dispatch("auth/logout").then(() => {
        this.$router.replace("login");
      });
    },
    toggleMenuCollapse: function () {
      this.isCollapse = !this.isCollapse;
    },
    removeTab: function (tabName) {
      this.$store
          .dispatch("sys/removeHeaderTab", tabName)
          .then((path) => this.$router.push(path));
    },
    clickTab: function (tab) {
      if (this.$route.path === tab.name) {
        return;
      }
      this.$router.push(tab.name);
    },
    findTabLabel: function (tab) {
      if (!this.menus || this.menus.length <= 0) {
        return tab.name;
      }
      for (let i = 0; i < this.menus.length; i++) {
        let child = this.menus[i];
        let found = this.findMenuItemByTab(child, tab);
        if (found) {
          return found.title;
        }
      }
      return tab.name;
    },
    findMenuItemByTab: function (root, tab) {
      if (root.url === tab.path) {
        return root;
      }
      if (root.children) {
        for (let i = 0; i < root.children.length; i++) {
          let child = root.children[i];
          let found = this.findMenuItemByTab(child, tab);
          if (found) {
            return found;
          }
        }
      }
      return null;
    },
    onChangeApp: function (appId) {
      this.$store.dispatch("auth/onChangeAppView", appId).then(appInfo => {
        this.selectedApp = appInfo.id;
      });
    }
  },
};
</script>

<style scoped>
.menu-bar {
  background-color: #2c3e50;
  color: #cccccc;
  overflow-y: scroll;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  height: 100%;
}

.menu-bar::-webkit-scrollbar {
  display: none;
}

.menu-bar .logo-container {
  height: 40px;
  margin: 0px 0;
  width: 100%;
  text-align: center;
}

.menu-bar .logo-container .title {
  color: #eeeeee;
  font-size: 26px;
  text-transform: uppercase;
}
</style>
