import request from '@/utils/request'

export function roleList(data) {
    return request({
        url: '/sys/role/list',
        method: 'get',
        data: data
    })
}

export function rolePage(params) {
    return request({
        url: '/sys/role/page',
        method: 'get',
        params: params
    })
}


export function createRole(data) {
    return request({
        url: '/sys/role',
        method: 'post',
        data: data
    })
}

export function updateRole(data) {
    return request({
        url: '/sys/role',
        method: 'put',
        data: data
    })
}

export function removeRole(id) {
    return request({
        url: '/sys/role/' + id,
        method: 'delete',
    })
}