import request from '@/utils/request'

export function statisticsExec(data) {
    return request({
        url: '/api/statistics/exec',
        method: 'post',
        data: data
    })
}

export function statisticsResetTask() {
    return request({
        url: '/api/statistics/resetTask',
        method: 'post',
    })
}


export function statisticsPage(params) {
    return request({
        url: '/api/statistics/page',
        method: 'get',
        params: params
    })
}

export function statisticsCreate(data) {
    return request({
        url: '/api/statistics/create',
        method: 'post',
        data: data
    })
}


export function statisticsUpdate(data) {
    return request({
        url: '/api/statistics/update',
        method: 'post',
        data: data
    })
}


export function statisticsDelete(id) {
    return request({
        url: '/api/statistics/delete/' + id,
        method: 'post'
    })
}


