<template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">

          <el-input
              size="medium"
              placeholder="输入用户ID"
              v-model="searchObj.userId" clearable
              style=" margin-left: 4px">
          </el-input>

          <el-input
              size="medium"
              placeholder="输入MT序号"
              v-model="searchObj.mtSn" clearable
              style="margin-left: 4px">
          </el-input>

          <el-select v-model="searchObj.isChain" clearable placeholder="请选择MT上链状态"
                     style="width: 500px; margin-left: 4px" size="medium">
            <el-option
                v-for="item in chainOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <el-button class="action-button" @click="search()" type="primary" icon="el-icon-search" size="medium"
                     style="margin-left: 4px">
            搜索
          </el-button>
        </div>

      </div>
      <el-table :data="userMtList" border stripe class="table" ref="multipleTable" height="700">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-descriptions class="margin-top" title="MT属性" :column="5" :size="mini" border
                             style="margin-left: 10px; width: 70%">
              <el-descriptions-item label="功率加点">{{ props.row.extraPower }}</el-descriptions-item>
              <el-descriptions-item label="推进加点">{{ props.row.extraAdvance }}</el-descriptions-item>
              <el-descriptions-item label="体质加点">{{ props.row.extraPhysical }}</el-descriptions-item>
              <el-descriptions-item label="精神力加点">{{ props.row.extraSpirit }}</el-descriptions-item>
              <el-descriptions-item label="运气加点">{{ props.row.extraLuck }}</el-descriptions-item>
              <el-descriptions-item label="MT等级">{{ props.row.mtLevel }}</el-descriptions-item>
              <el-descriptions-item label="当前能量">{{ props.row.energy }}</el-descriptions-item>
              <el-descriptions-item label="当前耐久">{{ props.row.durable }}</el-descriptions-item>
              <el-descriptions-item label="当前心情">{{ props.row.mood }}</el-descriptions-item>
              <el-descriptions-item label="最后能量计算时间">{{
                  props.row.lastCalculateEnergyTime
                }}
              </el-descriptions-item>
            </el-descriptions>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="ID" width="80" align="left"></el-table-column>
        <el-table-column prop="userId" width="150" label="用户ID"></el-table-column>
        <el-table-column prop="mtSn" width="150" label="MT序号"></el-table-column>
        <el-table-column prop="dna" width="260" label="DNA"></el-table-column>

        <el-table-column prop="packageStatus" label="状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.packageStatus == 'NORMAL' ? 'success' : 'danger'" size="mini">
              {{ packageStatusOptions.find((s) => s.value === scope.row.packageStatus).label }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="fromChannel" width="130" label="来源"></el-table-column>
        <el-table-column prop="origin" width="80" label="是否创世">
          <template slot-scope="scope">
            <el-tag :type="isOrigin(scope.row.dna) ? 'danger' : 'success'" size="mini">
              {{ isOrigin(scope.row.dna) ? "是" : "否" }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="tokenId" width="100" label="tokenId"></el-table-column>
        <el-table-column prop="assetsId" width="150" label="平台资产ID"></el-table-column>
        <el-table-column prop="chainId" width="130" label="链ID"></el-table-column>

        <el-table-column prop="mainMt" width="80" label="是否上阵">
          <template slot-scope="scope">
            <el-tag :type="scope.row.mainMt ? 'danger' : 'success'" size="mini">
              {{ scope.row.mainMt ? "是" : "否" }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="canMint" width="80" label="能否铸造">
          <template slot-scope="scope">
            <el-tag :type="scope.row.canMint ? 'success' : 'danger'" size="mini">
              {{ scope.row.canMint ? "是" : "否" }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="canMint" width="80" label="能否提取">
          <template slot-scope="scope">
            <el-tag :type="scope.row.canMint ? 'success' : 'danger'" size="mini">
              {{ scope.row.canMint ? "是" : "否" }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="canMarket" width="80" label="能否出售">
          <template slot-scope="scope">
            <el-tag :type="scope.row.canMarket ? 'success' : 'danger'" size="mini">
              {{ scope.row.canMarket ? "是" : "否" }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="canLevelUp" width="80" label="能否升级">
          <template slot-scope="scope">
            <el-tag :type="scope.row.canLevelUp ? 'success' : 'danger'" size="mini">
              {{ scope.row.canLevelUp ? "是" : "否" }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" width="160" label="创建时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column
            width="100"
            label="操作"
            fixed="right">
          <template slot-scope="scope">
            <el-button
                @click="toUpdate(scope.row)"
                type="text" size="small">编辑
            </el-button>
            <el-button
                @click="toDel(scope.row)"
                type="text" size="small">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination v-if="userMtList.length > 0" @current-change="getUserMtList"
                     :current-page.sync="pagingOption.page"
                     :page-size="pagingOption.limit" layout="total, prev, pager, next" :total="pagingOption.total">
      </el-pagination>
    </el-card>

    <el-dialog :before-close="handleClose" :modal="true" :title="dialogState.title"
               :visible.sync="dialogState.show" v-if="dialogState.show">
      <div style="width: 96%;">
        <el-form label-position="left" ref="objItem" :model="objItem" label-width="120px"
                 size="small">

          <el-form-item label="是否能铸造" prop="opened">
            <el-switch
                v-model="objItem.canMint"
                active-text="可以"
                inactive-text="不能">
            </el-switch>
          </el-form-item>
          <el-form-item label="能否提取" prop="canWithdraw">
            <el-switch
                v-model="objItem.canWithdraw"
                active-text="可以"
                inactive-text="不能">
            </el-switch>
          </el-form-item>
          <el-form-item label="能否出售" prop="canMarket">
            <el-switch
                v-model="objItem.canMarket"
                active-text="可以"
                inactive-text="不能">
            </el-switch>
          </el-form-item>
          <el-form-item label="能否升级" prop="canLevelUp">
            <el-switch
                v-model="objItem.canLevelUp"
                active-text="可以"
                inactive-text="不能">
            </el-switch>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">
          <el-button @click="handleClose">取消</el-button>
          <el-button @click="updateMtOperate()" type="primary">确认</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog :before-close="handleDelClose" :modal="true" :title="delDialogState.title"
               :visible.sync="delDialogState.show" v-if="delDialogState.show">
      <div style="width: 96%;">
        <el-form label-position="left" ref="delMtItem" :model="delMtItem" label-width="120px" :rules="funcRules"
                 size="mini">
          <el-form-item label="ID" prop="id">
            <el-input size="medium" v-model="delMtItem.id" disabled/>
          </el-form-item>

          <el-form-item label="SN码" prop="mtSn">
            <el-input size="medium" v-model="delMtItem.mtSn" disabled/>
          </el-form-item>
          <el-form-item label="用户ID" prop="userId">
            <el-input size="medium" v-model="delMtItem.userId" disabled/>
          </el-form-item>
          <el-form-item label="删除原因" prop="reason">
            <el-input
                size="medium"
                placeholder="输入删除原因，可以是中文"
                v-model="delMtItem.reason">
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">
          <el-button @click="handleDelClose">取消</el-button>
          <el-button @click="removeMtById()" type="primary">确认</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import moment from "moment";
// import {regionDataPlus} from 'element-china-area-data'
import {getUserMtList, removeMtById, updateMtOperate} from "@/api/appUser";
import {regionDataPlus} from 'element-china-area-data'
import {closeLoading} from "@/utils/LoadingUtil";

export default {
  name: "UerMtList",

  data() {
    return {
      options: regionDataPlus,
      userMtList: [],

      chainOptions: [
        {value: false, label: '中心化MT'},
        {value: true, label: '上链MT'}
      ],

      packageStatusOptions: [
        {value: 'NORMAL', label: '正常'},
        {value: 'MINT_ING', label: '铸造中'},
        {value: 'RE_MINT_ING', label: '重铸中'},
        {value: 'WITHDRAW_ING', label: '提取中'},
        {value: 'WITHDRAW_SUCCESS', label: '提取成功'},
        {value: 'MINT_FAIL', label: '铸造失败'},
        {value: 'RE_MINT_FAIL', label: '重铸失败'},
        {value: 'WITHDRAW_FAIL', label: '提取失败'},
      ],

      searchObj: {
        userId: null,
        isChain: null,
        mtSn: null
      },
      pagingOption: {
        page: 1,
        limit: 10,
        total: 0,
      },

      objItem: {
        id: null,
        canMint: false,
        canWithdraw: false,
        canMarket: false,
        canLevelUp: false,
      },

      delMtItem: {
        id: null,
        mtSn: null,
        userId: null,
        reason: false,
      },

      dialogState: {
        show: false,
        title: "修改MT",
        update: false
      },

      delDialogState: {
        show: false,
        title: "删除MT",
        update: false
      },
      funcRules: {
        reason: [
          {required: true, message: "不能为空！", trigger: "blur"},
        ]
      },
    };
  },
  created() {
    this.searchObj.userId = this.$route.query.userId;

    console.log(this.searchObj.userId)
    if (this.searchObj.userId == null) {
      return;
    }
    this.getUserMtList();
  },

  methods: {
    handleChange(value) {
      console.log(value)
    },
    search: function () {
      this.getUserMtList();
    },
    getUserMtList: function () {
      this.listLoading = true;
      // if (this.searchObj.userId == null || this.searchObj.userId == '') {
      //   this.$message.warning("请输入玩家ID!!!");
      //   return;
      // }
      let req = {
        pageNumber: this.pagingOption.page,
        pageSize: this.pagingOption.limit,
        userId: this.searchObj.userId,
        isChain: this.searchObj.isChain,
        mtSn: this.searchObj.mtSn
      };

      return getUserMtList(req).then((response) => {
        console.log(response.rows);
        this.userMtList = response.rows;
        this.pagingOption.total = response.total;
        this.listLoading = false;
      });
    },

    toUpdate(obj) {
      this.dialogState.show = true;
      this.objItem.id = obj.id;
      this.objItem.canMint = obj.canMint;
      this.objItem.canWithdraw = obj.canWithdraw;
      this.objItem.canMarket = obj.canMarket;
      this.objItem.canLevelUp = obj.canLevelUp;
    },

    toDel(obj) {
      this.delDialogState.show = true;
      this.delMtItem.id = obj.id;
      this.delMtItem.mtSn = obj.mtSn;
      this.delMtItem.userId = obj.userId;
      this.delMtItem.reason = null;
    },

    updateMtOperate() {
      this.$confirm('确认修改？').then(() => {
        let req = {
          id: this.objItem.id,
          canMint: this.objItem.canMint,
          canWithdraw: this.objItem.canWithdraw,
          canMarket: this.objItem.canMarket,
          canLevelUp: this.objItem.canLevelUp,
        }
        return updateMtOperate(req).then(r => {
          if (r === true) {
            this.$message.success("success");
            this.getUserMtList();
          } else {
            this.$message.error("fail");
          }
          closeLoading()
        }).catch(() => {
          closeLoading()
        })
      })
    },

    removeMtById() {
      this.$refs['delMtItem'].validate(valid => {
        if (valid) {
          this.$confirm('确认删除 【' + this.delMtItem.mtSn + '】？').then(() => {
            let req = {
              id: this.delMtItem.id,
              reason: this.delMtItem.reason
            }
            return removeMtById(req).then(r => {
              if (r === true) {
                this.$message.success("删除成功");
                this.getUserMtList();
              } else {
                this.$message.error("删除失败");
              }
              this.handleDelClose()
            }).catch(() => {
              this.handleDelClose()
            })
          })
        }
      })
    },

    isOrigin(dna) {
      return dna.includes("#C2");
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
    handleClose() {
      this.dialogState.show = false
    },

    handleDelClose() {
      this.delDialogState.show = false
    },
  }
}
</script>
<style>

.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>
