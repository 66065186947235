<template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between;">
          <el-input v-model="searchObj.keyword" placeholder="输入用户Id/设备信息/系统类别" clearable
                    style="width: 300px;" size="small"/>
          <el-button class="action-button" @click="search()" type="primary" icon="el-icon-search" size="small"
                     style="margin-left: 4px">
            搜索
          </el-button>
        </div>
      </div>
      <el-table :data="exceptionList" border stripe class="table" ref="multipleTable" style="margin-top: 10px">
        <el-table-column prop="id" label="ID" width="80" align="left"></el-table-column>
        <el-table-column prop="userId" width="170" label="用户ID"></el-table-column>
        <el-table-column prop="systemVersion" min-width="80" label="系统版本"></el-table-column>
        <el-table-column prop="deviceInfo" min-width="200" label="设备信息"></el-table-column>

        <el-table-column prop="createTime" width="180" label="创建时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>

        <el-table-column
            label="查看"
            width="80">
          <template slot-scope="scope">
            <el-button
                @click="detailObj.show = true; detailObj.context = scope.row.exceptionInfo"
                type="text" size="small">异常详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination v-if="exceptionList.length > 0" @current-change="clientExceptionList"
                     :current-page.sync="pagingOption.page"
                     :page-size="pagingOption.limit" layout="total, prev, pager, next" :total="pagingOption.total">
      </el-pagination>
    </el-card>

    <el-dialog :modal="true" title="详情"
               :close-on-click-modal=false
               :visible.sync="detailObj.show" v-if="detailObj.show">
      <el-card>
        <json-viewer
            :value="detailObj.context"
            :expand-depth=20
            copyable
            boxed
            sort/>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
// import {regionDataPlus} from 'element-china-area-data'
import {regionDataPlus} from 'element-china-area-data'
import {getClientExceptionList} from "@/api/agent";

export default {
  name: "ClientExceptionList",

  data() {
    return {
      options: regionDataPlus,
      exceptionList: [],

      detailObj: {
        show: false,
        context: null,
      },

      searchObj: {
        keyword: null
      },

      pagingOption: {
        page: 1,
        limit: 10,
        total: 0,
      },
    };
  },
  created() {
    this.clientExceptionList();
  },

  methods: {
    handleChange(value) {
      console.log(value)
    },
    search: function () {
      this.clientExceptionList();
    },
    clientExceptionList: function () {
      this.listLoading = true;
      let pageRequest = {
        pageNumber: this.pagingOption.page,
        pageSize: this.pagingOption.limit,
        keyword: this.searchObj.keyword
      };

      return getClientExceptionList(pageRequest).then((response) => {
        this.exceptionList = response.rows;
        this.pagingOption.total = response.total;
        this.listLoading = false;
      });
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(() => {
            this.userCurrencyDialogState.show = false
            done();
          })
          .catch(() => {
          });
    },
  }
}
</script>
<style>

.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>
