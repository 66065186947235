import request from '@/utils/request'

export function roomConfigList(data) {
    return request({
        url: '/manage/hall/room/roomConfigList',
        method: 'post',
        data: data,
    })
}

export function addRoomConfig(data) {
    return request({
        url: '/manage/hall/room/addRoomConfig',
        method: 'post',
        data: data,
    })
}

export function updateRoomConfig(data) {
    return request({
        url: '/manage/hall/room/updateRoomConfig',
        method: 'post',
        data: data,
    })
}

export function roomRecordList(data) {
    return request({
        url: '/manage/hall/room/recordList',
        method: 'post',
        data: data,
    })
}

export function closeRoom(data) {
    return request({
        url: '/manage/hall/room/closeRoom',
        method: 'post',
        data: data,
    })
}

export function activityItemConfigs(data) {
    return request({
        url: '/manage/hall/act/activityItemConfigs',
        method: 'post',
        data: data,
    })
}

export function updateActivityItem(data) {
    return request({
        url: '/manage/hall/act/updateActivityItem',
        method: 'post',
        data: data,
    })
}

export function checkinConfigs(data) {
    return request({
        url: '/manage/hall/checkin/checkinConfigs',
        method: 'post',
        data: data,
    })
}

export function updateCheckinConfig(data) {
    return request({
        url: '/manage/hall/checkin/updateCheckinConfig',
        method: 'post',
        data: data,
    })
}
export function getGameStatistic(data) {
    return request({
        url: '/manage/hall/statistic/getGameStatistic',
        method: 'post',
        data: data,
    })
}