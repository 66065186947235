<template>
  <div id="app">
    <transition>
      <router-view/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {
      username: null,
      password: null,
    }
  },
  created() {
    // TODO vuex 检查登录状态
  },
  methods: {
    login: function () {
      this.$router.push({path: '/home'})
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*text-align: center;*/
  color: #2c3e50;
  /*margin-top: 16px;*/
  height: 100%;
}

html,
body {
  margin: auto;
  height: 100%;
}

.el-card .el-card__header .action-button {
  float: right;
  padding: 3px 0;
  /*clear: both;*/
}
</style>
