<template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">
          <div style="display: flex;justify-content: space-between;">
            <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">

              <el-input
                  placeholder="输入ID"
                  v-model="searchObj.id" clearable
                  style=" margin-left: 4px">
              </el-input>

              <el-select v-model="searchObj.roomStatus" clearable placeholder="请选择房间状态"
                         style="width: 500px; margin-left: 4px">
                <el-option
                    v-for="item in roomStatusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-button class="action-button" @click="search()" type="primary" icon="el-icon-search" size="medium"
                         style="margin-left: 4px">
                搜索
              </el-button>
            </div>

          </div>
        </div>
      </div>
      <el-table :data="recordList" border stripe class="table" ref="multipleTable" height="700">
        <el-table-column prop="id" label="ID" width="80" align="left"></el-table-column>
        <el-table-column prop="roomName" width="250" label="房间名称"></el-table-column>
        <el-table-column prop="currencyType" width="120" label="货币类型"></el-table-column>
        <el-table-column prop="roomStatus" width="120" label="房间状态"></el-table-column>
        <el-table-column prop="sb" width="100" label="小盲注"></el-table-column>
        <el-table-column prop="bb" width="100" label="大盲注"></el-table-column>
        <el-table-column prop="straddle" width="100" label="抓头"></el-table-column>
        <el-table-column prop="maxPlayers" width="90" label="最多人数"></el-table-column>
        <el-table-column prop="seats" width="90" label="坐下人数"></el-table-column>
        <el-table-column prop="guests" width="90" label="观看人数"></el-table-column>
        <el-table-column prop="lastGameId" width="180" label="最后一手牌Id"></el-table-column>
        <el-table-column prop="lastHandSeq" width="90" label="手牌序号"></el-table-column>
        <!--        <el-table-column prop="createTime" width="160" label="过期时间">-->
        <!--          <template slot-scope="scope">-->
        <!--            {{ dateFormat(scope.row.expiredTime) }}-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column prop="createTime" width="160" label="创建时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>

        <el-table-column
            width="120"
            label="操作"
            fixed="right">
          <template slot-scope="scope">
            <el-button
                @click="closeById(scope.row)"
                type="text" size="small">关闭房间
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination v-if="recordList.length > 0" @current-change="getRecordList"
                     :current-page.sync="pagingOption.page"
                     :page-size="pagingOption.limit" layout="total, prev, pager, next" :total="pagingOption.total">
      </el-pagination>
    </el-card>

  </div>
</template>

<script>
import moment from "moment";
// import {regionDataPlus} from 'element-china-area-data'
import {regionDataPlus} from 'element-china-area-data'
import {closeRoom, roomRecordList} from "../../../api/hall";

export default {
  name: "RoomRecordList",

  data() {
    return {
      options: regionDataPlus,
      recordList: [],

      searchObj: {
        id: null,
        roomStatus: null,
      },

      roomStatusOptions: [
        {value: 'OPEN', label: 'OPEN'},
        {value: 'HIDDEN', label: 'HIDDEN'},
        {value: 'CLOSE', label: 'CLOSE'},
      ],
      // pagingOption: {
      //   page: 1,
      //   limit: 10,
      //   total: 0,
      // },

      objItem: {},

      // delMtItem: {
      //   id: null,
      //   mtSn: null,
      //   userId: null,
      //   reason: false,
      // },

      dialogState: {
        show: false,
        title: "修改配置",
        update: false
      },
      pagingOption: {
        page: 1,
        limit: 10,
        total: 0,
      },

      // delDialogState: {
      //   show: false,
      //   title: "删除MT",
      //   update: false
      // },
      rules: {
        ante: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        // ante: [{required: true, message: '不能为空', trigger: 'blur'}],
        id: [{required: true, message: '不能为空', trigger: 'blur'}],
        goodsName: [{required: true, message: '不能为空', trigger: 'blur'}],
        goodsPic: [{required: true, message: '不能为空', trigger: 'blur'}],
        goodsDetails: [{required: true, message: '不能为空', trigger: 'blur'}],
        coinPrice: [{required: true, message: '不能为空', trigger: 'blur'}],
        diamondPrice: [{required: true, message: '不能为空', trigger: 'blur'}],
        voucherPrice: [{required: true, message: '不能为空', trigger: 'blur'}],
        showUsdt: [{required: true, message: '不能为空', trigger: 'blur'}],
        realUsdt: [{required: true, message: '不能为空', trigger: 'blur'}],
        sort: [{required: true, message: '不能为空', trigger: 'blur'}],
      },

    };
  },
  created() {
    this.getRecordList();
  },

  methods: {
    handleChange(value) {
      console.log(value)
    },
    search: function () {
      this.getRecordList();
    },
    getRecordList: function () {
      this.listLoading = true;
      let req = {
        pageNumber: this.pagingOption.page,
        pageSize: this.pagingOption.limit,
        id: this.searchObj.id,
        roomStatus: this.searchObj.roomStatus
      };
      return roomRecordList(req).then((response) => {
        this.recordList = response.rows;
        this.pagingOption.total = response.total;
        this.listLoading = false;
      });
    },

    closeById(obj) {
      this.$confirm("确定关闭？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
        center: true,
      }).then(() => {
        let req = {
          id: obj.id,
        };
        closeRoom(req).then(() => {
          this.$message({
            type: 'success',
            message: '关闭成功!',
          });
          this.getRecordList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消",
        });
      });
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
    handleClose() {
      this.dialogState.show = false
    },
  }
}
</script>
<style>

.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>
