import request from '@/utils/request'

export function getUserInfo(params) {
    return request({
        url: '/sys/user/info',
        method: 'get',
        params: params
    })
}

export function userList(params) {
    return request({
        url: '/sys/user/page',
        method: 'get',
        params: params
    })
}

export function createUser(data) {
    return request({
        url: '/sys/user',
        method: 'post',
        data: data,
    })
}

export function updateUser(data) {
    return request({
        url: '/sys/user',
        method: 'put',
        data: data
    })
}


export function changePasswd(data) {
    return request({
        url: '/sys/user/changePasswd',
        method: 'post',
        data: data
    })
}

export function updateUserStatus(data) {
    return request({
        url: '/sys/user/status',
        method: 'put',
        data: data
    })
}

export function removeUser(id) {
    return request({
        url: '/sys/user/' + id,
        method: 'delete',
    })
}

export function searchUser(params) {
    return request({
        url: '/sys/user/search',
        method: 'get',
        params: {keyword: params}
    })
}
