<template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between;">
          <el-input v-model="searchObj.keyword" placeholder="输入ID/描述/执行指令/执行参数" clearable
                    style="width: 300px;"/>
          <el-button class="action-button" @click="search()" type="primary" icon="el-icon-search" size="small"
                     style="margin-left: 4px">
            搜索
          </el-button>
        </div>
        <div style="height: 20px;padding-top: 10px;padding-left: 5%">
          <a href="http://35.76.249.31:8869" target="_blank" style="font-size: 15px">点击去下载:http://35.76.249.31:8869</a>
        </div>
        <el-button
            @click="toCreate"
            type="text">创建配置
        </el-button>
      </div>
      <el-table :data="exceptionList" border stripe class="table" ref="multipleTable" style="margin-top: 10px">
        <el-table-column prop="id" label="ID" width="80" align="left"/>
        <el-table-column prop="desc" width="170" label="描述"/>
        <el-table-column prop="cmd" min-width="200" label="执行指令"/>
        <el-table-column prop="args" min-width="200" label="执行参数">
          <template slot-scope="scope">
            <p>{{ JSON.stringify(scope.row.args) }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="scheduled" min-width="200" label="定时表达式"></el-table-column>

        <el-table-column
            label="操作"
            width="130">
          <template slot-scope="scope">
            <el-button
                @click="toUpdate(scope.row)"
                type="text" size="small">更新
            </el-button>

            <el-button
                @click="toDelete(scope.row.id)"
                type="text" size="small">删除
            </el-button>

            <el-button
                @click="statisticsExec(scope.row.id)"
                type="text" size="small">执行
            </el-button>
          </template>
        </el-table-column>
      </el-table>


      <el-dialog :before-close="handleClose" :modal="true" :title="dialogInfo.context"
                 :visible.sync="dialogInfo.show" v-if="dialogInfo.show">
        <div style="width: 90%;">
          <el-form label-position="left" :model="newItem" :rules="whiteRules" label-width="200px">
            <el-form-item label="ID" prop="id" v-if="dialogInfo.create === false">
              <el-input v-model="newItem.id" :disabled="dialogInfo.create === false"/>
            </el-form-item>
            <el-form-item label="描述(用作导出文件名)" prop="desc">
              <el-input v-model="newItem.desc"/>
            </el-form-item>
            <el-form-item label="指令" prop="cmd">
              <el-input v-model="newItem.cmd"/>
            </el-form-item>
            <el-form-item label="参数" prop="args">
              <el-input v-model="newItem.args"/>
            </el-form-item>
            <el-form-item label="定时表达式" prop="scheduled">
              <el-input v-model="newItem.scheduled"/>
            </el-form-item>

          </el-form>
          <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="dealUpdateCreate">保存</el-button>
          </div>
        </div>
      </el-dialog>

      <el-pagination v-if="exceptionList.length > 0" @current-change="getPage"
                     :current-page.sync="pagingOption.page"
                     :page-size="pagingOption.limit" layout="total, prev, pager, next" :total="pagingOption.total">
      </el-pagination>
    </el-card>

  </div>
</template>

<script>
import moment from "moment";
import {statisticsCreate, statisticsDelete, statisticsExec, statisticsPage, statisticsUpdate} from "@/api/statistics";

export default {
  name: "Statistics",

  data() {
    return {
      exceptionList: [],

      dialogInfo: {
        show: false,
        context: null,
        create: false,
      },

      newItem: {
        desc: null,
        cmd: null,
        args: null,
        scheduled: null
      },

      searchObj: {
        keyword: null
      },


      whiteRules: {
        desc: [
          {required: true, message: "不能为空！", trigger: "blur"},
        ],
        cmd: [
          {required: true, message: "不能为空！", trigger: "blur"},
        ]
      },

      pagingOption: {
        page: 1,
        limit: 10,
        total: 0,
      },
    };
  },
  created() {
    this.getPage();
  },


  methods: {
    search: function () {
      this.pagingOption.page = 1;
      this.getPage();
    },
    getPage: function () {
      let pageRequest = {
        pageNumber: this.pagingOption.page,
        pageSize: this.pagingOption.limit,
        keyword: this.searchObj.keyword
      };
      return statisticsPage(pageRequest).then((response) => {
        this.exceptionList = response.rows;
        this.pagingOption.total = response.total;
      });
    },

    dealUpdateCreate: function () {
      let args = [];
      if (this.newItem.args) {
        let s = this.newItem.args.replaceAll("[", "").replaceAll("]", "");
        console.log(s)
        if (s) {
          let newArgs = s.split(",");
          newArgs.forEach(a => {
            console.log(a)
            if (a && !isNaN(a)) {
              args.push(parseFloat(a))
            } else {
              args.push(a)
            }
          });
        }
      }

      let req = {
        id: this.newItem.id,
        desc: this.newItem.desc,
        cmd: this.newItem.cmd,
        args: args,
        scheduled: this.newItem.scheduled,
      }
      if (this.dialogInfo.create) {
        this.$confirm("确定创建？", "", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
          center: true,
        }).then(() => {
          statisticsCreate(req).then((r) => {
            if (r && r === true) {
              this.$message.success("更新成功")
              this.dialogInfo.show = false;
            }
            this.getPage()
          }).catch(() => {
            this.$message.error("更新失败")
          })
        }).catch(() => {
          this.$message.info("已取消")
        });
      } else {
        this.$confirm("确定更新？", "", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
          center: true,
        }).then(() => {
          statisticsUpdate(req).then((r) => {
            if (r && r === true) {
              this.$message.success("更新成功")
              this.dialogInfo.show = false;
            }
            this.getPage()
          }).catch(() => {
            this.$message.error("更新失败")
          })
        }).catch(() => {
          this.$message.info("已取消")
        });
      }
    },

    toCreate: function () {
      this.newItem = {
        desc: null,
        cmd: null,
        args: null,
        scheduled: null
      }
      this.dialogInfo.show = true;
      this.dialogInfo.context = '创建配置';
      this.dialogInfo.create = true;
    },

    toUpdate: function (obj) {
      this.dialogInfo.show = true;
      this.dialogInfo.context = '更新配置';
      this.dialogInfo.create = false;
      this.newItem = JSON.parse(JSON.stringify(obj));
      this.newItem.args = JSON.stringify(obj.args)
    },

    toDelete: function (id) {
      this.$confirm("确定删除？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
        center: true,
      }).then(() => {
        statisticsDelete(id).then((r) => {
          if (r && r === true) {
            this.$message.success("删除成功")
          }
          this.getPage()
        }).catch(() => {
          this.$message.error("删除失败")
        })
      }).catch(() => {
        this.$message.info("已取消")
      });
    },

    statisticsExec: function (id) {
      this.$confirm("确定执行？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
        center: true,
      }).then(() => {
        statisticsExec({id: id}).then((r) => {
          if (r) {
            this.$message.success("执行成功")
          }
          this.getPage()
        }).catch(() => {
          this.$message.error("执行失败")
        })
      }).catch(() => {
        this.$message.info("已取消")
      });
    },

    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(() => {
            this.dialogInfo.show = false
            done();
          })
          .catch(() => {
          });
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },

  }
}
</script>
<style>

.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>
