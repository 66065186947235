import request from '@/utils/request'

export function noticeList(params) {
    return request({
        url: '/api/app/agent/noticeList',
        method: 'get',
        params: params
    })
}

export function updateNotice(data) {
    return request({
        url: '/api/app/agent/updateNotice',
        method: 'post',
        data: data,
    })
}

export function versionList(params) {
    return request({
        url: '/api/app/agent/versionList',
        method: 'get',
        params: params
    })
}

export function updateVersion(data) {
    return request({
        url: '/api/app/agent/updateVersion',
        method: 'post',
        data: data,
    })
}

export function getServerStatus(params) {
    return request({
        url: '/api/app/agent/getServerStatus',
        method: 'get',
        params: params
    })
}

export function changeServerStatus(data) {
    return request({
        url: '/api/app/agent/changeServerStatus',
        method: 'post',
        data: data,
    })
}

export function oneKeyChangePackageStatus(params) {
    return request({
        url: '/api/app/agent/oneKeyChangePackageStatus',
        method: 'get',
        params: params
    })
}

export function reloadCache(params) {
    return request({
        url: '/api/app/agent/reloadCache',
        method: 'get',
        params: params
    })
}

export function addRedirectDeviceWhitelist(data) {
    return request({
        url: '/api/app/agent/addRedirectDeviceWhitelist',
        method: 'post',
        data: data,
    })
}

export function getRedirectDeviceWhitelist(params) {
    return request({
        url: '/api/app/agent/getRedirectDeviceWhitelist',
        method: 'get',
        params: params
    })
}

export function delRedirectDeviceWhitelist(params) {
    return request({
        url: '/api/app/agent/delRedirectDeviceWhitelist',
        method: 'get',
        params: params
    })
}

export function getClientExceptionList(params) {
    return request({
        url: '/api/app/agent/getClientExceptionList',
        method: 'get',
        params: params
    })
}


