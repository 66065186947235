<template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: flex-start; margin-bottom: 10px">
        <el-input
            size="medium"
            placeholder="输入用户ID"
            clearable
            style="width: 300px"
            v-model="searchObj.userId">
        </el-input>

        <el-input
            size="medium"
            placeholder="输入提取钱包地址"
            clearable
            style="width: 300px;margin-left: 4px"
            v-model="searchObj.toWalletAddress">
        </el-input>

        <el-select v-model="searchObj.recordType" placeholder="请选择记录类型" clearable
                   style="width: 220px;margin-left: 4px" size="medium">
          <el-option
              v-for="item in recordType"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>

        <el-select v-model="searchObj.chainId" placeholder="请选择链" clearable
                   style="width: 220px;margin-left: 4px" size="medium">
          <el-option
              v-for="item in ChainIdEnum"
              :key="item.value"
              :label="item.value"
              :value="item.value">
          </el-option>
        </el-select>

        <el-select v-model="searchObj.currencyType" placeholder="请选择货币类型" clearable
                   style="width: 220px;margin-left: 4px" size="medium">
          <el-option
              v-for="item in currencyType"
              :key="item.value"
              :label="item.value"
              :value="item.value">
          </el-option>
        </el-select>

        <el-select v-model="searchObj.currencyState" placeholder="请选择提取状态" clearable
                   style="width: 220px;margin-left: 4px" size="medium">
          <el-option
              v-for="item in currencyState"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-date-picker
            style="margin-left: 4px"
            size="medium"
            v-model="searchObj.searchTime"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right">
        </el-date-picker>
        <el-button class="action-button" @click="getRecord()" type="primary" icon="el-icon-search" size="small"
                   style="margin-left: 10px">
          搜索
        </el-button>
      </div>

      <el-table :data="list" border stripe class="table" ref="multipleTable" height="700">
        <el-table-column prop="id" label="ID" align="left"></el-table-column>
        <el-table-column prop="userId" width="150" label="用户ID"></el-table-column>
        <el-table-column prop="withdrawCurrencyRecordType" min-width="100" label="记录类型">
          <template slot-scope="scope">
            <el-tag size="mini"
                    :type="recordType.find(r => r.value === scope.row.withdrawCurrencyRecordType) ?
                     recordType.find(r => r.value === scope.row.withdrawCurrencyRecordType).state : 'info'">
              {{
                recordType.find(r => r.value === scope.row.withdrawCurrencyRecordType) ? recordType.find(r => r.value === scope.row.withdrawCurrencyRecordType).label : "暂无"
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="withdrawCurrency" label="得到货币类型" min-width="120"></el-table-column>
        <el-table-column prop="withdrawAmount" label="得到数量"></el-table-column>

        <el-table-column prop="costCurrency" label="消耗货币类型" min-width="120"></el-table-column>
        <el-table-column prop="costAmount" label="消耗数量"></el-table-column>

        <el-table-column prop="currencyFee" min-width="200" label="手续费"></el-table-column>
        <el-table-column prop="chainId" label="链ID" min-width="100"></el-table-column>
        <el-table-column prop="toWalletAddress" min-width="180" label="提取钱包地址"></el-table-column>
        <el-table-column prop="withdrawState" label="提取状态" min-width="80">
          <template slot-scope="scope">
            <el-tag size="mini"
                    :type="currencyState.find(r => r.value === scope.row.withdrawState) ? currencyState.find(r => r.value === scope.row.withdrawState).state : 'info'">
              {{
                currencyState.find(r => r.value === scope.row.withdrawState) ? currencyState.find(r => r.value === scope.row.withdrawState).label : "暂无"
              }}

            </el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" width="160" label="创建时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>
      </el-table>

      <el-pagination v-if="list.length > 0" @current-change="getRecord()"
                     :current-page.sync="pageOption.page"
                     :page-size="pageOption.limit" layout="total, prev, pager, next" :total="pageOption.total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import moment from "moment";
import {getUserWithdrawCurrencyRecord} from "@/api/appUser";

export default {
  name: "userWithdrawCurrencyRecord",

  computed: {
    ChainIdEnum() {
      return this.$store.state.type.chainIdEnum;
    },
    currencyType() {
      return this.$store.state.type.currencyType;
    },
  },

  data() {
    return {
      list: [],
      searchObj: {
        searchTime: [],
        userId: null,
        state: null,
        recordType: null,
        currencyType: null,
        chainId: null,
        toWalletAddress: null,
        currencyState: null,
      },

      recordType: [
        {value: 'WITHDRAW', label: "提取", state: 'info'},
        {value: 'EXCHANGE', label: "兑换", state: ''}
      ],

      currencyState: [
        {value: 'CREATE', label: "创建", state: 'info'},
        {value: 'SUCCESS', label: "成功", state: 'success'},
        {value: 'FAIL', label: "失败", state: 'danger'}
      ],

      detailObj: {
        show: false,
        obj: null,
      },

      pageOption: {
        page: 1,
        limit: 20,
        total: 0,
      },

      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    };
  },
  mounted() {
  },

  methods: {
    getRecord() {
      let p = {
        pageNumber: this.pageOption.page,
        pageSize: this.pageOption.limit,
        userId: this.searchObj.userId,
        recordType: this.searchObj.recordType,
        currencyType: this.searchObj.currencyType,
        chainId: this.searchObj.chainId,
        toWalletAddress: this.searchObj.toWalletAddress,
        currencyState: this.searchObj.currencyState,
        startTime: this.searchObj.searchTime != null && this.searchObj.searchTime[0] != null ? this.searchObj.searchTime[0].getTime() : null,
        endTime: this.searchObj.searchTime != null && this.searchObj.searchTime[1] != null ? this.searchObj.searchTime[1].getTime() : null
      };
      return getUserWithdrawCurrencyRecord(p).then((response) => {
        this.list = response.rows;
        this.pageOption.total = response.total;
        console.log(this.list)
      });
    }
    ,

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    }
    ,
  }
}
</script>
<style>
</style>
