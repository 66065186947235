<template>
  <div>
    <el-card class="box-card">

      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">
          <el-input
              size="medium"
              placeholder="输入订单号"
              v-model="searchObj.batchSn"
              style=" margin-left: 4px;width:400px;" clearable>
          </el-input>
          <el-input
              size="medium"
              placeholder="输入钱包地址"
              v-model="searchObj.address"
              style=" margin-left: 4px;width:400px;" clearable>
          </el-input>
          <el-button class="action-button" @click="search()" type="primary" icon="el-icon-search" size="medium"
                     style="margin-left: 4px">搜索
          </el-button>

          <el-button class="action-button" @click="toAddExchangePlan()" type="primary" size="medium"
                     style="margin-left: 20px">添加兑换计划
          </el-button>

          <el-button class="action-button" @click="removeByBatch()" type="danger"
                     size="medium"
                     style="margin-left: 4px">按批次删除
          </el-button>

        </div>
      </div>

      <el-table :data="systemExchangeList" border stripe class="table" ref="multipleTable" height="650">
        <el-table-column prop="id" label="ID" width="80" align="left"></el-table-column>
        <el-table-column prop="batchSn" min-width="210" label="批次ID"></el-table-column>
        <el-table-column prop="address" min-width="260" label="兑换地址"></el-table-column>
        <el-table-column prop="mtsAmount" width="100" label="MTS数量"></el-table-column>
        <el-table-column prop="usdtAmount" idth="100" label="已兑换U数量"></el-table-column>

        <el-table-column prop="nextExchangeTime" width="170" label="下一次执行时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.nextExchangeTime) }}
          </template>
        </el-table-column>

        <el-table-column prop="createTime" width="170" label="创建时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>

        <el-table-column prop="remark" width="120" label="备注"></el-table-column>

        <el-table-column
            label="操作"
            width="100"
            fixed="right">
          <template slot-scope="scope">
            <el-button @click="removeSystemExchange(scope.row.id)" type="text"
                       size="small">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination v-if="systemExchangeList.length > 0" @current-change="getSystemExchangePage"
                     :current-page.sync="pagingOption.page"
                     :page-size="pagingOption.limit" layout="total, prev, pager, next" :total="pagingOption.total">
      </el-pagination>
    </el-card>

    <el-dialog :before-close="handleClose" :modal="true" :title="dialogState.title"
               :visible.sync="dialogState.show" v-if="dialogState.show">
      <div style="width: 80%;">
        <el-form label-position="left" ref="systemExchangeItem" :model="systemExchangeItem"
                 :rules="systemExchangeItemRules"
                 label-width="120px"
                 size="small ">

          <el-form-item label="兑换MTS数量" prop="mtsAmount">
            <el-input-number :min="100" value="100" v-model="systemExchangeItem.mtsAmount" style="width: 100%">
            </el-input-number>
          </el-form-item>

          <el-form-item label="平台UID" prop="platformUid">
            <el-input v-model="systemExchangeItem.platformUid"
                      placeholder="无意义，但是需要输入一个存在的平台UID"></el-input>
          </el-form-item>

          <el-form-item label="提取地址" prop="addressList">
            <el-input type="textarea" :autosize="{ minRows: 10, maxRows: 15}" v-model="systemExchangeItem.addressList"
                      :disabled="!dialogState.create"
                      placeholder="输入地址，换行隔开，最多填写50个地址"/>
          </el-form-item>

          <el-form-item label="执行兑换时间点" prop="exchangeTime">
            <el-time-select
                v-model="systemExchangeItem.exchangeTime"
                :picker-options="{
                  start: '00:01',
                  step: '00:03',
                  end: '02:00'
                }"
                placeholder="选择时间">
            </el-time-select>
          </el-form-item>
          <el-form-item label="备注" prop="platformUid">
            <el-input v-model="systemExchangeItem.remark" placeholder="无意义，但是需要输入一个存在的平台UID"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">
          <el-button @click="handleClose">取消</el-button>
          <el-button v-show="dialogState.create" type="primary" @click="addExchangePlan">添加计划</el-button>
        </div>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import moment from "moment";
import {addSystemExchange, removeSystemExchange, systemExchangePage,} from "@/api/game";

export default {
  name: "SystemExchangeUsdtList",

  data() {
    return {
      searchObj: {
        batchSn: null,
        address: null,
      },

      systemExchangeList: [],
      pagingOption: {
        page: 1,
        limit: 10,
        total: 0,
      },

      withdrawPlanList: [],

      dialogState: {
        title: "", // dialog 标题
        show: false, // dialog 显示控制
        disabled: false, // dialog 可编辑
        create: false, // dialog 是否为创建新用户（固定部分行不可编辑）
        action: null,
      },

      systemExchangeItem: {
        platformUid: null,
        mtsAmount: 100,
        remark: null,
        addressList: null,
        exchangeTime: null,
      },

      systemExchangeItemRules: {
        platformUid: [
          {required: true, message: "不能为空！", trigger: "blur"}
        ],
        exchangeTime: [
          {required: true, message: "不能为空！", trigger: "blur"}
        ],
        mtsAmount: [
          {required: true, message: "不能为空！", trigger: "blur"}
        ],
        addressList: [
          {required: true, message: "不能为空！", trigger: "blur"},
          {min: 1, max: 4000, message: "长度有误[1~4000]", trigger: "blur"}
        ],
        remark: [
          {required: true, message: "不能为空！", trigger: "blur"}
        ],
      },
    };
  },
  created() {
    this.getSystemExchangePage();
  },

  methods: {

    handleChange(value) {
      console.log(value)
    },
    search: function () {
      this.getSystemExchangePage()
    },

    getSystemExchangePage: function () {
      this.listLoading = true;
      let req = {
        pageNumber: this.pagingOption.page,
        pageSize: this.pagingOption.limit,
        batchSn: this.searchObj.batchSn,
        address: this.searchObj.address
      };

      return systemExchangePage(req).then((response) => {
        console.log(response.rows);
        this.systemExchangeList = response.rows;
        this.pagingOption.total = response.total;
        this.listLoading = false;
      });
    },

    toAddExchangePlan(obj) {
      this.systemExchangeItem = Object.assign({}, obj);
      this.dialogState = Object.assign(
          {},
          {
            title: "添加系统兑换计划",
            create: true,
            disabled: false,
            show: true,
          }
      );
    },

    addExchangePlan: function () {
      this.$refs['systemExchangeItem'].validate((valid) => {
        if (valid) {
          this.$confirm("确定添加系统兑换计划？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.systemExchangeItem)

            let addressList = []
            this.systemExchangeItem.addressList.split("\n").forEach(code => {
              code.split(",").forEach(c => {
                if (c && c.length > 0) {
                  addressList.push(c.trim())
                }
              })
            })
            req.addressList = addressList

            let split = this.systemExchangeItem.exchangeTime.split(":")
            req.startHour = split[0]
            req.startMinutes = split[1]

            console.log(req)
            addSystemExchange(req).then(() => {
              this.$message({
                type: 'success',
                message: '发送成功!',
              });
              this.getSystemExchangePage();
            });
            this.close()
          }).catch((e) => {
            console.log(e)
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    removeByBatch() {
      if (this.searchObj.batchSn == null || this.searchObj.batchSn == '') {
        this.$message.success("填写批次号");
        return
      }
      this.removeSystemExchange(null, this.searchObj.batchSn)
    },

    removeSystemExchange(id, batchSn) {
      if (id == null && batchSn == null) {
        this.$message.success("请选择条件");
        return
      }

      this.$confirm('确认删除计划').then(() => {
        let req = {
          id: id,
          batchSn: batchSn
        }
        console.log(req)
        return removeSystemExchange(req).then(r => {
          if (r === true) {
            this.$message.success("删除成功");
            this.getSystemExchangePage();
          } else {
            this.$message.error("删除失败");
          }
          this.handleDelClose()
        }).catch(() => {
          this.handleDelClose()
        })
      })
    },

    close: function () {
      this.systemExchangeItem = {}
      this.dialogState = {
        title: "",
        show: false,
        disabled: false,
        create: false,
        action: null,
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(() => {
            this.dialogState.show = false
            this.funcDialogState.show = false
            done();
          })
          .catch(() => {
          });
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
    selectBlur(e) {
      this.foodModel = e.target.value;
      this.$forceUpdate(); // 强制更新
    },
    selectClear() {
      this.foodModel = "";
      this.$forceUpdate(); // 强制更新
    }
  }
}
</script>

<style>
.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}

.input-new-uid {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

</style>

