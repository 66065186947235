<template>
  <div>
    <el-card class="box-card">
      <div slot="header">
        <span>上门时段管理</span>
      </div>
      <div style="display: flex;justify-content: end; padding: 0 20px">
        <el-button class="action-button" @click="toDelIds" :type="this.delIdsState ? 'warning' : 'danger'" size="mini"
                   icon="el-icon-delete">
          {{ this.delIdsState ? "取消" : "批量删除" }}
        </el-button>
        <el-button class="action-button" @click="toCreate" type="primary" icon="el-icon-plus" size="mini">添加上门时段
        </el-button>
      </div>
      <div style="height: 10px"></div>
      <el-table :data="list" row-key="id" border stripe style="width: 100%">
        <el-table-column prop="timeId" width="120" label="ID">
          <template slot-scope="scope">
            <div v-if="delIdsState">
              <el-checkbox-group v-model="checkedCities" @change="handleCheckedChange">
                <el-checkbox :label="scope.row.timeId" :key="scope.row.timeId"></el-checkbox>
              </el-checkbox-group>
            </div>
            <div v-if="!delIdsState">{{ scope.row.timeId }}</div>
          </template>
        </el-table-column>

        <el-table-column width="360" label="上门时间">
          <template slot-scope="scope">
            {{ scope.row.startTime }} - {{ scope.row.endTime }}
          </template>
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
                @click="del(scope.row)"
                type="danger"
                round
                size="mini">
              删除
            </el-button>
          </template>
        </el-table-column>

      </el-table>
      <div v-if="delIdsState" style="padding: 10px">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <el-button @click="delIds" style="margin-left: 30px" type="danger" size="mini">
          确认
        </el-button>
        <div style="margin: 15px 0;"></div>

      </div>
    </el-card>

    <el-dialog
        :before-close="handleClose"
        width="40%"
        :modal="true"
        :title="dialogState.title"
        :visible.sync="dialogState.show"
        v-if="dialogState.show">
      <div>
        <el-form
            label-position="left"
            ref="newItem"
            :model="newItem"
            :rules="rules"
            label-width="120px">
          <el-form-item label="开始时间" prop="startTime">
            <el-time-select
                placeholder="起始时间" prop="startTime"
                v-model="newItem.startTime"
                :picker-options="{
                start: '07:00',
                step: '00:15',
                end: '22:00', maxTime: newItem.endTime}">
            </el-time-select>
          </el-form-item>
          <el-form-item label="结束时间" prop="endTime">
            <el-time-select prop="endTime"
                            placeholder="结束时间"
                            v-model="newItem.endTime"
                            :picker-options="{
                start: '07:00',
                step: '00:15',
                end: '22:00',minTime: newItem.startTime}">
            </el-time-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogState.action.call()">确定</el-button>
      </span>
    </el-dialog>
    <el-pagination
        v-if="list.length > 0"
        @current-change="getList"
        :current-page.sync="pagingOption.page"
        :page-size="pagingOption.limit"
        layout="total, prev, pager, next"
        :total="pagingOption.total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "Time",
  components: {},
  data() {
    return {
      list: [],

      newItem: {
        timeId: null,
        timeDesc: null,
        startTime: null,
        endTime: null,
      },
      dialogState: {
        title: "", // dialog 标题
        show: false, // dialog 显示控制
        disabled: false, // dialog 可编辑
        create: false, // dialog 是否为创建新用户（固定部分行不可编辑）
        action: null,
      },

      delIdsState: false,
      delList: [],


      checkAll: false,
      checkedCities: [],
      cities: [],
      isIndeterminate: false,

      rules: {
        startTime: [
          {required: true, message: "请选择开始时间！", trigger: "blur"},
        ],
        endTime: [
          {required: true, message: "请选择结束时间！", trigger: "blur"},
        ],
      },

      pagingOption: {
        page: 1,
        limit: 10,
        total: 0,
      },

      tmpId: 10,
    };
  },
  created() {
    this.getList();
  },

  computed: {},

  methods: {
    getList: function (keyword) {
      this.listLoading = true;
      // eslint-disable-next-line no-unused-vars
      let pageRequest = {
        pageNumber: this.pagingOption.page,
        pageSize: this.pagingOption.limit,
        keyword: keyword,
      };


      this.list.push({
        "startTime": "09:00",
        "endTime": "10:00",
        "timeDesc": "string",
        "timeId": 1
      })

      this.list.push({
        "startTime": "08:00",
        "endTime": "09:00",
        "timeDesc": "string",
        "timeId": 2
      })

      //TODO: 补消息
      // return contentGetPage(pageRequest).then((response) => {
      //   this.list = response.rows;
      //   this.pagingOption.total = response.total;
      //   this.dealCities()
      // });
      this.dealCities()
    },

    dealCities: function () {
      this.cities = []
      this.list.forEach((o) => {
        this.cities.push(o.timeId)
      })
    },

    toCreate() {
      // TODO :测试,删除
      this.newItem = {};
      this.dialogState = Object.assign(
          {},
          {
            title: "创建上门时间段",
            create: true,
            disabled: false,
            show: true,
            action: this.create,
          }
      );
    },

    del: function (obj) {
      this.$confirm("确定删除？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        center: true,
      })
          .then(() => {
            // TODO: 补接口
            console.log(obj)
            this.list.pop(obj) //临时这样写,接口补了就删除
            //TODO: 删除之后,重新调get接口,获取数据
            // this.getList()
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },

    create: function () {
      if (!this.submitForm('newItem')) {
        this.$message.error("请完善表单,再进行提交")
        return
      }
      this.$confirm("确定创建？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        center: true,
      }).then(() => {
        // TODO: 补接口
        this.newItem.timeId = this.tmpId
        this.tmpId++
        this.list.push(this.newItem) //临时这样写,接口补了就删除
        //TODO: 新增之后,重新调get接口,获取数据
        // this.getList()
        this.close()
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消创建",
        });
      });
    },

    toDelIds: function () {
      this.delIdsState = !this.delIdsState;
      this.checkedCities = [];
      this.isIndeterminate = false;
      this.checkAll = false;
    },

    delIds: function () {
      this.$confirm("确定删除？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        center: true,
      })
          .then(() => {
            // TODO: 补接口
            this.$message.error("点击批量删除按钮,信息:" + JSON.stringify(this.checkedCities))
            //TODO: 删除之后,重新调get接口,获取数据
            // this.getList()
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },

    handleCheckAllChange(val) {
      this.dealCities()
      this.checkedCities = val ? this.cities : [];
      this.isIndeterminate = false;
    },
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },

    submitForm: function (formName) {
      let s = false
      this.$refs[formName].validate((valid) => {
        s = valid;
      });
      return s;
    },

    close: function () {
      this.newItem = {}
      this.dialogState = {
        title: "",
        show: false,
        disabled: false,
        create: false,
        action: null,
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(() => {
            done();
          })
          .catch(() => {
          });
    },
  }
}
</script>

<style>

</style>
