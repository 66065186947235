<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>权限管理</span>
        <el-button class="action-button" type="text" @click="toCreate"
          >新增权限</el-button
        >
      </div>
      <SearchPanel :keyword="keyword" @onSearch="onSearch" />
      <el-table :data="list" row-key="id" border stripe style="width: 100%">
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="title" label="名称" width="180">
        </el-table-column>
        <el-table-column prop="url" label="服务器请求"> </el-table-column>
        <el-table-column prop="method" label="拥有权限"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!--                        <el-button @click="detail(scope.row)" type="primary" round size="small">查看</el-button>-->
            <el-button
              @click="toEdit(scope.row)"
              type="warning"
              round
              size="small"
              >编辑</el-button
            >
            <el-button
              @click="remove(scope.row)"
              type="danger"
              round
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <pagination
      v-show="pagingOption.total > 0"
      :total="pagingOption.total"
      :page.sync="pagingOption.page"
      :limit.sync="pagingOption.limit"
      @pagination="getList"
    />
    <el-dialog title="新增权限" :visible.sync="showCreateDialog">
      <div>
        <el-form
          label-position="left"
          :model="newOpItem"
          ref="opCreateForm"
          :rules="rules"
          label-width="80px"
        >
          <el-form-item label="名称" prop="title">
            <el-input v-model="newOpItem.title"></el-input>
          </el-form-item>
          <el-form-item label="url" prop="url">
            <el-input v-model="newOpItem.url"></el-input>
          </el-form-item>
          <el-form-item label="method" prop="method">
            <el-select v-model="newOpItem.method">
              <el-option
                v-for="(m, index) in methods"
                :key="index"
                :label="m.name"
                :value="m.value"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCreateDialog = false">取 消</el-button>
        <el-button type="primary" @click="create">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="编辑权限" :modal="true" :visible.sync="showEditDialog">
      <div>
        <el-form
          label-position="left"
          :model="newOpItem"
          ref="opEditForm"
          :rules="rules"
          label-width="80px"
        >
          <el-form-item label="名称" prop="title">
            <el-input v-model="newOpItem.title"></el-input>
          </el-form-item>
          <el-form-item label="url" prop="url">
            <el-input v-model="newOpItem.url"></el-input>
          </el-form-item>
          <el-form-item label="method" prop="method">
            <el-select v-model="newOpItem.method">
              <el-option
                v-for="(m, index) in methods"
                :key="index"
                :label="m.name"
                :value="m.value"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showEditDialog = false">取 消</el-button>
        <el-button type="primary" @click="edit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SearchPanel from "@/components/common/SearchPanel";
import {
  createOperation,
  opPage,
  removeOperation,
  updateOperation,
} from "@/api/operation";
import Pagination from "@/components/common/Pagination";
export default {
  name: "OperationList",
  components: { SearchPanel, Pagination },
  data() {
    return {
      keyword: null,
      methods: [
        {
          name: "*",
          value: "ALL",
        },
        {
          name: "GET",
          value: "GET",
        },
        {
          name: "PUT",
          value: "PUT",
        },
        {
          name: "POST",
          value: "POST",
        },
        {
          name: "DELETE",
          value: "DELETE",
        },
        {
          name: "PATCH",
          value: "PATCH",
        },
        {
          name: "OPTION",
          value: "OPTION",
        },
      ],

      listLoading: false,
      list: [],

      pagingOption: {
        page: 1,
        limit: 10,
        total: 0,
      },

      newOpItem: {},
      // 用于菜单子组件数据同步
      showCreateDialog: false,
      showEditDialog: false,

      rules: {
        title: [
          { required: true, message: "请输入名称！", trigger: "blur" },
          { min: 1, max: 20, message: "长度有误[3~20]", trigger: "blur" },
        ],
        url: [
          { required: true, message: "请输入值！", trigger: "blur" },
          { min: 1, max: 100, message: "长度有误[1~100]", trigger: "blur" },
        ],
        method: [{ required: true, message: "请选择类型！", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    onSearch: function (w) {
      this.keyword = w;
      this.getList();
    },
    getList() {
      this.listLoading = true;
      let pageRequest = {
        pageNumber: this.pagingOption.page,
        pageSize: this.pagingOption.limit,
        keyword: this.keyword,
      };
      return opPage(pageRequest).then((response) => {
        this.list = response.rows;
        this.pagingOption.total = response.total;
        this.listLoading = false;
      });
    },
    toCreate: function () {
      this.newOpItem = {};
      this.showCreateDialog = true;
    },
    toEdit: function (obj) {
      this.newOpItem = Object.assign({}, obj);
      this.showEditDialog = true;
    },
    create: function () {
      this.$confirm("确定创建权限？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        this.$refs.opCreateForm.validate((result) => {
          if (result) {
            if (
              !this.newOpItem.title ||
              this.newOpItem.title.trim().length <= 0
            ) {
              this.$message.error("需要名称！");
              return;
            }
            if (!this.newOpItem.opIds) {
              this.newOpItem.opIds = [];
            }
            createOperation(this.newOpItem).then(() => {
              this.$message({
                type: "success",
                message: "创建成功!",
              });
              this.getList();
              this.newOpItem = {};
              this.showCreateDialog = false;
            });
          }
        });
      });
    },
    edit: function () {
      this.$confirm("确定提交修改？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        this.$refs.opEditForm.validate((result) => {
          if (result) {
            if (
              this.newOpItem.title === undefined ||
              this.newOpItem.title.trim().length <= 0
            ) {
              this.$message.error("需要名称！");
              return;
            }
            if (!this.newOpItem.opIds) {
              this.newOpItem.opIds = [];
            }
            updateOperation(this.newOpItem).then(() => {
              this.$message({
                type: "success",
                message: "修改成功!",
              });
              this.getList();
              this.newOpItem = {};
              this.showEditDialog = false;
            });
          }
        });
      });
    },
    remove: function (obj) {
      this.$confirm("确定删除？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        center: true,
      })
        .then(() => {
          removeOperation(obj.id).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style>
</style>
