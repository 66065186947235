<template>
    <div>
        <el-submenu :index="menu.title" v-if="menu.isMenuGroup">
            <template slot="title"><i v-if="menu.icon" :class="menu.icon"/>{{menu.title}}</template>
            <SysMenuItem v-for="(m,index) in menu.children" :menu="m" :index="index" :key="index"/>
        </el-submenu>
        <el-menu-item v-else :index="menu.url">
            <template slot="title"><i v-if="menu.icon" :class="menu.icon"/>{{menu.title}}</template>
        </el-menu-item>
    </div>
</template>

<script>
    /**
     * 侧边栏菜单项
     */
    export default {
        name: "SysMenuItem",
        props: {
            index: Number,
            menu: Object
        },
        created() {
        }
    }
</script>

<style scoped>

</style>