import {userLogin, userLogout} from "@/api/auth";
import {getToken, removeToken, setToken} from "@/utils/auth";
import {getUserInfo} from "@/api/user";

const getDefaultState = () => {
    return {
        token: getToken(),
        user: null,
    }
};

const state = getDefaultState();

const mutations = {
    setUser: function (state, data) {
        state.user = data.user;
        if (data.token) {
            state.token = data.token;
        }
        // console.log('mutations ' + JSON.stringify(state.user))
    },
    resetState: (state) => {
        Object.assign(state, getDefaultState())
    },
};

const actions = {
    getUserInfo: function ({commit}) {
        getUserInfo().then(response => {
            commit('setUser', {user: response}, null);
        })
    },
    login: function ({commit}, loginInfo) {
        return userLogin({account: loginInfo.account, password: loginInfo.password}).then(response => {
            console.log(response)
            commit('setUser', response);
            setToken(response.token);
            return response;
        });
    },
    logout: function ({commit}) {
        return userLogout().then(() => {
            return new Promise(resolve => {
                removeToken();
                commit('resetState');
                resolve();
            })
        });
    },
    resetToken: function ({commit}) {
        return new Promise(resolve => {
            removeToken();
            commit('resetState');
            resolve();
        })
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
