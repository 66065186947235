<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>用户管理</span>
        <el-button class="action-button" type="text" @click="toCreate">创建用户</el-button>
      </div>
      <SearchPanel :keyword="keyword" @onSearch="onSearch"/>
      <el-table :data="list" row-key="id" border stripe style="width: 100%">
        <el-table-column prop="id" width="100" label="ID"></el-table-column>
        <el-table-column prop="account" label="账号" width="180">
        </el-table-column>
        <el-table-column prop="name" label="昵称" width="180">
        </el-table-column>
        <el-table-column prop="roles" label="角色">
          <template slot-scope="scope">
            <el-tag
                style="margin: 4px"
                v-for="id in scope.row.roles"
                :key="id"
                size="mini"
            >
              {{ searchRole(id).title }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-tag
                :type="scope.row.status === 'ACTIVE' ? '' : 'danger'"
                size="mini">
              {{ accountStatus.find((s) => s.id === scope.row.status).title }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="250">
          <template slot-scope="scope">
            <el-button
                @click="detail(scope.row)"
                type="primary"
                round
                size="small"
            >查看
            </el-button
            >
            <el-button
                @click="toEdit(scope.row)"
                type="warning"
                round
                size="small"
            >编辑
            </el-button
            >
            <el-button
                @click="toChangePasswd(scope.row)"
                type="warning"
                round
                size="small"
            >修改密码
            </el-button>
            <el-button
                v-if="scope.row.status === 'NORMAL'"
                @click="toggleBan(scope.row, 1)"
                type="danger"
                round
                size="small">
              禁用
            </el-button>
            <el-button
                v-if="scope.row.status === 'BANNED'"
                @click="toggleBan(scope.row, 0)"
                type="primary"
                round
                size="small"
            >
              启用
            </el-button>
            <!--                        <el-button @click="remove(scope.row)" type="danger" round size="small">删除</el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <pagination
        v-show="pagingOption.total > 0"
        :total="pagingOption.total"
        :page.sync="pagingOption.page"
        :limit.sync="pagingOption.limit"
        :close-on-click-modal=false
        @pagination="getList"
    />
    <el-dialog
        width="60%"
        :modal="true"
        :title="dialogState.title"
        :visible.sync="dialogState.show"
        v-if="dialogState.show"
    >
      <div>
        <el-form
            label-position="left"
            ref="createUserForm"
            :model="newUserItem"
            :rules="rules"
            label-width="150px"
        >
          <el-form-item label="账号" prop="account">
            <el-input
                :disabled="!dialogState.create"
                v-model="newUserItem.account"
            />
          </el-form-item>
          <el-form-item v-if="dialogState.create" label="密码" prop="password">
            <el-input show-password v-model="newUserItem.password"/>
          </el-form-item>
          <el-form-item
              v-if="dialogState.create"
              label="确认密码"
              prop="rePassword"
          >
            <el-input show-password v-model="newUserItem.rePassword"/>
          </el-form-item>
          <el-form-item label="昵称" prop="name">
            <el-input
                :disabled="dialogState.disabled"
                v-model="newUserItem.name"
            />
          </el-form-item>
          <el-form-item label="角色" prop="roles">
            <el-select
                :disabled="dialogState.disabled"
                multiple
                v-model="newUserItem.roles"
                placeholder="角色列表"
                style="width: 100%"
            >
              <el-option
                  v-for="role in roleList"
                  :key="role.id"
                  :label="role.title"
                  :value="role.id"
              />
            </el-select>
          </el-form-item>

          <el-divider/>
          <el-form-item label="邮箱" prop="email">
            <el-input
                :disabled="dialogState.disabled"
                v-model="newUserItem.email"
            />
          </el-form-item>
          <el-form-item label="电话" prop="phone">
            <el-input
                :disabled="dialogState.disabled"
                v-model="newUserItem.phone"
            />
          </el-form-item>
          <el-divider/>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogState.action.call()"
        >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
        width="60%"
        :modal="true"
        title="修改密码"
        :close-on-click-modal=false
        :visible.sync="passwdDialog.show"
        v-if="passwdDialog.show"
    >
      <div>
        <el-form
            label-position="left"
            ref="changePasswd"
            :model="passwdDialog"
            :rules="passwdRules"
            label-width="150px">
          <el-form-item label="账号" prop="account">
            <el-input
                :disabled=true
                v-model="passwdDialog.account"/>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input show-password v-model="passwdDialog.password"/>
          </el-form-item>
          <el-form-item
              label="确认密码"
              prop="rePassword">
            <el-input show-password v-model="passwdDialog.rePassword"/>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="passwdDialog.action.call()"
        >确 定</el-button
        >
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {
  changePasswd,
  createUser,
  removeUser,
  updateUser,
  updateUserStatus,
  userList,
} from "@/api/user";
import {roleList} from "@/api/role";
import SearchPanel from "@/components/common/SearchPanel";
import Pagination from "@/components/common/Pagination";

export default {
  name: "UserList",
  components: {SearchPanel, Pagination},
  data() {
    return {
      keyword: null,
      roleList: [],
      listLoading: false,
      list: [],

      pagingOption: {
        page: 1,
        limit: 10,
        total: 0,
      },

      newUserItem: {},
      dialogState: {
        title: "", // dialog 标题
        show: false, // dialog 显示控制
        disabled: false, // dialog 可编辑
        create: false, // dialog 是否为创建新用户（固定部分行不可编辑）
        action: null,
      },
      passwdDialog: {
        show: false,
        password: null,
        rePassword: null,
        account: null,
      },
      rules: {
        account: [
          {required: true, message: "请输入账号！", trigger: "blur"},
          {min: 6, max: 20, message: "长度有误[6~20]", trigger: "blur"},
        ],
        name: [
          {required: true, message: "请输入用户名！", trigger: "blur"},
          {min: 3, max: 20, message: "长度有误[3~20]", trigger: "blur"},
        ],
        password: [
          {required: true, message: "请输入密码！", trigger: "blur"},
          {min: 6, max: 20, message: "长度有误[6~20]", trigger: "blur"},
        ],
        rePassword: [
          {required: true, message: "请输入密码！", trigger: "blur"},
          {min: 6, max: 20, message: "长度有误[6~20]", trigger: "blur"},
        ],
        phone: [
          {required: true, message: "请输入手机号码！", trigger: "blur"},
          {min: 11, max: 11, message: "长度有误[11~11]", trigger: "blur"},
        ],
        roles: [
          {
            type: "array",
            required: true,
            message: "请选择角色！",
            trigger: "blur",
          },
        ],
      },

      passwdRules: {
        password: [
          {required: true, message: "请输入密码！", trigger: "blur"},
          {min: 6, max: 20, message: "长度有误[6~20]", trigger: "blur"},
        ],
        rePassword: [
          {required: true, message: "请输入密码！", trigger: "blur"},
          {min: 6, max: 20, message: "长度有误[6~20]", trigger: "blur"},
        ],
      },
    };
  },
  created() {
    this.getRoleList().then(() => this.getList());
  },
  computed: {
    accountStatus() {
      return this.$store.state.sys.accountStatus;
    },
  },
  methods: {
    onSearch: function (keyword) {
      this.getList(null, keyword);
    },
    getRoleList: function () {
      return roleList().then((response) => {
        this.roleList = response;
      });
    },
    getList(pageNumber, keyword) {
      this.listLoading = true;
      let pageRequest = {
        pageNumber: this.pagingOption.page,
        pageSize: this.pagingOption.limit,
        keyword: keyword,
      };
      return userList(pageRequest).then((response) => {
        this.list = response.rows;
        this.pagingOption.total = response.total;
        this.listLoading = false;
      });
    },
    detail: function (obj) {
      this.dialogState = Object.assign(
          {},
          {
            title: "查看用户",
            create: false,
            disabled: true,
            show: true,
            action: () => {
              this.dialogState.show = false;
            },
          }
      );
      this.newUserItem = obj;
      console.log(JSON.stringify(obj));
    },
    toCreate: function () {
      this.newUserItem = {};
      this.dialogState = Object.assign(
          {},
          {
            title: "创建用户",
            create: true,
            disabled: false,
            show: true,
            action: this.create,
          }
      );
    },
    toEdit: function (obj) {
      this.newUserItem = Object.assign({}, obj);
      this.dialogState = Object.assign(
          {},
          {
            title: "编辑用户",
            create: false,
            disabled: false,
            show: true,
            action: this.edit,
          }
      );
    },

    toChangePasswd: function (obj) {
      this.passwdDialog = {show: true, account: obj.account, uid: obj.id, action: this.changePasswd}
    },

    changePasswd() {
      this.$refs.changePasswd.validate((result) => {
        if (result) {
          if (this.passwdDialog.password === this.passwdDialog.rePassword) {
            this.$confirm("确定修改密码？", "", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              center: true,
            }).then(() => {

              let data = {
                id: this.passwdDialog.uid,
                passwd: this.passwdDialog.password,
                rePasswd: this.passwdDialog.rePassword,
              }
              changePasswd(data).then(() => {
                this.passwdDialog.show = false;
                this.$message.success("修改密码成功")
              });
            })
          } else {
            this.$message.error("两次密码不一致, 请重新输入")
          }
        }
      })
    },

    create: function () {
      this.$confirm("确定创建用户？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        this.$refs.createUserForm.validate((result) => {
          if (result) {
            if (
                !this.newUserItem.account ||
                this.newUserItem.account.trim().length <= 0
            ) {
              this.$message.error("需要账号！");
              return;
            }
            if (!this.newUserItem.roles) {
              this.newUserItem.roles = [];
            }
            if (
                this.newUserItem.region &&
                this.newUserItem.region.length === 3
            ) {
              this.newUserItem.regionProvince = this.newUserItem.region[0];
              this.newUserItem.regionCity = this.newUserItem.region[1];
              this.newUserItem.regionDistrict = this.newUserItem.region[2];
            }
            let formData = new FormData();
            Object.keys(this.newUserItem).forEach((k) => {
              let val = this.newUserItem[k];
              if (val !== null && typeof val !== "undefined") {
                formData.append(k, val);
              }
            });
            createUser(formData).then(() => {
              // createUser(this.newUserItem).then(() => {
              this.$message({
                type: "success",
                message: "创建成功!",
              });
              this.getList();
              this.newUserItem = {};
              this.dialogState.show = false;
            });
          }
        });
      });
    },
    edit: function () {
      this.$confirm("确定提交修改？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        this.$refs.createUserForm.validate((result) => {
          if (result) {
            if (
                this.newUserItem.account === undefined ||
                this.newUserItem.account.trim().length <= 0
            ) {
              this.$message.error("需要账号！");
              return;
            }
            if (!this.newUserItem.roles) {
              this.newUserItem.roles = [];
            }
            if (
                this.newUserItem.region &&
                this.newUserItem.region.length === 3
            ) {
              this.newUserItem.regionProvince = this.newUserItem.region[0];
              this.newUserItem.regionCity = this.newUserItem.region[1];
              this.newUserItem.regionDistrict = this.newUserItem.region[2];
            }
            // if (this.uploadFiles.licenseImgFile) {
            //     this.newUserItem.licenseImgFile = new FormData(this.uploadFiles.licenseImgFile);
            // }
            // if (this.uploadFiles.processGraphFile) {
            //     this.newUserItem.processGraphFile = new FormData(this.uploadFiles.processGraphFile);
            // }
            // if (this.uploadFiles.processDescFile) {
            //     this.newUserItem.processDescFile = new FormData(this.uploadFiles.processDescFile);
            // }
            let formData = new FormData();
            Object.keys(this.newUserItem).forEach((k) => {
              let val = this.newUserItem[k];
              if (val !== null && typeof val !== "undefined") {
                formData.append(k, val);
              }
            });
            updateUser(formData).then(() => {
              // updateUser(this.newUserItem).then(() => {
              this.$message({
                type: "success",
                message: "修改成功!",
              });
              this.getList();
              this.newUserItem = {};
              this.dialogState.show = false;
            });
          }
        });
      });
    },
    remove: function (obj) {
      this.$confirm("确定删除？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        center: true,
      })
          .then(() => {
            removeUser(obj.id).then(() => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    toggleBan: function (user, status) {
      this.$confirm("确定修改？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        updateUserStatus({id: user.id, status: status}).then(() => {
          // updateUser(this.newUserItem).then(() => {
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          this.getList();
          this.newUserItem = {};
        });
      });
    },
    searchRole: function (id) {
      return this.roleList.find((role) => role.id === id) || {};
    },
    onLicenseUploaded: function (f) {
      this.newUserItem.licenseImgFile = f;
      // const reader = new FileReader();
      // reader.onload = e => {
      //     const data = e.target.result;
      //     console.log(data);
      // };
      // reader.readAsDataURL(this.newUserItem.licenseImgFile);
    },
    onProcessGraphUploaded: function (f) {
      this.newUserItem.processGraphFile = f;
      // const reader = new FileReader();
      // reader.onload = e => {
      //     const data = e.target.result;
      //     console.log(data);
      // };
      // reader.readAsDataURL(this.newUserItem.licenseImgFile);
    },
    onProcessDescUploaded: function (f) {
      this.newUserItem.processDescFile = f;
      // const reader = new FileReader();
      // reader.onload = e => {
      //     const data = e.target.result;
      //     console.log(data);
      // };
      // reader.readAsDataURL(this.newUserItem.licenseImgFile);
    },
  },
};
</script>

<style>
</style>
