import request from '@/utils/request'

export function sysConfig(params) {
    return request({
        url: '/api/app/game/sysConfig',
        method: 'get',
        params: params
    })
}

export function updateConfig(data) {
    return request({
        url: '/api/app/game/updateSysConfig',
        method: 'post',
        data: data,
    })
}

export function itemList(params) {
    return request({
        url: '/api/app/game/itemList',
        method: 'get',
        params: params
    })
}

export function sendMail(data) {
    return request({
        url: '/api/app/game/sendMail',
        method: 'post',
        data: data,
    })
}

export function mtQualityList(params) {
    return request({
        url: '/api/app/game/mtQualityList',
        method: 'get',
        params: params
    })
}

export function redisList(params) {
    return request({
        url: '/api/app/game/redisList',
        method: 'get',
        params: params
    })
}

export function removeRedis(params) {
    return request({
        url: '/api/app/game/removeRedis',
        method: 'get',
        params: params
    })
}

export function burnSetting(params) {
    return request({
        url: '/api/app/game/burnSetting',
        method: 'get',
        params: params
    })
}

export function updateBurnSetting(data) {
    return request({
        url: '/api/app/game/updateBurnSetting',
        method: 'post',
        data: data,
    })
}

export function getAppCareList(params) {
    return request({
        url: '/api/app/game/getAppCareList',
        method: 'get',
        params: params
    })
}

export function updateAppCare(data) {
    return request({
        url: '/api/app/game/updateAppCare',
        method: 'post',
        data: data,
    })
}

export function addSystemWithdraw(data) {
    return request({
        url: '/api/app/game/addSystemWithdraw',
        method: 'post',
        data: data,
    })
}

export function updateSystemWithdraw(data) {
    return request({
        url: '/api/app/game/updateSystemWithdraw',
        method: 'post',
        data: data,
    })
}

export function systemWithdrawPage(params) {
    return request({
        url: '/api/app/game/systemWithdrawPage',
        method: 'get',
        params: params
    })
}

export function removeSystemWithdraw(params) {
    return request({
        url: '/api/app/game/removeSystemWithdraw',
        method: 'get',
        params: params
    })
}

export function withdrawOpenList(params) {
    return request({
        url: '/api/app/game/withdrawOpenList',
        method: 'get',
        params: params
    })
}

export function withdrawSetting(params) {
    return request({
        url: '/api/app/game/withdrawSetting',
        method: 'get',
        params: params
    })
}

export function updateWithdrawSetting(data) {
    return request({
        url: '/api/app/game/updateWithdrawSetting',
        method: 'post',
        data: data,
    })
}






export function addSystemExchange(data) {
    return request({
        url: '/api/app/game/addSystemExchange',
        method: 'post',
        data: data,
    })
}
export function systemExchangePage(params) {
    return request({
        url: '/api/app/game/systemExchangePage',
        method: 'get',
        params: params
    })
}

export function removeSystemExchange(params) {
    return request({
        url: '/api/app/game/removeSystemExchange',
        method: 'get',
        params: params
    })
}