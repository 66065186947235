<template>
  <el-card class="box-card">
    <div class="app-container">
      <el-form ref="dataForm" :rules="rules" :model="dataForm" status-icon label-width="300px">
        <el-form-item label="用户ID" prop="userId">
          <el-input v-model="dataForm.userId">
          </el-input>
        </el-form-item>

        <el-form-item label="DNA" prop="dna">
          <el-input v-model="dataForm.dna"></el-input>
        </el-form-item>

        <el-form-item label="是否能铸造" prop="canMint">
          <el-switch
              v-model="dataForm.canMint"
              active-text="可以铸造"
              inactive-text="不能铸造">
          </el-switch>
        </el-form-item>

        <el-form-item label="是否能提取" prop="canWithdraw">
          <el-switch
              v-model="dataForm.canWithdraw"
              active-text="可以提取"
              inactive-text="不能提取">
          </el-switch>
        </el-form-item>

        <el-form-item label="是否能市场出售" prop="canMarket">
          <el-switch
              v-model="dataForm.canMarket"
              active-text="可以出售"
              inactive-text="不能出售">
          </el-switch>
        </el-form-item>

        <el-form-item label="是否能升级" prop="canLevelUp">
          <el-switch
              v-model="dataForm.canLevelUp"
              active-text="可以升级"
              inactive-text="不能升级">
          </el-switch>
        </el-form-item>

        <el-form-item>
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="sendMt">确定</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>
<script>
import {sendMt2User} from "@/api/appUser";

export default {
  name: 'SendMtToUser',
  data() {
    return {
      dataForm: {
        userId: undefined,
        dna: undefined,
        canMint: false,
        canWithdraw: false,
        canMarket: false,
        canLevelUp: false,
      },
      rules: {
        userId: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        dna: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        canMint: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        canMarket: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        locked: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
      }
    }
  },
  created() {
  },
  methods: {
    cancel() {
    },
    update() {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.doUpdate()
      })
    },
    sendMt: function () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$confirm("确定发送？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.dataForm)
            sendMt2User(req).then(() => {
              this.$message({
                type: 'success',
                message: '发送成功!',
              });
              this.dataForm.userId = undefined
              this.dataForm.dna = undefined
              this.dataForm.canMint = false
              this.dataForm.canMarket = false
              this.dataForm.canWithdraw = false
              this.dataForm.canLevelUp = false
            });
            this.close()
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>
<style scoped>
.input-width {
  width: 50%;
}
</style>
