<template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: flex-start; margin-bottom: 10px">
        <el-input
            size="medium"
            placeholder="输入提取单号"
            clearable
            style="width: 300px"
            v-model="searchObj.progressSn">
        </el-input>
        <el-input
            size="medium"
            placeholder="输入计划组订单"
            clearable
            style="width: 300px"
            v-model="searchObj.orderSn">
        </el-input>
        <el-input
            size="medium"
            placeholder="输入用户ID"
            clearable
            style="width: 300px"
            v-model="searchObj.userId">
        </el-input>

        <el-input
            size="medium"
            placeholder="输入到账钱包地址"
            clearable
            style="width: 300px;margin-left: 4px"
            v-model="searchObj.toWalletAddress">
        </el-input>

        <el-select v-model="searchObj.progressStatus" placeholder="请选择提取状态" clearable
                   style="width: 220px;margin-left: 4px" size="medium">
          <el-option
              v-for="item in progressStatusOperation"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>

        <el-button class="action-button" @click="getWithdrawProgress()" type="primary" icon="el-icon-search"
                   size="small"
                   style="margin-left: 10px">
          搜索
        </el-button>
      </div>

      <el-table :data="list" border stripe class="table" ref="multipleTable" height="700">
        <el-table-column prop="id" label="ID" width="80" align="left"></el-table-column>
        <el-table-column prop="userId" width="150" label="用户ID"></el-table-column>
        <el-table-column prop="progressSn" width="150" label="进度订单号"></el-table-column>
        <el-table-column prop="withdrawType" width="110" label="提取类型">
          <template slot-scope="scope">
            <el-tag style="margin-right: 10px" :type="withdrawTypeOperation.find(r => r.value === scope.row.withdrawType).state">
              {{
                withdrawTypeOperation.find((s) => s.value === scope.row.withdrawType).label
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="currencyType" width="80" label="货币类型"></el-table-column>
        <el-table-column prop="progress" width="80" label="进度"></el-table-column>
        <el-table-column prop="withdrawAmount" width="80" label="提取数量"></el-table-column>
        <el-table-column prop="toWalletAddress" width="205" label="提取钱包"></el-table-column>

        <el-table-column prop="progressStatus" width="130" label="提取状态">
          <template slot-scope="scope">
            <el-tag style="margin-right: 10px" :type="progressStatusOperation.find(r => r.value === scope.row.progressStatus).state">
              {{
                progressStatusOperation.find((s) => s.value === scope.row.progressStatus).label
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="startTime" width="170" label="执行提取时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.startTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" width="170" label="创建时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="notifyTime" width="170" label="链通知时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.notifyTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="chainId" width="130" label="链ID"></el-table-column>

        <el-table-column prop="orderSn" width="160" label="订单号"></el-table-column>

        <el-table-column prop="progressId" width="110" label="配置ID"></el-table-column>
        <el-table-column
            label="操作"
            width="100"
            fixed="right">
          <template slot-scope="scope">
            <el-button v-if="scope.row.progressStatus !== 'WITHDRAW_SUCCESS'" @click="doForceWithdraw(scope.row.id)"
                       type="danger" size="mini">强行提取
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination v-if="list.length > 0" @current-change="getWithdrawProgress()"
                     :current-page.sync="pageOption.page"
                     :page-size="pageOption.limit" layout="total, prev, pager, next" :total="pageOption.total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import moment from "moment";
import {forceWithdraw, getWithdrawCurrencyProgress} from "@/api/appUser";

export default {
  name: "WithdrawCurrencyProgress",

  computed: {
    ChainIdEnum() {
      return this.$store.state.type.chainIdEnum;
    },
    currencyType() {
      return this.$store.state.type.currencyType;
    },
  },

  data() {
    return {
      list: [],
      searchObj: {
        searchTime: [],
        userId: null,
        progressSn: null,
        orderSn: null,
        toWalletAddress: null,
        progressStatus: null,
      },

      progressStatusOperation: [
        {value: 'CREATE', label: "创建", state: 'success'},
        {value: 'CANT_WITHDRAW', label: "禁止提取", state: 'danger'},
        {value: 'REQUEST_PLATFORM_SUCCESS', label: "请求平台成功", state: 'success'},
        {value: 'REQUEST_PLATFORM_FAIL', label: "请求平台失败", state: 'danger'},
        {value: 'WITHDRAW_SUCCESS', label: "提取成功", state: 'success'},
        {value: 'WITHDRAW_FAIL', label: "提取失败", state: 'danger'},
      ],

      withdrawTypeOperation: [
        {value: 'ITEM', label: "提取道具", state: 'danger'},
        {value: 'POOL', label: "提取池", state: 'success'},
        {value: 'SYSTEM', label: "后台系统", state: 'success'},
      ],

      detailObj: {
        show: false,
        obj: null,
      },

      pageOption: {
        page: 1,
        limit: 20,
        total: 0,
      },

      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    };
  },
  mounted() {
    // this.getWithdrawProgress();
  },

  methods: {
    getWithdrawProgress() {
      let p = {
        pageNumber: this.pageOption.page,
        pageSize: this.pageOption.limit,
        userId: this.searchObj.userId,
        progressSn: this.searchObj.progressSn,
        orderSn: this.searchObj.orderSn,
        toWalletAddress: this.searchObj.toWalletAddress,
        progressStatus: this.searchObj.progressStatus,
      };
      return getWithdrawCurrencyProgress(p).then((response) => {
        this.list = response.rows;
        this.pageOption.total = response.total;
        console.log(this.list)
      });
    },

    doForceWithdraw(obj) {
      this.$confirm("确定强制提取，不等待解锁时间？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
        center: true,
      }).then(() => {
        let req = {
          id: obj
        }
        forceWithdraw(req).then(() => {
          this.$message({
            type: 'success',
            message: '操作成功!',
          });
          this.getWithdrawProgress()
        });
        this.close()
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消",
        });
      });
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
  }
}
</script>
<style>
</style>
