const state = {
    jobStatus: [
        {id: 'EDIT', value: 1, title: '未发布', face: 'default'},
        {id: 'PUBLISHED', value: 2, title: '已发布', face: 'success'},
        {id: 'CLOSED', value: 3, title: '已关闭', face: 'danger'},
    ],

    jobDegree: [
        {id: 'NONE', value: 1, title: '无要求'},
        {id: 'COLLEGE', value: 2, title: '大专'},
        {id: 'BACHELOR', value: 3, title: '本科'},
        {id: 'MASTER', value: 4, title: '硕士'},
        {id: 'DOCTOR', value: 5, title: '博士'},
    ],

    salaryPeriod: [
        {id: 'MONTH', value: 1, title: '月薪', face: '月'},
        {id: 'YEAR', value: 2, title: '年薪', face: '年'},
        {id: 'DAY', value: 3, title: '日薪', face: '天'},
    ],
};

const mutations = {};

const actions = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
