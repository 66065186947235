<template>
  <div>
    <el-card class="box-card">

      <el-table :data="appCareList" ref="multipleTable" row-key="id" border stripe
                style="margin-top: 15px;">
        <el-table-column prop="id" width="80" label="ID"></el-table-column>
        <el-table-column prop="contractAddress" min-width="200" label="合约地址"></el-table-column>
        <el-table-column prop="contractEvent" label="事件名"></el-table-column>
        <el-table-column prop="contractType" width="100" label="事件类型"></el-table-column>
        <el-table-column prop="chainId" label="链ID"></el-table-column>
        <el-table-column prop="active" label="是否激活">
          <template slot-scope="scope">
            <el-tag :type="scope.row.active === true ?'danger' : 'success'" size="mini">
              {{ scope.row.active === true ? '已激活' : '未激活' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="eventParamJson" label="事件参数" min-width="220"></el-table-column>

        <el-table-column prop="fullMatch" label="是否全匹配">
          <template slot-scope="scope">
            <el-tag :type="scope.row.fullMatch === true ?'danger' : 'success'" size="mini">
              {{ scope.row.fullMatch === true ? '全匹配' : '部分匹配' }}
            </el-tag>
          </template>
        </el-table-column>


        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button @click="toUpdateAppCare(scope.row)" type="text" size="small">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog :before-close="handleClose" :modal="true" :title="dialogState.title"
               :visible.sync="dialogState.show" v-if="dialogState.show">
      <div style="width: 80%;">
        <el-form label-position="left" ref="newItem" :model="newItem" :rules="whiteRules" label-width="120px"
                 size="small">
          <!--          <el-form-item label="ID" prop="id" v-show="!dialogState.create">-->
          <!--            <el-input v-model="newItem.id"/>-->
          <!--          </el-form-item>-->
          <el-form-item label="合约地址" prop="contractAddress" v-show="!dialogState.create">
            <el-input v-model="newItem.contractAddress"/>
          </el-form-item>
          <el-form-item label="事件名" prop="contractEvent" v-show="!dialogState.create">
            <el-input v-model="newItem.contractEvent"/>
          </el-form-item>
          <el-form-item label="事件类型" prop="contractType" v-show="!dialogState.create">
            <el-input v-model="newItem.contractType"/>
          </el-form-item>
          <el-form-item label="链ID" prop="chainId" v-show="!dialogState.create">
            <el-input v-model="newItem.chainId"/>
          </el-form-item>
          <el-form-item label="合约地址" prop="active" v-show="!dialogState.create">
            <el-switch
                v-model="newItem.active"
                active-text="已激活"
                inactive-text="未启用">
            </el-switch>
          </el-form-item>
          <el-form-item label="事件参数" prop="eventParamJson" v-show="!dialogState.create">
            <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 5}" v-model="newItem.eventParamJson"
                      placeholder="JSON数组格式，例： [{'key':'to','value':'0x111111111111111111111111111111'}]"/>
          </el-form-item>
          <el-form-item label="是否全匹配" prop="fullMatch" v-show="!dialogState.create">
            <el-switch
                v-model="newItem.fullMatch"
                active-text="全匹配"
                inactive-text="部分匹配">
            </el-switch>
          </el-form-item>
          <el-form-item label="备注" prop="details" v-show="!dialogState.create">
            <el-input v-model="newItem.details"/>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="updateAppCare">保存</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
// import {regionDataPlus} from 'element-china-area-data'
import {regionDataPlus} from 'element-china-area-data'
import {getAppCareList, updateAppCare} from "@/api/game";

export default {
  name: "SnifferList",

  data() {
    return {

      options: regionDataPlus,
      appCareList: [],

      dialogState: {
        title: "", // dialog 标题
        show: false, // dialog 显示控制
        disabled: false, // dialog 可编辑
        create: false, // dialog 是否为创建新用户（固定部分行不可编辑）
        action: null,
      },

      newItem: {
        id: null,
        contractAddress: null,
        contractEvent: null,
        contractType: null,
        chainId: null,
        details: null,
        active: null,
        fullMatch: null,
        eventParamJson: null,
      },

      whiteRules: {
        contractAddress: [
          {required: true, message: "不能为空！", trigger: "blur"},
        ],
        contractEvent: [
          {required: true, message: "不能为空！", trigger: "blur"},
        ],
        contractType: [
          {required: true, message: "不能为空！", trigger: "blur"},
        ],
        chainId: [
          {required: true, message: "不能为空！", trigger: "blur"},
        ],
        details: [
          {required: true, message: "不能为空！", trigger: "blur"},
        ],
        active: [
          {required: true, message: "不能为空！", trigger: "blur"},
        ],
        fullMatch: [
          {required: true, message: "不能为空！", trigger: "blur"},
        ]
      },
    };
  },
  created() {
    this.getAppCareList();
  },

  methods: {
    handleChange(value) {
      console.log(value)
    },
    getAppCareList: function () {
      this.listLoading = true;
      return getAppCareList().then((response) => {
        this.appCareList = response;
        console.log(response)
        this.listLoading = false;
      });

    },

    toUpdateAppCare(obj) {
      this.newItem = Object.assign({}, obj);
      this.dialogState = Object.assign(
          {},
          {
            title: "编辑事件",
            create: false,
            disabled: false,
            show: true,
          }
      );
    },

    updateAppCare: function () {
      this.$refs['newItem'].validate((valid) => {
        if (valid) {
          this.$confirm("确定添加？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.newItem)
            updateAppCare(req).then(() => {
              this.$message({
                type: 'success',
                message: '添加成功!',
              });
              this.getAppCareList()
            });
            this.close()
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    close: function () {
      this.newItem = {}
      this.dialogState = {
        title: "",
        show: false,
        disabled: false,
        create: false,
        action: null,
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(() => {
            this.dialogState.show = false
            done();
          })
          .catch(() => {
          });
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
  }
}
</script>

<style>
.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>

