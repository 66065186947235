<template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: flex-start; margin-bottom: 10px">
        <el-input
            size="medium"
            placeholder="输入用户ID"
            clearable
            style="width: 300px"
            v-model="searchObj.userId">
        </el-input>
        <el-select v-model="searchObj.type" placeholder="请选择货币类型"
                   style="width: 200px; margin-left: 4px" size="medium">
          <el-option
              v-for="item in currencyType"
              :key="item.value"
              :label="item.value"
              :value="item.value">
          </el-option>
        </el-select>
        <el-date-picker
            style="margin-left: 4px"
            size="medium"
            v-model="searchObj.searchTime"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right">
        </el-date-picker>
        <el-button class="action-button" @click="getRecord()" type="primary" icon="el-icon-search" size="small"
                   style="margin-left: 10px">
          搜索
        </el-button>
      </div>

      <el-table :data="list" border stripe class="table" ref="multipleTable" height="700">
        <el-table-column prop="id" label="ID" min-width="30" align="left"></el-table-column>
        <el-table-column prop="userId" width="280" label="用户ID"></el-table-column>
        <el-table-column prop="currencyType" width="100" label="货币类型">
          <template slot-scope="scope">
            <el-tag
                :type="scope.row.currencyType === 'MTS' || scope.row.currencyType === 'MTS_FEE' ? 'warning' : scope.row.currencyType === 'USD' || scope.row.currencyType === 'GAME_USDT' ? 'success' : ''"
                size="mini">
              {{ scope.row.currencyType }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="amount" min-width="30" label="数量"></el-table-column>
        <el-table-column prop="beforeAmount" min-width="50" label="操作前数量"></el-table-column>
        <el-table-column prop="afterAmount" min-width="50" label="操作后数量"></el-table-column>
        <el-table-column prop="recordType" min-width="50" label="操作标记">
          <template slot-scope="scope">
            <el-tag :type="'success'" size="mini">
              {{ scope.row.recordName }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" width="180" label="创建时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination v-if="list.length > 0" @current-change="getRecord()"
                     :current-page.sync="pageOption.page"
                     :page-size="pageOption.limit" layout="total, prev, pager, next" :total="pageOption.total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>

import {getUserCurrencyRecord} from "@/api/appUser";
import moment from "moment";

export default {
  name: "userCurrencyRecord",

  computed: {
    currencyType() {
      return this.$store.state.type.currencyType;
    },
  },

  data() {
    return {

      list: [],

      searchObj: {
        type: null,
        userId: null,
        searchTime: null,
      },

      pageOption: {
        page: 1,
        limit: 20,
        total: 0,
      },

      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    };
  },
  mounted() {
    let currency = JSON.parse(sessionStorage.getItem('userTmpCurrency'));
    if (currency && currency.type && currency.userId) {
      this.searchObj.type = currency.type;
      this.searchObj.userId = currency.userId;
      this.getRecord();
    }
  },

  methods: {
    getRecord() {
      if (!this.searchObj.userId) {
        this.$message.warning("请输入用户ID")
        return
      }
      if (!this.searchObj.type) {
        this.$message.warning("请选择货币类型")
        return
      }
      let p = {
        pageNumber: this.pageOption.page,
        pageSize: this.pageOption.limit,
        userId: this.searchObj.userId,
        currencyType: this.searchObj.type,
        startTime: this.searchObj.searchTime != null && this.searchObj.searchTime[0] != null ? this.searchObj.searchTime[0].getTime() : null,
        endTime: this.searchObj.searchTime != null && this.searchObj.searchTime[1] != null ? this.searchObj.searchTime[1].getTime() : null
      };
      console.log(p)
      return getUserCurrencyRecord(p).then((response) => {
        this.list = response.rows;
        this.pageOption.total = response.total;
        console.log(this.list)
      });
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
  }
}
</script>
<style>

.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>
