<template>
  <el-card class="box-card">
    <div class="app-container">
      <el-form ref="dataForm" :rules="rules" :model="dataForm" status-icon label-width="300px">

        <el-form-item label="提取是否开启" prop="opened">
          <el-switch
              v-model="dataForm.opened"
              active-text="开启中"
              inactive-text="关闭中">
          </el-switch>
        </el-form-item>

        <el-form-item label="积分后台配置倍率" prop="integralRate">
          <el-input-number :min="0" v-model="dataForm.integralRate" class="input-width">
            <template slot="append">万分比（增加值）</template>
          </el-input-number>
        </el-form-item>

        <el-form-item label="平台需要的一个uid" prop="defaultPlatformUid">
          <el-input v-model="dataForm.defaultPlatformUid" class="input-width">
            <template slot="append">对于应用用没啥用,但是需要一个真实的平台UID</template>
          </el-input>
        </el-form-item>

        <el-form-item label="积分后台配置倍率开始时间" prop="startTime">
          <el-date-picker type="datetime" placeholder="选择时间" class="input-width"
                          v-model="dataForm.startTime"
                          style="width: 80%;"
                          align="center"></el-date-picker>
        </el-form-item>

        <el-form-item label="积分后台配置倍率结束时间" prop="endTime">
          <el-date-picker type="datetime" placeholder="选择时间" class="input-width"
                          v-model="dataForm.endTime"
                          style="width: 80%;"
                          align="center"></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="editSetting">更新</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>
<script>
import {updateWithdrawSetting, withdrawSetting} from '@/api/game.js'

export default {
  name: 'BurnSetting',
  data() {
    return {
      dataForm: {
        open: 0,
        integralRate: undefined,
        startTime: undefined,
        endTime: undefined,
        defaultPlatformUid:undefined
      },
      rules: {
        open: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        integralRate: [
          {required: true, message: '不能为空', trigger: 'blur'},
          // {type: "number", min: 0, message: '最小输入0', trigger: 'blur'}
        ],
        startTime: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        endTime: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        defaultPlatformUid: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init: function () {
      withdrawSetting().then(response => {
        console.log(response)
        this.dataForm = response
      })
    },
    cancel() {
      this.init()
    },
    update() {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.doUpdate()
      })
    },
    editSetting: function () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$confirm("确定更新？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.dataForm)

            req.startTime = new Date(this.dataForm.startTime).getTime();
            req.endTime = new Date(this.dataForm.endTime).getTime();
            updateWithdrawSetting(req).then(() => {
              this.$message({
                type: 'success',
                message: '更新成功!',
              });
            });
            this.close()
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>
<style scoped>
.input-width {
  width: 80%;
}
</style>
