<template>
    <el-menu class="el-menu-demo"
             background-color="#2c3e50"
             text-color="#cccccc"
             active-text-color="#ffd04b"
             :collapse="isCollapse"
             :router="true">
        <SysMenuItem v-for="(menu,index) in menus" :key="index" :menu="menu"/>
    </el-menu>
</template>

<script>
    /**
     * 侧边栏菜单
     */
    import SysMenuItem from "@/components/system/SysMenuItem";

    export default {
        name: "SysMenu",
        components: {SysMenuItem},
        props: {
            isCollapse: Boolean,
            menus: Array,
        },
        data() {
            return {
            }
        },
    }
</script>

<style scoped>
    .menu-container {
        background-color: #2c3e50;
        color: #cccccc;
    }
    .el-menu {
        border-right-color: transparent;
    }
</style>
