<template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: flex-start; margin-bottom: 10px">
        <el-input
            size="medium"
            placeholder="输入用户ID"
            clearable
            style="width: 300px"
            v-model="searchObj.userId">
        </el-input>

        <el-input
            size="medium"
            placeholder="输入平台用户ID"
            clearable
            style="width: 300px;margin-left: 4px"
            v-model="searchObj.platformUserId">
        </el-input>

        <el-select v-model="searchObj.msgType" placeholder="请选择消息类型" clearable
                   style="width: 220px;margin-left: 4px" size="medium">
          <el-option
              v-for="item in msgType"
              :key="item.value"
              :label="item.value"
              :value="item.value">
          </el-option>
        </el-select>

        <el-select v-model="searchObj.msgStatus" placeholder="请选择消息状态" clearable
                   style="width: 220px;margin-left: 4px" size="medium">
          <el-option
              v-for="item in msgStatus"
              :key="item.value"
              :label="item.value"
              :value="item.value">
          </el-option>
        </el-select>
        <el-date-picker
            style="margin-left: 4px"
            size="medium"
            v-model="searchObj.searchTime"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right">
        </el-date-picker>
        <el-button class="action-button" @click="getRecord()" type="primary" icon="el-icon-search" size="small"
                   style="margin-left: 10px">
          搜索
        </el-button>
      </div>

      <el-table :data="list" border stripe class="table" ref="multipleTable" height="700">
        <el-table-column prop="id" label="ID" align="left"></el-table-column>
        <el-table-column prop="userId" width="280" label="用户ID"></el-table-column>
        <el-table-column prop="platformUserId" width="280" label="平台用户ID"></el-table-column>
        <el-table-column prop="platformBatchId" width="280" label="平台批次"></el-table-column>
        <el-table-column prop="msgParamKey" width="280" label="参数"></el-table-column>
        <el-table-column prop="msgSn" width="280" label="消息签名"></el-table-column>
        <el-table-column prop="msgType" width="150" label="消息类型"></el-table-column>
        <el-table-column prop="msgState" width="100" label="消息状态"></el-table-column>
        <el-table-column prop="createTime" width="180" label="创建时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" width="180" label="更新时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.updateTime) }}
          </template>
        </el-table-column>

        <el-table-column
            label="操作"
            fixed="right">
          <template slot-scope="scope">
            <el-button
                @click="detailObj.show = true; detailObj.context = JSON.parse(scope.row.msgData)"
                type="text" size="small">详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog :modal="true" title="消息详情"
                 :close-on-click-modal=false
                 :visible.sync="detailObj.show" v-if="detailObj.show">
        <el-card>
          <json-viewer
              :value="detailObj.context"
              :expand-depth=20
              copyable
              boxed
              sort/>
          <!--              <pre class="preauto">{{ requ.message }}</pre>-->
        </el-card>
      </el-dialog>

      <el-pagination v-if="list.length > 0" @current-change="getRecord()"
                     :current-page.sync="pageOption.page"
                     :page-size="pageOption.limit" layout="total, prev, pager, next" :total="pageOption.total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import moment from "moment";
import {getApp2PlatformRecord} from "@/api/sync";

export default {
  name: "app2Platform",

  data() {
    return {

      list: [],

      searchObj: {
        searchTime: [],
        userId: null,
        platformUserId: null,
        msgType: null,
        msgStatus: null,
      },

      msgType: [
        {value: 'MINT_MT'},
        {value: 'MINT_BOXES'},
        {value: 'RE_MINT_MT'},
        {value: 'DEPOSIT_MT'},
        {value: 'WITHDRAW_MT'},
        {value: 'WITHDRAW_CURRENCY'},
        {value: 'CHANGE_NFT_OWN'},
        {value: 'CHANGE_CURRENCY_AMOUNT'}
      ],

      msgStatus: [
        {value: 'CREATE'},
        {value: 'SEND_SUCCESS'},
        {value: 'NOTICE'},
      ],

      detailObj: {
        show: false,
        context: null,
      },

      pageOption: {
        page: 1,
        limit: 20,
        total: 0,
      },

      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    };
  },
  mounted() {
    this.getRecord();
  },

  methods: {
    getRecord() {
      let p = {
        pageNumber: this.pageOption.page,
        pageSize: this.pageOption.limit,
        userId: this.searchObj.userId,
        platformUserId: this.searchObj.platformUserId,
        msgType: this.searchObj.msgType,
        msgStatus: this.searchObj.msgStatus,
        startTime: this.searchObj.searchTime != null && this.searchObj.searchTime[0] != null ? this.searchObj.searchTime[0].getTime() : null,
        endTime: this.searchObj.searchTime != null && this.searchObj.searchTime[1] != null ? this.searchObj.searchTime[1].getTime() : null
      };
      return getApp2PlatformRecord(p).then((response) => {
        this.list = response.rows;
        this.pageOption.total = response.total;
      });
    }
    ,

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    }
    ,
  }
}
</script>
<style>
</style>
