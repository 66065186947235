import moment from "moment";

export function dateFormat(timeStamp) {
    return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
}


//补位，如果为单数，则在前面加0
const formatNumber = n => {
    n = n.toString()
    return n[1] ? n : '0' + n
}

//毫秒
const timestampToYmd = ms => {
    let date = new Date(ms) //时间戳为10位  需*1000，时间戳为13位的话就不需要*1000
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()

    return [year, month, day].map(formatNumber).join('-')
}

//毫秒
const timestampToHms = ms => {
    let date = new Date(ms)
    const hour = date.getHours()
    const minute = date.getMinutes() + 1
    const second = date.getSeconds()
    return [hour, minute, second].map(formatNumber).join(':')
}

//毫秒
const timestampToDay = ms => {
    let weekDay = ["星期天", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
    let date = new Date(ms)
    let day = date.getDay()
    return weekDay[day]
}

export {
    timestampToDay,
    timestampToHms,
    timestampToYmd
}