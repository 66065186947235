<template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between;">
          <el-input v-model="searchObj.keyPattern" placeholder="输入redis的key值，模糊匹配" clearable
                    style="width: 700px;" size="small"/>

          <el-button class="action-button" @click="search()" type="primary" icon="el-icon-search" size="mini"
                     style="margin-left: 4px">
            查看
          </el-button>

          <el-button class="action-button" @click="removeRedis()" type="danger" size="mini"
                     style="margin-left: 4px">
            删除Redis数据
          </el-button>
        </div>
      </div>
      <el-table :data="keysList" border stripe class="table" ref="multipleTable">
        <el-table-column prop="key" min-width="200" label="key"></el-table-column>

        <el-table-column
            label="操作"
            fixed="right">
          <template slot-scope="scope">
            <el-button
                @click="detailObj.show = true; detailObj.context = JSON.parse(scope.row.value)"
                type="text" size="small">详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--      <el-pagination v-if="userList.length > 0" @current-change="getUserList"-->
      <!--                     :current-page.sync="pagingOption.page"-->
      <!--                     :page-size="pagingOption.limit" layout="total, prev, pager, next" :total="pagingOption.total">-->
      <!--      </el-pagination>-->
    </el-card>

    <el-dialog :modal="true" title="详情"
               :close-on-click-modal=false
               :visible.sync="detailObj.show" v-if="detailObj.show">
      <el-card>
        <json-viewer
            :value="detailObj.context"
            :expand-depth=20
            copyable
            boxed
            sort/>
      </el-card>
    </el-dialog>

  </div>
</template>

<script>
import moment from "moment";
// import {regionDataPlus} from 'element-china-area-data'
import {regionDataPlus} from 'element-china-area-data'
import {redisList, removeRedis} from "@/api/game";

export default {
  name: "RedisList",

  data() {
    return {
      options: regionDataPlus,
      keysList: [],
      searchObj: {
        keyPattern: null,
      },


      detailObj: {
        show: false,
        context: null,
      },
    };
  },
  created() {
  },

  methods: {
    handleChange(value) {
      console.log(value)
    },
    search: function () {
      this.getRedisList();
    },

    getRedisList: function () {
      this.listLoading = true;
      // eslint-disable-next-line no-unused-vars
      let req = {
        keyPattern: this.searchObj.keyPattern,
      };

      return redisList(req).then((response) => {
        this.keysList = response;
        console.log(response)
        this.listLoading = false;
      });
    },

    removeRedis() {
      this.$confirm("确定删除？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
        center: true,
      }).then(() => {
        let req = {
          keyPattern: this.searchObj.keyPattern,
        }
        removeRedis(req).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
          this.getRedisList()
        });
        this.close()
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消",
        });
      });
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(() => {
            this.userCurrencyDialogState.show = false
            done();
          })
          .catch(() => {
          });
    },
  }
}
</script>
<style>

.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>
