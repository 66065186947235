<template>
  <el-card class="box-card">
    <div class="app-container">
      <el-form ref="dataForm" :rules="rules" :model="dataForm" status-icon label-width="300px">
        <el-form-item label="每日兑换USDT上限" prop="EXCHANGE_USDT_LIMIT">
          <el-input v-model="dataForm.EXCHANGE_USDT_LIMIT" class="input-width">
            <template slot="append">个</template>
          </el-input>
        </el-form-item>

        <el-form-item label="最大Gas费" prop="MAX_GAS_FEE">
          <el-input v-model="dataForm.MAX_GAS_FEE" class="input-width">
            <template slot="append">wei</template>
          </el-input>
        </el-form-item>


        <el-form-item label="MTS兑换USDT比例" prop="MTS_TO_USDT_RATIO">
          <el-input v-model="dataForm.MTS_TO_USDT_RATIO" class="input-width">
            <template slot="append">小数</template>
          </el-input>
        </el-form-item>

        <el-form-item label="MTH兑换USDT比例" prop="MTH_TO_USDT_RATIO">
          <el-input v-model="dataForm.MTH_TO_USDT_RATIO" class="input-width">
            <template slot="append">小数</template>
          </el-input>
        </el-form-item>

        <el-form-item label="成就开启状态" prop="HONOR_IS_OPEN">
          <el-switch
              v-model="dataForm.HONOR_IS_OPEN"
              active-text="开启中"
              inactive-text="关闭中">
          </el-switch>
        </el-form-item>

        <el-form-item label="链上充值双倍的开关" prop="CHAIN_DEPOSIT_USDT_SWITCH">
          <el-switch
              v-model="dataForm.CHAIN_DEPOSIT_USDT_SWITCH"
              active-text="开启中"
              inactive-text="关闭中">
          </el-switch>
        </el-form-item>

        <el-form-item label="新手任务开启开关" prop="NOVICE_TASK_IS_OPEN">
          <el-switch
              v-model="dataForm.NOVICE_TASK_IS_OPEN"
              active-text="开启中"
              inactive-text="关闭中">
          </el-switch>
        </el-form-item>

        <el-form-item label="解锁活跃任务开关" prop="ACTIVE_TASK_IS_OPEN">
          <el-switch
              v-model="dataForm.ACTIVE_TASK_IS_OPEN"
              active-text="开启中"
              inactive-text="关闭中">
          </el-switch>
        </el-form-item>

        <el-form-item label="道具背包是否开放" prop="ITEM_PACK_IS_OPEN">
          <el-switch
              v-model="dataForm.ITEM_PACK_IS_OPEN"
              active-text="开启中"
              inactive-text="关闭中">
          </el-switch>
        </el-form-item>
        <el-form-item label="上链相关开关" prop="CHAIN_SWITCH">
          <el-switch
              v-model="dataForm.CHAIN_SWITCH"
              active-text="开启中"
              inactive-text="关闭中">
          </el-switch>
        </el-form-item>

        <el-form-item label="排行榜功能开关" prop="RANK_SWITCH">
          <el-switch
              v-model="dataForm.RANK_SWITCH"
              active-text="开启中"
              inactive-text="关闭中">
          </el-switch>
        </el-form-item>

        <el-form-item label="CDK功能开关" prop="CDK_SWITCH">
          <el-switch
              v-model="dataForm.CDK_SWITCH"
              active-text="开启中"
              inactive-text="关闭中">
          </el-switch>
        </el-form-item>

        <el-form-item label="邀请码开关" prop="INVITE_CODE_SWITCH">
          <el-switch
              v-model="dataForm.INVITE_CODE_SWITCH"
              active-text="开启中"
              inactive-text="关闭中">
          </el-switch>
        </el-form-item>

        <el-form-item label="开屏广告开关" prop="ADVERTISE_SWITCH">
          <el-switch
              v-model="dataForm.ADVERTISE_SWITCH"
              active-text="开启中"
              inactive-text="关闭中">
          </el-switch>
        </el-form-item>

        <el-form-item>
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="editConfig">确定</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>
<script>
import {sysConfig, updateConfig} from '@/api/game.js'

export default {
  name: 'GameConfig',
  data() {
    return {
      dataForm: {
        EXCHANGE_USDT_LIMIT: 0,
        MAX_GAS_FEE: 0,
        HONOR_IS_OPEN: undefined,
        MTS_TO_USDT_RATIO: undefined,
        MTH_TO_USDT_RATIO: undefined,
        CHAIN_DEPOSIT_USDT_SWITCH: undefined,
        NOVICE_TASK_IS_OPEN: undefined,
        ACTIVE_TASK_IS_OPEN: undefined,
        ITEM_PACK_IS_OPEN: undefined,
        CHAIN_SWITCH: undefined,
        RANK_SWITCH: undefined,
        CDK_SWITCH: undefined,
        INVITE_CODE_SWITCH: undefined,
        ADVERTISE_SWITCH: undefined,

      },
      rules: {
        EXCHANGE_USDT_LIMIT: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        MAX_GAS_FEE: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        HONOR_IS_OPEN: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        CHAIN_DEPOSIT_USDT_SWITCH: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        NOVICE_TASK_IS_OPEN: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        ACTIVE_TASK_IS_OPEN: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        ITEM_PACK_IS_OPEN: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        CHAIN_SWITCH: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        RANK_SWITCH: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        CDK_SWITCH: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        INVITE_CODE_SWITCH: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        ADVERTISE_SWITCH: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init: function () {
      sysConfig().then(response => {
        console.log(response)
        this.dataForm = Object.assign({}, response)
        this.dataForm.HONOR_IS_OPEN = this.toBool(response['HONOR_IS_OPEN'])
        this.dataForm.CHAIN_DEPOSIT_USDT_SWITCH = this.toBool(response['CHAIN_DEPOSIT_USDT_SWITCH'])
        this.dataForm.NOVICE_TASK_IS_OPEN = this.toBool(response['NOVICE_TASK_IS_OPEN'])
        this.dataForm.ACTIVE_TASK_IS_OPEN = this.toBool(response['ACTIVE_TASK_IS_OPEN'])
        this.dataForm.ITEM_PACK_IS_OPEN = this.toBool(response['ITEM_PACK_IS_OPEN'])
        this.dataForm.CHAIN_SWITCH = this.toBool(response['CHAIN_SWITCH'])
        this.dataForm.RANK_SWITCH = this.toBool(response['RANK_SWITCH'])
        this.dataForm.CDK_SWITCH = this.toBool(response['CDK_SWITCH'])
        this.dataForm.INVITE_CODE_SWITCH = this.toBool(response['INVITE_CODE_SWITCH'])
        this.dataForm.ADVERTISE_SWITCH = this.toBool(response['ADVERTISE_SWITCH'])

      })
    },

    toBool(obj) {
      if (obj) {
        if (obj === 'true') {
          return true;
        }

        if (obj === 'false') {
          return false;
        }
      }
      return false
    },
    cancel() {
      this.init()
    },
    update() {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.doUpdate()
      })
    },
    editConfig: function () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$confirm("确定更新？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.dataForm)
            updateConfig(req).then(() => {
              this.$message({
                type: 'success',
                message: '更新成功!',
              });
            });
            this.close()
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>
<style scoped>
.input-width {
  width: 50%;
}
</style>
