<template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">
          <el-button class="action-button" @click="toAdd()" type="primary" size="medium"
                     style="margin-left: 4px">
            增加
          </el-button>
        </div>
      </div>
      <el-table :data="goodsList" border stripe class="table" ref="multipleTable" height="700">
        <el-table-column prop="id" label="ID" width="80" align="left"></el-table-column>
        <el-table-column prop="goodsName" width="150" label="商品名称"></el-table-column>
        <el-table-column prop="goodsPic" width="200" label="商品图片"></el-table-column>
        <el-table-column prop="goodsDetails" label="商品描述"></el-table-column>
        <el-table-column prop="coinPrice" width="100" label="花费Chips"></el-table-column>
        <el-table-column prop="diamondPrice" width="100" label="花费钻石"></el-table-column>
        <el-table-column prop="voucherPrice" width="90" label="花费PUSD"></el-table-column>
        <el-table-column prop="showUsdt" width="120" label="显示USD价格"></el-table-column>
        <el-table-column prop="realUsdt" width="120" label="实际USD价格"></el-table-column>
        <el-table-column prop="sort" width="100" label="排序"></el-table-column>
        <el-table-column
            width="100"
            label="操作"
            fixed="right">
          <template slot-scope="scope">
            <el-button
                @click="toUpdate(scope.row)"
                type="text" size="small">编辑
            </el-button>
            <el-button
                @click="removeById(scope.row)"
                type="text" size="small">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog :before-close="handleClose" :modal="true" :title="dialogState.title"
               :visible.sync="dialogState.show" v-if="dialogState.show">
      <div style="width: 96%;">
        <el-form label-position="right" ref="objItem" :model="objItem" :rules="rules" label-width="160px" size="mini"
        >
          <el-form-item label="ID" prop="id" v-if="dialogState.update">
            <el-input v-model="objItem.id" :disabled="dialogState.update"></el-input>
          </el-form-item>
          <el-form-item label="名称" prop="goodsName">
            <el-input v-model="objItem.goodsName"></el-input>
          </el-form-item>
          <el-form-item label="商品图片" prop="goodsPic">
            <el-input v-model="objItem.goodsPic"></el-input>
          </el-form-item>
          <el-form-item label="商品详情" prop="goodsDetails">
            <el-input v-model="objItem.goodsDetails"></el-input>
          </el-form-item>
          <el-form-item label="花费Chips" prop="coinPrice">
            <el-input v-model="objItem.coinPrice"></el-input>
          </el-form-item>
          <el-form-item label="花费钻石" prop="diamondPrice">
            <el-input v-model="objItem.diamondPrice"></el-input>
          </el-form-item>
          <el-form-item label="花费PUSD" prop="voucherPrice">
            <el-input v-model="objItem.voucherPrice"></el-input>
          </el-form-item>
          <el-form-item label="显示USD价格" prop="showUsdt">
            <el-input v-model="objItem.showUsdt"></el-input>
          </el-form-item>
          <el-form-item label="实际USD价格" prop="realUsdt">
            <el-input v-model="objItem.realUsdt"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input v-model="objItem.sort"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">
          <el-button size="mini" @click="handleClose">取消</el-button>
          <el-button v-if="dialogState.update" size="mini" @click="updateGoods()" type="primary">修改</el-button>
          <el-button v-if="!dialogState.update" size="mini" @click="addGoods()" type="primary">增加</el-button>
        </div>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import moment from "moment";
// import {regionDataPlus} from 'element-china-area-data'
import {regionDataPlus} from 'element-china-area-data'
import {
  addExchangeGoods,
  exchangeGoodsList,
  removeExchangeById,
  updateExchangeGoods,
} from "../../../api/payment";

export default {
  name: "ExchangeList",

  data() {
    return {
      options: regionDataPlus,
      goodsList: [],

      // searchObj: {
      //   userId: null,
      //   isChain: null,
      //   mtSn: null
      // },
      // pagingOption: {
      //   page: 1,
      //   limit: 10,
      //   total: 0,
      // },

      objItem: {},

      // delMtItem: {
      //   id: null,
      //   mtSn: null,
      //   userId: null,
      //   reason: false,
      // },

      dialogState: {
        show: false,
        title: "修改配置",
        update: false
      },

      // delDialogState: {
      //   show: false,
      //   title: "删除MT",
      //   update: false
      // },
      rules: {
        ante: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        // ante: [{required: true, message: '不能为空', trigger: 'blur'}],
        id: [{required: true, message: '不能为空', trigger: 'blur'}],
        goodsName: [{required: true, message: '不能为空', trigger: 'blur'}],
        goodsPic: [{required: true, message: '不能为空', trigger: 'blur'}],
        goodsDetails: [{required: true, message: '不能为空', trigger: 'blur'}],
        coinPrice: [{required: true, message: '不能为空', trigger: 'blur'}],
        diamondPrice: [{required: true, message: '不能为空', trigger: 'blur'}],
        voucherPrice: [{required: true, message: '不能为空', trigger: 'blur'}],
        showUsdt: [{required: true, message: '不能为空', trigger: 'blur'}],
        realUsdt: [{required: true, message: '不能为空', trigger: 'blur'}],
        sort: [{required: true, message: '不能为空', trigger: 'blur'}],
      },

    };
  },
  created() {
    this.getExchangeList();
  },

  methods: {
    handleChange(value) {
      console.log(value)
    },
    search: function () {
      this.getExchangeList();
    },
    getExchangeList: function () {
      this.listLoading = true;

      return exchangeGoodsList().then((response) => {
        this.goodsList = response;
        this.listLoading = false;
      });
    },

    toUpdate(obj) {
      this.dialogState.show = true;
      this.dialogState.update = true;
      this.objItem = Object.assign({}, obj);
    },
    toAdd(obj) {
      this.dialogState.title = "增加数据";
      this.dialogState.show = true;
      this.dialogState.update = false;
      this.objItem = Object.assign({}, obj);
    },

    updateGoods() {
      this.$refs['objItem'].validate((valid) => {
        if (valid) {
          this.$confirm("确定更新？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.objItem)
            updateExchangeGoods(req).then(() => {
              this.$message({
                type: 'success',
                message: '更新成功!',
              });
              this.dialogState.show = false;
              this.getExchangeList();
            });
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    removeById(obj) {
      this.$confirm("确定删除？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
        center: true,
      }).then(() => {
        let req = {
          id: obj.id,
        };
        removeExchangeById(req).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
          this.getExchangeList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },

    addGoods() {
      this.$refs['objItem'].validate((valid) => {
        if (valid) {
          this.$confirm("确定增加？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.objItem)
            addExchangeGoods(req).then(() => {
              this.$message({
                type: 'success',
                message: '成功!',
              });
              this.dialogState.show = false;
              this.getExchangeList();
            });
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
    handleClose() {
      this.dialogState.show = false
    },
  }
}
</script>
<style>

.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>
