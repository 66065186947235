<template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between;">
          <el-input v-model="searchObj.userId" placeholder="用户id" clearable
                    style="width: auto;"/>

          <el-input v-model="searchObj.content" placeholder="CDK码" clearable
                    style="width: auto; margin-left: 4px"/>

          <el-input v-model="searchObj.inviteUserId" placeholder="邀请人id" clearable
                    style="width: auto; margin-left: 4px"/>

          <el-input v-model="searchObj.batch" placeholder="批次" clearable
                    style="width: auto; margin-left: 4px"/>

          <el-input v-model="searchObj.desc" placeholder="描述" clearable
                    style="width: auto; margin-left: 4px"/>

          <el-select
              v-model="searchObj.type"
              filterable
              clearable
              remote
              placeholder="类型"
              style="width: 160px; margin-left: 4px">
            <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>

          <el-select
              v-model="searchObj.channel"
              clearable
              placeholder="渠道"
              style="width: 160px; margin-left: 4px">
            <el-option
                v-for="item in AuthChannel"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>

          <el-select
              v-model="searchObj.active"
              filterable
              clearable
              remote
              placeholder="激活状态"
              style="width: 160px; margin-left: 4px">
            <el-option
                v-for="item in activeOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>

          <el-button class="action-button" @click="search()" type="primary" icon="el-icon-search" size="mini"
                     style="margin-left: 15px">
            搜索
          </el-button>
        </div>

      </div>
      <div style="display: flex;justify-content: flex-end;">
        <el-button class="action-button" type="text" @click="exportCsv">导出数据</el-button>
        <el-button class="action-button" type="text" @click="toCreate">创建CDK</el-button>
      </div>
      <el-table :data="list" border stripe class="table" ref="multipleTable">
        <el-table-column prop="id" label="ID" width="75" align="left"></el-table-column>
        <el-table-column prop="content" width="210" label="CDK">
          <template slot-scope="scope">
            <el-tag>
              {{ scope.row.content }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="120" label="使用次数">
          <template slot-scope="scope">
            <el-tag :type="scope.row.usageCount >= scope.row.maxUsageCount ? 'success' : '' ">
              {{ scope.row.usageCount + "/" + scope.row.maxUsageCount }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="active" width="100" label="状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.active ? 'success' : 'danger' ">
              {{ scope.row.active ? '激活' : '未激活' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="channel" width="260" label="渠道">
          <template slot-scope="scope">
            <el-tag
                style="margin: 2px"
                type="info"
                v-for="item in scope.row.channel"
                :key="item"
                size="mini">
              {{ item }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="funcList" width="400" label="发送物品">
          <template slot-scope="scope">
            {{ scope.row.funcList ? scope.row.funcList : '无' }}
          </template>
        </el-table-column>
        <el-table-column prop="type" width="110" label="类型">
          <template slot-scope="scope">
            <el-tag :type="scope.row.type === 'SINGLE' ? '' : 'info' ">
              {{ scope.row.type === 'SINGLE' ? '单码多用' : '单码单用' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="batch" width="180" label="批次"></el-table-column>
        <el-table-column prop="desc" width="200" label="描述"></el-table-column>

        <el-table-column prop="userId" width="150" label="指定用户ID"></el-table-column>
        <el-table-column prop="inviteUser" width="150" label="指定邀请人ID"></el-table-column>

        <el-table-column prop="validBeginTime" width="180" label="有效开始时间">
          <template slot-scope="scope">
            {{ scope.row.validBeginTime ? dateFormat(scope.row.validBeginTime) : "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="validEndTime" width="180" label="有效结束时间">
          <template slot-scope="scope">
            {{ scope.row.validEndTime ? dateFormat(scope.row.validEndTime) : "无" }}
          </template>
        </el-table-column>

        <el-table-column prop="createTime" width="180" label="创建时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>

        <el-table-column
            width="120"
            label="操作"
            fixed="right">
          <template slot-scope="scope">
            <el-button
                @click="toUpdate(scope.row)"
                type="text" size="small">编辑
            </el-button>
            <el-button
                @click="toUsage(scope.row.id)"
                type="text" size="small">使用详情
            </el-button>
          </template>
        </el-table-column>

        <!--        <el-table-column-->
        <!--            label="操作"-->
        <!--            width="180"-->
        <!--            fixed="right">-->
        <!--        </el-table-column>-->
      </el-table>
      <el-pagination v-if="list.length > 0" @current-change="getList"
                     :current-page.sync="pagingOption.page"
                     :page-size="pagingOption.limit" layout="total, prev, pager, next" :total="pagingOption.total">
      </el-pagination>
    </el-card>


    <el-dialog :before-close="handleClose" :modal="true" :title="dialogState.title"
               :visible.sync="dialogState.show" v-if="dialogState.show">
      <div style="width: 96%;">
        <el-form label-position="left" ref="objItem" :model="objItem" :rules="noticeRules" label-width="120px"
                 size="small">
          <el-form-item label="CDK类型" prop="type">
            <el-select
                v-model="objItem.type"
                filterable
                remote
                placeholder="请选择类型"
                :disabled="dialogState.update"
                style="width:100%;">
              <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="渠道" prop="channel">
            <el-select
                v-model="objItem.channel"
                multiple
                placeholder="请选择渠道"
                :disabled="dialogState.update"
                style="width:100%;">
              <el-option
                  v-for="item in AuthChannel"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="有效时间" prop="validTime">
            <el-date-picker
                style="width:100%;"
                :disabled="dialogState.update"
                v-model="objItem.validTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="有效开始日期"
                end-placeholder="有效结束日期"
                align="right">
            </el-date-picker>
          </el-form-item>

          <el-form-item v-if="objItem.type === typeOptions[0].value" label="(多用) CDK码">
            <el-input v-model="objItem.content"
                      maxlength="20"
                      show-word-limit
                      :disabled="dialogState.update"
                      @input="hexNumStr(true)"
                      placeholder="单码多用需指定CDK正文，且不能和之前的码重复，例如：张三的宣传码（ZHANSAN888）"/>
          </el-form-item>

          <el-form-item v-if="objItem.type === typeOptions[1].value" label="(单用)CDK批次">
            <el-input v-model="objItem.batch"
                      maxlength="15"
                      show-word-limit
                      :disabled="dialogState.update"
                      @input="hexNumStr(false)"
                      placeholder="输入批次，系统生成的不规律、不重复的码（以批次为前缀），例如：NB_ADFE、NB_1KDS、NB_LAKSDA"/>
          </el-form-item>

          <el-form-item v-if="!objItem.userId"
                        :label="objItem.type === typeOptions[0].value ? '(多用)使用次数' : '(单用)创建个数'"
                        prop="count">
            <el-input-number :disabled="dialogState.update"
                             style="width: 50%;"
                             size="small"
                             v-model="objItem.count"
                             :min="1" />
          </el-form-item>

          <el-form-item v-if="dialogState.update && objItem.type === 'SINGLE'">
            <el-input-number style="width: 50%;" size="small" v-model="objItem.addNum"
                             placeholder="增加数量 请填写在此框中"
                             :min="1"></el-input-number>
          </el-form-item>

          <el-form-item label="备注" prop="desc">
            <el-input v-model="objItem.desc"
                      placeholder="只做CDK描述，没什么卵用"/>
          </el-form-item>

          <el-divider>
          </el-divider>

          <el-form-item
              v-if="objItem.type === typeOptions[1].value && (objItem.inviteUser === '' ||  objItem.inviteUser === null)"
              label="限定用户ID">
            <el-input v-model="objItem.userId"
                      :disabled="dialogState.update"
                      placeholder="只允许此ID的用户使用，且系统只为改用户创建1个码，输入数量无效"/>
          </el-form-item>

          <el-form-item v-if="objItem.userId === '' || objItem.userId === null" label="推荐人用户ID">
            <el-input v-model="objItem.inviteUser"
                      :disabled="dialogState.update"
                      placeholder="只允许此ID用户的下级使用"/>
          </el-form-item>

          <el-form-item label="附件">
            <el-tag v-for="tag in objItem.funcList" :key="tag.param" :closable="!dialogState.update" type="primary"
                    @close="handleFuncListClose(tag)">
              {{ tag }}
            </el-tag>
            <el-button :disabled="dialogState.update" type="primary" size="mini" @click="handleAddFunc">+
              增加
            </el-button>
          </el-form-item>

          <el-form-item label="是否激活" prop="objItemActive">
            <el-switch
                v-model="objItem.active"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="激活"
                inactive-text="不激活">
            </el-switch>
          </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">
          <el-button @click="handleClose">取消</el-button>
          <el-button @click="dialogState.update ? update() : create()" type="primary">确认</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog :modal="true" :title="funcDialogState.title"
               :visible.sync="funcDialogState.show" v-if="funcDialogState.show" width="30%" append-to-body>
      <div style="width: 80%;">
        <el-form label-position="left" ref="funcForm" :model="funcForm" :rules="funcRules" label-width="120px"
                 size="mini ">

          <el-form-item label="类型" prop="type">
            <el-select v-model="funcForm.type" placeholder="选择类型"
                       size="medium">
              <el-option
                  v-for="item in FuncType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item v-if="this.funcForm.type === FuncType[0].value" label="选择道具" prop="param">
            <el-select v-model="funcForm.param" clearable filterable>
              <el-option v-for="item in itemList" :key="item.id" :label="item.name" :value="item.id"/>
            </el-select>
          </el-form-item>

          <el-form-item v-if="this.funcForm.type === FuncType[1].value" label="选择MT" prop="param">
            <el-select v-model="funcForm.param" clearable filterable>
              <el-option v-for="item in mtQualityList" :key="item.id" :label="item.name" :value="item.id"/>
            </el-select>
          </el-form-item>

          <el-form-item label="数量" prop="value">
            <el-input v-model.number="funcForm.value" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">
          <el-button @click="funcDialogState.show = false">取消</el-button>
          <el-button type="primary" @click="addFunc">添加</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import moment from "moment";
// import {regionDataPlus} from 'element-china-area-data'
import {create, page, update} from "@/api/cdk";
import {itemList, mtQualityList} from "@/api/game";
import {closeLoading, showLoading} from "@/utils/LoadingUtil";
import {exportCsv} from "@/utils/DownloadUtil";

export default {
  name: "Cdk",

  computed: {
    AuthChannel() {
      let option = this.$store.state.type.authChannel;
      let array = [{"id": 8, "name": "所有渠道", value: "ALL"}];
      option.forEach(o => {
        array.push(o);
      })
      return array;
    },

    FuncType() {
      return this.$store.state.type.funcType
    }
  },

  data() {
    return {
      list: [],
      itemList: [],
      mtQualityList: [],

      userCurrencyItem: [],

      typeOptions: [
        {"name": "单码多用", value: "SINGLE"},
        {"name": "单码单用", value: "MULTIPLE"}
      ],


      activeOptions: [
        {"name": "激活", value: true},
        {"name": "未激活", value: false}
      ],

      searchObj: {
        content: null,
        userId: null,
        inviteUserId: null,
        batch: null,
        desc: null,
        type: null,
        channel: null,
        active: null,
      },

      pagingOption: {
        page: 1,
        limit: 10,
        total: 0,
      },

      dialogState: {
        show: false,
        title: "创建CDK",
        update: false
      },

      funcDialogState: {
        show: false,
        title: '添加物品'
      },

      funcForm: {},

      objItem: {
        type: null,
        channel: null,
        desc: null,
        content: null,
        batch: null,
        userId: null,
        inviteUser: null,
        count: 1,
        addNum: null,
        validTime: null,
        validBeginTime: null,
        validEndTime: null,
        active: true,
        funcList: [],
      },

      noticeRules: {
        type: [
          {required: true, message: "不能为空！", trigger: "blur"}
        ],
        channel: [
          {required: true, message: "不能为空！", trigger: "blur"}
        ],
        desc: [
          {required: true, message: "不能为空！", trigger: "blur"}
        ],
        validTime: [
          {required: true, message: "不能为空！", trigger: "blur"}
        ],
        count: [
          {required: true, message: "不能为空！", trigger: "blur"},
          {type: 'number', message: '必须大于0', min: 1}
        ],
      },

      funcRules: {
        type: [
          {required: true, message: "不能为空！", trigger: "blur"},
        ],
        param: [
          {required: true, message: "不能为空！", trigger: "blur"},
        ],
        value: [
          {required: true, message: "不能为空！", trigger: "blur"},
          {type: 'number', message: '必须大于0', min: 1}

        ]
      },
    };
  },
  created() {
    this.getList();
    this.getItemList();
    this.getMtQualityList();
  },

  methods: {
    search: function () {
      this.pagingOption.page = 1;
      this.getList();
    },
    getList: function () {
      showLoading()
      let pageRequest = {
        pageNumber: this.pagingOption.page,
        pageSize: this.pagingOption.limit,
        userId: this.searchObj.userId,
        content: this.searchObj.content,
        inviteUserId: this.searchObj.inviteUserId,
        batch: this.searchObj.batch,
        desc: this.searchObj.desc,
        type: this.searchObj.type,
        channel: this.searchObj.channel ? "\"" + this.searchObj.channel + "\"" : null,
        active: this.searchObj.active,
      };
      return page(pageRequest).then((response) => {
        this.list = response.rows;
        this.pagingOption.total = response.total;
        closeLoading()
      }).catch(() => {
        closeLoading()
      });
    },

    toUsage(id) {
      sessionStorage.setItem("cdkTempId", id);
      this.$router.push({path: `/cdkUsage`})
    },

    toUpdate(obj) {
      this.dialogState.show = true;
      this.dialogState.title = "编辑CDK (" + obj.content + ")";
      this.dialogState.update = true;
      this.objItem = JSON.parse(JSON.stringify(obj));
      this.objItem.validTime = [new Date(obj.validBeginTime), new Date(obj.validEndTime)]
      this.objItem.count = obj.maxUsageCount
    },

    toCreate() {
      this.dialogState.show = true;
      this.dialogState.update = false;
      this.dialogState.title = "创建CDK";
      this.objItem.type = this.typeOptions[0].value;
      this.objItem.channel = this.AuthChannel[0].value;
    },

    create() {
      this.$refs['objItem'].validate(valid => {
        if (valid) {
          let req = JSON.parse(JSON.stringify(this.objItem));
          if (this.objItem.channel.find((c) => c === "ALL")) {
            req.channel = null;
          }
          if (req.channel != null) {
            if (req.channel.find((c) => c === "BEE") && !req.channel.find((c) => c === "BEE_WALLET")) {
              req.channel.push("BEE_WALLET")
            }
            if (!req.channel.find((c) => c === "BEE") && req.channel.find((c) => c === "BEE_WALLET")) {
              req.channel.push("BEE")
            }
          }
          req.validBeginTime = new Date(req.validTime[0]).getTime();
          req.validEndTime = new Date(req.validTime[1]).getTime();
          if (this.objItem.userId) {
            req.count = 1;
          }
          if (!req.funcList || req.funcList.length <= 0) {
            this.$message.error("附件不能为空");
            return
          }
          this.$confirm('确认创建？')
              .then(() => {
                showLoading()
                return create(req).then(r => {
                  if (r === true) {
                    this.$message.success("创建成功");
                    this.dialogClose();
                    this.getList();
                  } else {
                    this.$message.error("创建失败");
                  }
                  closeLoading()
                }).catch(() => {
                  closeLoading()
                })
              })
        }
      })
    },

    addFunc: function () {
      this.$refs['funcForm'].validate(valid => {
        if (valid) {
          if (!this.objItem.funcList) {
            this.objItem.funcList = [];
          }
          const tmpFunc = JSON.parse(JSON.stringify(this.funcForm))
          for (let i = 0; i < this.objItem.funcList.length; i++) {
            if (this.objItem.funcList[i].param === tmpFunc.param) {
              this.$message.error("重复添加")
              return
            }
          }
          this.objItem.funcList.push(tmpFunc)
          this.funcDialogState.show = false
        }
      })
    },

    update() {
      let req = {
        id: this.objItem.id,
        num: this.objItem.addNum,
        desc: this.objItem.desc,
        active: this.objItem.active,
      }
      this.$confirm('确认修改CDK ' + this.objItem.content + " ?")
          .then(() => {
            showLoading();
            return update(req).then(r => {
              if (r === true) {
                this.$message.success("修改成功");
                this.dialogClose();
                this.getList();
              } else {
                this.$message.error("修改失败");
              }
              closeLoading()
            }).catch(() => {
              closeLoading()
            })
          })
    },

    handleAddFunc() {
      this.funcForm = {type: 1, param: null, name: '', value: 1}
      this.funcDialogState.show = true
    },

    handleFuncListClose(tag) {
      this.objItem.funcList.splice(this.objItem.funcList.indexOf(tag), 1)
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },

    getItemList: function () {
      return itemList().then((response) => {
        this.itemList = response;
      });
    },

    getMtQualityList: function () {
      return mtQualityList().then((response) => {
        this.mtQualityList = response;
      });
    },

    handleClose() {
      this.$confirm('确认关闭？')
          .then(() => {
            this.dialogClose();
          })
    },

    dialogClose() {
      this.dialogState.show = false
      this.funcDialogState.show = false
      this.objItem = {
        type: null,
        channel: null,
        desc: null,
        content: null,
        batch: null,
        userId: null,
        inviteUser: null,
        count: 1,
        sourceCount: null,
        addNum: null,
        validTime: null,
        validBeginTime: null,
        validEndTime: null,
        active: true,
        funcList: []
      }
    },

    hexNumStr(isContent) {
      if (isContent) {
        let str = this.objItem.content;
        this.objItem.content = str.replace(/[^\dA-Z]/g, "")
        if ((str.match(/[^\dA-Z]/g, ""))) {
          this.$message.error("只允许大写字母和数字")
        }
      } else {
        let str = this.objItem.batch;
        this.objItem.batch = str.replace(/[^\dA-Z]/g, "")
        if ((str.match(/[^\dA-Z]/g, ""))) {
          this.$message.error("只允许大写字母和数字")
        }
      }
    },

    exportCsv() {
      if (this.pagingOption.total === 0) {
        this.$message.error("当前无数据")
        return
      }
      showLoading()
      let res = {
        pageNumber: 1,
        pageSize: this.pagingOption.total,
        userId: this.searchObj.userId,
        content: this.searchObj.content,
        inviteUserId: this.searchObj.inviteUserId,
        batch: this.searchObj.batch,
        desc: this.searchObj.desc,
        type: this.searchObj.type,
        channel: this.searchObj.channel,
        active: this.searchObj.active
      }
      return page(res).then(r => {
        this.download(r)
        this.$message.success("导出成功")
        closeLoading()
      }).catch(() => {
        this.$message.error("导出失败")
        closeLoading()
      })
    },

    download(list) {
      let rows = list.rows;
      let keys = [];
      Object.keys(rows[0]).forEach(key => {
        if (key !== 'deleted' && key !== 'updateTime') {
          if (key === "funcList") {
            keys.push({'key': key, 'type': 'list'})
          } else if (key === "validBeginTime" || key === "validEndTime" || key === "createTime" || key === "updateTime") {
            keys.push({'key': key, 'type': 'time'})
          } else {
            keys.push({'key': key, 'type': ''})
          }
        }
      });
      let title = '序号,CDK正文,用户ID,邀请人ID,类型,批次,描述,渠道,当前使用次数,最大使用次数,附件,有效开始时间,有效结束时间,激活状态,创建时间'
      exportCsv(rows, keys, title, "CDK");
    },

  }
}
</script>
<style>

</style>
