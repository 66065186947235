<template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between;">
          <el-input v-model="searchObj.cdkId" placeholder="输入cdk id" clearable
                    style="width: auto;"/>

          <el-input v-model="searchObj.cdkContent" placeholder="输入cdk" clearable
                    style="width: auto;"/>

          <el-input v-model="searchObj.userId" placeholder="输入用户id" clearable
                    style="width: auto;"/>

          <el-button class="action-button" @click="search()" type="primary" icon="el-icon-search" size="mini"
                     style="margin-left: 15px">
            搜索
          </el-button>
        </div>
        <div style="display: flex;justify-content: flex-end;">
          <el-button class="action-button" type="text" @click="exportCsv">导出记录</el-button>
        </div>
      </div>
      <el-table :data="list" border stripe class="table" ref="multipleTable">
        <el-table-column prop="id" label="ID" width="75" align="left"></el-table-column>
        <el-table-column prop="userId" label="用户id" width="300" align="left"></el-table-column>
        <el-table-column prop="cdkContent" width="200" label="CDK">
          <template slot-scope="scope">
            <el-tag>
              {{ scope.row.cdkContent }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="cdkId" width="100" label="CDK_ID"></el-table-column>
        <el-table-column prop="batch" width="200" label="批次"></el-table-column>

        <el-table-column prop="validBeginTime" width="180" label="使用时间">
          <template slot-scope="scope">
            {{ scope.row.usageTime ? dateFormat(scope.row.usageTime) : "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" width="180" label="创建时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>

      </el-table>
      <el-pagination v-if="list.length > 0" @current-change="getList"
                     :current-page.sync="pagingOption.page"
                     :page-size="pagingOption.limit" layout="total, prev, pager, next" :total="pagingOption.total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import moment from "moment";
// import {regionDataPlus} from 'element-china-area-data'
import {regionDataPlus} from 'element-china-area-data'
import {getUsage} from "@/api/cdk";
import {closeLoading, showLoading} from "@/utils/LoadingUtil";
import {exportCsv} from "@/utils/DownloadUtil";

export default {
  name: "CdkUsage",

  computed: {
    AuthChannel() {
      let option = this.$store.state.type.authChannel;
      let array = [{"id": 8, "name": "无渠道", value: ""}];
      option.forEach(o => {
        array.push(o);
      })
      return array;
    },
  },

  data() {
    return {

      options: regionDataPlus,
      list: [],

      userCurrencyItem: [],

      typeOptions: [
        {"name": "单码多用", value: "SINGLE"},
        {"name": "单码单用", value: "MULTIPLE"}
      ],


      activeOptions: [
        {"name": "激活", value: true},
        {"name": "未激活", value: false}
      ],

      searchObj: {
        cdkId: null,
        cdkContent: null,
        userId: null,
      },

      pagingOption: {
        page: 1,
        limit: 15,
        total: 0,
      },
    };
  },
  created() {
    let id = sessionStorage.getItem('cdkTempId');
    if (id) {
      this.searchObj.cdkId = id;
      sessionStorage.setItem('cdkTempId', '');
    }
    this.getList();
  },

  methods: {
    search: function () {
      this.pagingOption.page = 1;
      this.getList();
    },
    getList: function () {
      showLoading()
      // eslint-disable-next-line no-unused-vars
      let pageRequest = {
        pageNumber: this.pagingOption.page,
        pageSize: this.pagingOption.limit,
        cdkId: this.searchObj.cdkId,
        cdkContent: this.searchObj.cdkContent,
        userId: this.searchObj.userId,
      };

      return getUsage(pageRequest).then((response) => {
        this.list = response.rows;
        this.pagingOption.total = response.total;
        closeLoading();
      }).catch(() => {
        closeLoading();
      });
    },
    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },

    exportCsv() {
      if (this.pagingOption.total === 0) {
        this.$message.error("当前无数据")
        return
      }
      showLoading()
      let res = {
        pageNumber: 1,
        pageSize: this.pagingOption.total,
        cdkId: this.searchObj.cdkId,
        cdkContent: this.searchObj.cdkContent,
        userId: this.searchObj.userId,
      }
      return getUsage(res).then(r => {
        this.download(r)
        this.$message.success("导出成功")
        closeLoading()
      }).catch(() => {
        this.$message.error("导出失败")
        closeLoading()
      })
    },

    download(list) {
      let rows = list.rows;
      let keys = [];
      Object.keys(rows[0]).forEach(key => {
        if (key !== 'deleted' && key !== 'createTime' && key !== 'updateTime') {
          if (key === "funcList") {
            keys.push({'key': key, 'type': 'list'})
          } else if (key === "usageTime") {
            keys.push({'key': key, 'type': 'time'})
          } else {
            keys.push({'key': key, 'type': ''})
          }
        }
      });
      let title = '序号,用户ID,cdkId,cdk正文,批次,使用时间'
      exportCsv(rows, keys, title, "CDK_USAGE");
    },
  }
}
</script>
<style>

.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>
