<template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: flex-start; margin-bottom: 10px">
        <el-input
            size="medium"
            placeholder="输入用户ID"
            clearable
            style="width: 300px"
            v-model="searchObj.userId">
        </el-input>

        <el-input
            size="medium"
            placeholder="输入来源钱包地址"
            clearable
            style="width: 300px;margin-left: 4px"
            v-model="searchObj.fromWalletAddress">
        </el-input>

        <el-select v-model="searchObj.assetsCatalog" placeholder="请选择资产类型" clearable
                   style="width: 220px;margin-left: 4px" size="medium">
          <el-option
              v-for="item in assetsCatalog"
              :key="item.value"
              :label="item.value"
              :value="item.value">
          </el-option>
        </el-select>

        <el-select v-model="searchObj.chainId" placeholder="请选择链" clearable
                   style="width: 220px;margin-left: 4px" size="medium">
          <el-option
              v-for="item in ChainIdEnum"
              :key="item.value"
              :label="item.value"
              :value="item.value">
          </el-option>
        </el-select>
        <el-date-picker
            style="margin-left: 4px"
            size="medium"
            v-model="searchObj.searchTime"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right">
        </el-date-picker>
        <el-button class="action-button" @click="getRecord()" type="primary" icon="el-icon-search" size="small"
                   style="margin-left: 10px">
          搜索
        </el-button>
      </div>

      <el-table :data="list" border stripe class="table" ref="multipleTable" height="700">
        <el-table-column prop="id" label="ID" align="left"></el-table-column>
        <el-table-column prop="userId" width="280" label="用户ID"></el-table-column>
        <el-table-column prop="catalog" label="资产类型"></el-table-column>
        <el-table-column prop="tokenIds" min-width="200" label="NFT ID列表">
          <template slot-scope="scope">
            <el-tag
                type="success"
                style="margin: 4px"
                v-for="id in scope.row.tokenIds"
                :key="id"
                size="mini">
              {{ id }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="chainId" label="链" min-width="100"></el-table-column>
        <el-table-column prop="fromWalletAddress" min-width="280" label="来源钱包"></el-table-column>
        <el-table-column prop="depositState" min-width="100" label="状态"></el-table-column>
        <el-table-column prop="createTime" width="160" label="创建时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" width="160" label="更新时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.updateTime) }}
          </template>
        </el-table-column>

        <el-table-column
            label="操作"
            fixed="right">
          <template slot-scope="scope">
            <el-button
                @click="detailObj.show = true; detailObj.obj = scope.row"
                type="text" size="small">详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog :modal="true" title="详情"
                 :close-on-click-modal=false
                 :visible.sync="detailObj.show" v-if="detailObj.show">
        <el-descriptions :column=1>
          <el-descriptions-item label="交易签名">
            <el-card style="width: 100%">
              {{ detailObj.obj.txSign }}
            </el-card>
          </el-descriptions-item>
          <el-descriptions-item label="记录数据">
            <el-card style="width: 100%">
              <json-viewer
                  :value="JSON.parse(detailObj.obj.recordData)"
                  :expand-depth=20
                  copyable
                  boxed
                  sort/>
            </el-card>
          </el-descriptions-item>
        </el-descriptions>

      </el-dialog>

      <el-pagination v-if="list.length > 0" @current-change="getRecord()"
                     :current-page.sync="pageOption.page"
                     :page-size="pageOption.limit" layout="total, prev, pager, next" :total="pageOption.total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import moment from "moment";
import {getUserDepositNftRecord} from "@/api/appUser";

export default {
  name: "userDepositNftRecord",

  computed: {
    ChainIdEnum() {
      return this.$store.state.type.chainIdEnum;
    },
  },

  data() {
    return {

      list: [],
      searchObj: {
        searchTime: [],
        userId: null,
        state: null,
        assetsCatalog: null,
        chainId: null,
        fromWalletAddress: null,
      },

      assetsCatalog: [
        {value: 'KEY'},
        {value: 'ASSETS'}
      ],

      detailObj: {
        show: false,
        obj: null,
      },

      pageOption: {
        page: 1,
        limit: 20,
        total: 0,
      },

      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    };
  },
  mounted() {
    this.getRecord();
  },

  methods: {
    getRecord() {
      let p = {
        pageNumber: this.pageOption.page,
        pageSize: this.pageOption.limit,
        userId: this.searchObj.userId,
        fromWalletAddress: this.searchObj.fromWalletAddress,
        assetsCatalog: this.searchObj.assetsCatalog,
        chainId: this.searchObj.chainId,
        startTime: this.searchObj.searchTime != null && this.searchObj.searchTime[0] != null ? this.searchObj.searchTime[0].getTime() : null,
        endTime: this.searchObj.searchTime != null && this.searchObj.searchTime[1] != null ? this.searchObj.searchTime[1].getTime() : null
      };
      return getUserDepositNftRecord(p).then((response) => {
        this.list = response.rows;
        this.pageOption.total = response.total;
        console.log(this.list)
      });
    }
    ,

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    }
    ,
  }
}
</script>
<style>
</style>
