<template>
  <div>

    <el-card class="box-card">

      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">
          <span>当前登录服务器、跑步服务器状态：<el-tag
              style="margin-right: 10px">{{
              serverStatusOptions.find((s) => s.value === serverStatusEntity.serverStatus).label
            }}</el-tag></span>
        </div>
      </div>
      <el-form :inline="true" label-position="left" ref="serverItem" :model="serverItem" :rules="serverStatusRules"
               label-width="85px"
               size="small">
        <!--        <el-form-item label="玩家ID" prop="userId">-->
        <!--          <el-input v-model="newItem.userId"/>-->
        <!--        </el-form-item>-->
        <el-form-item label="登录状态" prop="serverStatus" label-position="left">
          <el-select v-model="serverItem.serverStatus" clearable placeholder="选择服务器状态"
                     style="width: 500px; margin-left: 4px">
            <el-option
                v-for="item in serverStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-button class="action-button" @click="changeServerStatus()" type="primary" size="small">修改服务器状态
        </el-button>
      </el-form>
    </el-card>

    <el-card class="box-card" style="margin-top: 10px">
      <el-button class="action-button" @click="reloadAgentCache()" type="primary" size="small" plain>
        重新加载代理服务器内存数据（公告、网关、白名单、服务器信息等, 开发人员专用）
      </el-button>

      <el-form :inline="true" label-position="left" ref="oneKeyPackageStatusItem" :model="oneKeyPackageStatusItem"
               label-width="120px"
               size="small" style="margin-top: 10px">
        <el-form-item label="" prop="serverStatus" label-position="left" label-width="80px">
          <el-select v-model="oneKeyPackageStatusItem.status" clearable placeholder="选择服务器状态">
            <el-option
                v-for="item in versionStateOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-button class="action-button" @click="oneKeyChangePackageStatus()" type="warning" size="small" plain>
          一键修改发布包状态
        </el-button>
      </el-form>

      <el-table :data="versionList" ref="multipleTable" row-key="id" border stripe
                style="margin-top: 15px;">
        <el-table-column prop="id" width="80" label="ID"></el-table-column>
        <el-table-column prop="packageId" min-width="180" label="包ID"></el-table-column>
        <el-table-column prop="platform" label="平台" min-width="100"></el-table-column>
        <el-table-column prop="version" label="最小登录版本"></el-table-column>
        <el-table-column prop="minVersion" label="最小兼容版本号" min-width="100"></el-table-column>
        <el-table-column prop="versionStatus" min-width="110" label="维护状态">
          <template slot-scope="scope">
            {{ versionStateOptions.find((s) => s.value === scope.row.versionStatus).label }}
          </template>
        </el-table-column>

        <el-table-column prop="audit" label="提审状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.audit ? 'success' : 'error' ">{{
                scope.row.audit ? '审核中' : '审核完成'
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="details" min-width="150" label="注释"></el-table-column>

        <el-table-column
            label="操作"
            width="99"
            fixed="right">
          <template slot-scope="scope">
            <el-button @click="toEditVersion(scope.row)" type="text" size="small">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog :before-close="handleClose" :modal="true" :title="dialogState.title"
               :visible.sync="dialogState.show" v-if="dialogState.show">
      <div style="width: 80%;">
        <el-form label-position="left" ref="newItem" :model="newItem" :rules="versionRules" label-width="120px"
                 size="small ">
          <el-form-item label="ID" prop="id" v-show="false">
            <el-input :disabled="false" v-model="newItem.id"/>
          </el-form-item>

          <el-form-item label="包ID" prop="channel" v-show="!dialogState.create">
            <el-input :disabled="!dialogState.create" v-model="newItem.packageId"/>
          </el-form-item>
          <el-form-item label="平台" prop="platform" v-show="!dialogState.create">
            <el-input :disabled="true" v-model="newItem.platform"/>
          </el-form-item>

          <el-form-item label="平台地址" prop="platformUrl" v-show="!dialogState.create">
            <el-input :disabled="true" v-model="newItem.platformUrl"/>
          </el-form-item>
          <el-form-item label="应用逻辑地址" prop="gameUrl" v-show="!dialogState.create">
            <el-input :disabled="true" v-model="newItem.gameUrl"/>
          </el-form-item>
          <el-form-item label="资源地址" prop="resourceUrl" v-show="!dialogState.create">
            <el-input :disabled="true" v-model="newItem.resourceUrl"/>
          </el-form-item>

          <el-form-item label="当前版本" prop="version" v-show="!dialogState.create">
            <el-input v-model="newItem.version"/>
          </el-form-item>

          <el-form-item label="兼容版本" prop="minVersion" v-show="!dialogState.create">
            <el-input v-model="newItem.minVersion"/>
          </el-form-item>

          <el-form-item label="维护状态" prop="versionStatus" v-show="!dialogState.create">
            <el-select v-model="newItem.versionStatus" clearable placeholder="请选择维护状态">
              <el-option v-for="item in versionStateOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="状态" prop="versionStatus" v-show="!dialogState.create">
            <el-switch
                v-model="newItem.audit"
                active-text="审核中"
                inactive-text="审核完成">
            </el-switch>
          </el-form-item>

          <el-form-item label="注释" prop="details" v-show="!dialogState.create">
            <el-input v-model="newItem.details"/>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="dialogState.action.call()">保存</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
// import {regionDataPlus} from 'element-china-area-data'
import {regionDataPlus} from 'element-china-area-data'
import {
  changeServerStatus,
  getServerStatus,
  oneKeyChangePackageStatus,
  reloadCache,
  updateVersion,
  versionList
} from "@/api/agent";

export default {
  name: "VersionList",

  data() {
    return {

      versionStateOptions: [
        {
          value: 1,
          label: '开启'
        },
        {
          value: 10,
          label: '维护'
        },
        {
          value: 2,
          label: 'IP白名单热更'
        },
        {
          value: 100,
          label: '设备白名单重定向'
        }],

      serverStatusOptions: [{
        value: 1,
        label: '开启'
      }, {
        value: 10,
        label: '维护'
      }],

      serverStatusEntity: {
        id: undefined,
        serverStatus: 1
      },

      options: regionDataPlus,
      versionList: [],

      dialogState: {
        title: "", // dialog 标题
        show: false, // dialog 显示控制
        disabled: false, // dialog 可编辑
        create: false, // dialog 是否为创建新用户（固定部分行不可编辑）
        action: null,
      },

      newItem: {
        id: null,
        packageId: null,
        platform: null,
        version: null,
        minVersion: null,
        platformUrl: null,
        gameUrl: null,
        resourceUrl: null,
        versionStatus: null,
        audit: false,
        details: null
      },

      serverItem: {
        id: null,
        serverStatus: null
      },

      oneKeyPackageStatusItem: {
        status: null
      },

      versionRules: {
        version: [
          {required: true, message: "请输入版本号！", trigger: "blur"},
          {min: 2, max: 10, message: "长度有误[50]", trigger: "blur"},
        ],
        minVersion: [
          {required: true, message: "请输入兼容版本号！", trigger: "blur"},
          {min: 2, max: 10, message: "长度有误[50]", trigger: "blur"},
        ],
        platformUrl: [
          {required: true, message: "请输入平台地址！", trigger: "blur"},
        ],
        versionStatus: [
          {required: true, message: "请选择状态！", trigger: "blur"},
        ],
        details: [
          {required: true, message: "请输入备注信息！", trigger: "blur"},
        ]
      },

      serverStatusRules: {
        serverStatus: [
          {required: true, message: "不能为空！", trigger: "blur"},
        ],
      },

    };
  },
  created() {
    this.getVersionList();
    this.getServerStatus();
  },

  methods: {

    handleChange(value) {
      console.log(value)
    },
    search: function () {
      // this.getList(null, this.keyword);
      this.getVersionList()
      // this.$message.error("点击搜索按钮,搜索信息:" + JSON.stringify(this.searchObj))
    },
    getVersionList: function () {
      this.listLoading = true;
      return versionList().then((response) => {
        this.versionList = response;
        console.log(response)
        this.listLoading = false;
      });
    },

    getServerStatus: function () {
      this.listLoading = true;
      return getServerStatus().then((response) => {
        this.serverStatusEntity = response;
        console.log(response)
        this.listLoading = false;
      });
    },

    changeServerStatus: function () {
      this.$refs['serverItem'].validate((valid) => {
        if (valid) {
          this.$confirm("确定开启修改登录和跑步服务器状态？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.serverItem)
            changeServerStatus(req).then(() => {
              this.$message({
                type: 'success',
                message: '操作成功!',
              });
              this.getServerStatus()
            });
            this.close()
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    oneKeyChangePackageStatus: function () {
      this.$confirm("确定一键修改发布包状态？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
        center: true,
      }).then(() => {
        let req = {
          status: this.oneKeyPackageStatusItem.status
        }
        oneKeyChangePackageStatus(req).then(() => {
          this.$message({
            type: 'success',
            message: '操作成功!',
          });
          this.getVersionList()
        });
        this.close()
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消更新",
        });
      });
    },

    reloadAgentCache: function () {
      this.$confirm("确定重新加载？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
        center: true,
      }).then(() => {

        reloadCache().then(() => {
          this.$message({
            type: 'success',
            message: '操作成功!',
          });
        });
        this.close()
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消更新",
        });
      });
    },

    toEditVersion(obj) {
      this.newItem = Object.assign({}, obj);
      this.dialogState = Object.assign(
          {},
          {
            title: "编辑版本数据",
            create: false,
            disabled: false,
            show: true,
            action: this.editVersion,
          }
      );
    },

    editVersion: function () {
      this.$refs['newItem'].validate((valid) => {
        if (valid) {
          this.$confirm("确定更新？", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
            center: true,
          }).then(() => {
            let req = Object.assign({}, this.newItem)
            updateVersion(req).then(() => {
              this.$message({
                type: 'success',
                message: '更新成功!',
              });
              this.getVersionList()
            });
            this.close()
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消更新",
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    close: function () {
      this.newItem = {}
      this.dialogState = {
        title: "",
        show: false,
        disabled: false,
        create: false,
        action: null,
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(() => {
            this.dialogState.show = false
            done();
          })
          .catch(() => {
          });
    },

    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
  }
}
</script>

<style>
.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>

