<template>
    <el-tree
            ref="createMenuTree"
            :props="treeProps"
            :data="nodes"
            empty-text="菜单无内容"
            node-key="id"
            :check-strictly="!multipleCheck"
            :check-on-click-node="true"
            :default-expand-all="expandByDefault"
            :show-checkbox="checkEnable"
            @check-change="handleCheckChange">
    </el-tree>
</template>

<script>
    /**
     * 菜单树形控件
     */
    export default {
        name: "SysMenuTreeCtrl",
        props: {
            /**
             * checkEnable=true时，决定是否多选，默认单选
             */
            multipleCheck: Boolean,
            /**
             * 开启选择功能，默认打开
             */
            checkEnable: Boolean,
            /**
             * 默认是否展开，默认true
             */
            expandByDefault: Boolean,
            /**
             * 必填内容，菜单树数据
             */
            nodes: Array,
            checkedNodesByDefault: [Array, Number],
            checkedNodeIdsByDefault: [Array, Number],
        },
        data() {
            return {
                treeProps: {
                    label: 'title',
                    children: 'children'
                },

                isCollapse: false,
                lastSelectedNode: null,
            }
        },
        created() {

        },
        mounted() {
            this.loadCheckedNodeIds(this.checkedNodeIdsByDefault);
            this.loadCheckedNodes(this.checkedNodesByDefault);
        },
        watch: {
            checkedNodeIdsByDefault(newVal) {
                this.loadCheckedNodeIds(newVal);
            },
            checkedNodesByDefault(newVal) {
                this.loadCheckedNodes(newVal);
            },
        },
        methods: {
            handleCheckChange: function (node, checked) {
                if (!this.checkEnable) {
                    return;
                }
                if (this.multipleCheck) {
                    // 多选
                    let selected = this.$refs.createMenuTree.getCheckedNodes();
                    this.$emit('onNodeSelectChange', node, checked, selected);
                } else {
                    // 单选
                    if (checked) {
                        // 选中，重置Tree的选中节点
                        this.$refs.createMenuTree.setCheckedKeys([node.id]);
                        // this.newMenuItem.parentId = node.id;
                        this.lastSelectedNode = node;
                        this.$emit('onNodeSelectChange', node, checked);
                    } else {
                        // 取消选择，排除选择切换的情况后如果取消的是当前节点，清空选择
                        if (node === this.lastSelectedNode) {
                            this.lastSelectedNode = null;
                            this.$emit('onNodeSelectChange', node, checked);
                        }
                    }
                }
            },
            loadCheckedNodes: function (nodes) {
                if (!nodes) {
                    return;
                }
                if (!Array.isArray(nodes)) {
                    nodes = [nodes];
                }
                this.$refs.createMenuTree.setCheckedNodes(nodes);
            },
            loadCheckedNodeIds: function (ids) {
                // console.log(ids);
                if (!ids) {
                    return;
                }
                if (!Array.isArray(ids)) {
                    ids = [ids];
                }
                this.$refs.createMenuTree.setCheckedKeys(ids);
            }
        }
    }
</script>

<style scoped>
    .menu-container {
        background-color: #2c3e50;
        color: #cccccc;
    }
</style>