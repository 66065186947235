// eslint-disable-next-line no-unused-vars
import {dateFormat} from "@/utils/timeUtil";
import moment from "moment";

function exportCsv(data, keys, title, name) {
    let csv = title + "\n";
    for (let i = 0; i < data.length; i++) {
        let obj = data[i];
        for (let j = 0; j < keys.length; j++) {
            if (j !== 0) {
                csv += ","
            }
            let k = keys[j]['key'];
            let t = keys[j]['type'];
            let o = obj[k];
            if (!o && o !== false && o !== 0) {
                csv += "";
            } else {
                if (t === "list") {
                    let str = JSON.stringify(o).replaceAll('"', "");
                    csv += '"'
                    csv += str;
                    csv += '"'
                } else if (t === "time" || t === "TIME") {
                    csv += dateFormat(o);
                } else {
                    csv += JSON.stringify(o);
                }
            }
        }
        csv += "\n"
    }

    let tag = document.createElement('a');
    tag.href = 'data:application/vnd.ms-excel;charset=utf-8,' + encodeURIComponent(csv);
    tag.download = name + "_" + moment(new Date()).format('YYYYMMDDHHmmss').toString() + '.csv';
    tag.click();

}


export {
    exportCsv,
}
