<template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">

          <el-input
              size="medium"
              placeholder="输入用户ID"
              v-model="searchObj.userId" clearable
              style="width: 400px;  margin-left: 4px">
          </el-input>

          <el-input
              size="medium"
              placeholder="输入地址"
              v-model="searchObj.address" clearable
              style="width: 400px;margin-left: 4px">
          </el-input>

          <el-button class="action-button" @click="search()" type="primary" icon="el-icon-search" size="medium"
                     style="margin-left: 4px">
            搜索
          </el-button>
        </div>

      </div>
      <el-table :data="userWalletAddressList" border stripe class="table" ref="multipleTable" height="700">
        <el-table-column prop="id" label="ID" width="80" align="left"></el-table-column>
        <el-table-column prop="userId" min-width="150" label="用户ID"></el-table-column>
        <el-table-column prop="walletAddress" min-width="200" label="钱包地址"></el-table-column>
        <el-table-column prop="remark" width="260" label="备注"></el-table-column>
        <el-table-column prop="defaultWallet" width="130" label="是否默认地址">
          <template slot-scope="scope">
            <el-tag size="mini">
              {{ scope.row.defaultWallet ? "是" : "否" }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" width="160" label="创建时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column
            width="100"
            label="操作"
            fixed="right">
          <template slot-scope="scope">
            <el-button
                @click="toDel(scope.row)"
                type="text" size="small">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog :before-close="handleDelClose" :modal="true" :title="delDialogState.title"
               :visible.sync="delDialogState.show" v-if="delDialogState.show">
      <div style="width: 96%;">
        <el-form label-position="left" ref="delAddressItem" :model="delAddressItem" label-width="120px" :rules="funcRules"
                 size="mini">
          <el-form-item label="ID" prop="id">
            <el-input size="medium" v-model="delAddressItem.id" disabled/>
          </el-form-item>

          <el-form-item label="地址" prop="address">
            <el-input size="medium" v-model="delAddressItem.address" disabled/>
          </el-form-item>
          <el-form-item label="用户ID" prop="userId">
            <el-input size="medium" v-model="delAddressItem.userId" disabled/>
          </el-form-item>
          <el-form-item label="删除原因" prop="reason">
            <el-input
                size="medium"
                placeholder="输入删除原因，可以是中文"
                v-model="delAddressItem.reason">
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-top: 50px;">
          <el-button @click="handleDelClose">取消</el-button>
          <el-button @click="removeAddressById()" type="primary">确认</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import moment from "moment";
// import {regionDataPlus} from 'element-china-area-data'
import {getUserAddressList, removeAddressById} from "@/api/appUser";
import {regionDataPlus} from 'element-china-area-data'

export default {
  name: "UserWalletAddressList",

  data() {
    return {
      options: regionDataPlus,
      userWalletAddressList: [],

      searchObj: {
        userId: null,
        address: null
      },

      delAddressItem: {
        id: null,
        address: null,
        userId: null,
        reason: false,
      },

      delDialogState: {
        show: false,
        title: "删除地址",
        update: false
      },
      funcRules: {
        reason: [
          {required: true, message: "不能为空！", trigger: "blur"},
        ]
      },
    };
  },
  created() {
    this.searchObj.userId = this.$route.query.userId;
    console.log(this.searchObj.userId)
    if (this.searchObj.userId == null) {
      return;
    }
    this.getUserAddressList();
  },

  methods: {
    handleChange(value) {
      console.log(value)
    },
    search: function () {
      this.getUserAddressList();
    },
    getUserAddressList: function () {
      this.listLoading = true;
      // if (this.searchObj.userId == null || this.searchObj.userId == '') {
      //   this.$message.warning("请输入玩家ID!!!");
      //   return;
      // }
      let req = {
        userId: this.searchObj.userId,
        address: this.searchObj.address,
      };

      return getUserAddressList(req).then((response) => {
        this.userWalletAddressList = response;
        this.listLoading = false;
      });
    },


    toDel(obj) {
      this.delDialogState.show = true;
      this.delAddressItem.id = obj.id;
      this.delAddressItem.address = obj.walletAddress;
      this.delAddressItem.userId = obj.userId;
      this.delAddressItem.reason = null;
    },

    removeAddressById() {
      this.$refs['delAddressItem'].validate(valid => {
        if (valid) {
          this.$confirm('确认删除 【' + this.delAddressItem.address + '】？').then(() => {
            let req = {
              id: this.delAddressItem.id,
              userId: this.delAddressItem.userId,
              reason: this.delAddressItem.reason
            }
            return removeAddressById(req).then(r => {
              if (r === true) {
                this.$message.success("删除成功");
                this.getUserAddressList();
              } else {
                this.$message.error("删除失败");
              }
              this.handleDelClose()
            }).catch(() => {
              this.handleDelClose()
            })
          })
        }
      })
    },


    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },

    handleDelClose() {
      this.delDialogState.show = false
    },
  }
}
</script>
<style>

.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>
