<template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">
          <el-input
              size="medium"
              placeholder="输入用户ID"
              v-model="searchObj.userId"
              style="width:400px; margin-left: 4px">
          </el-input>
          <el-button class="action-button" @click="search()" type="primary" icon="el-icon-search" size="mini"
                     style="margin-left: 4px">
            搜索
          </el-button>
        </div>
        <div style="float: right;">
          <el-tag size="medium" style="margin-right: 20px">当前积分数量: {{ integralAmount }} </el-tag>
        </div>
      </div>
      <el-table :data="integralList" border stripe class="table" ref="multipleTable" height="700">
        <el-table-column prop="id" label="ID" width="120"></el-table-column>
        <el-table-column prop="userId" min-width="150" label="用户ID"></el-table-column>
        <el-table-column prop="amount" width="150" label="数量"></el-table-column>
        <el-table-column prop="beforeAmount" width="130" label="更改前数量"></el-table-column>
        <el-table-column prop="afterAmount" width="100" label="更改后数量"></el-table-column>
        <el-table-column prop="recordType" width="270" label="记录类型"></el-table-column>
        <el-table-column prop="createTime" width="180" label="创建时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>

      </el-table>
      <el-pagination v-if="integralList.length > 0" @current-change="getUserIntegralPage"
                     :current-page.sync="pagingOption.page"
                     :page-size="pagingOption.limit" layout="total, prev, pager, next" :total="pagingOption.total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import moment from "moment";
// import {regionDataPlus} from 'element-china-area-data'
import {getUserIntegralAmount, getUserIntegralPage} from "@/api/appUser";
import {regionDataPlus} from 'element-china-area-data'

export default {
  name: "UserIntegralList",

  data() {
    return {
      options: regionDataPlus,
      integralList: [],
      integralAmount: 0,

      searchObj: {
        userId: null,
      },

      pagingOption: {
        page: 1,
        limit: 10,
        total: 0,
      },
    };
  },
  created() {
    this.searchObj.userId = this.$route.query.userId;

    console.log(this.searchObj.userId)
    if (this.searchObj.userId == null) {
      return;
    }
    this.getUserIntegralAmount();
    this.getUserIntegralPage();
  },

  methods: {
    handleChange(value) {
      console.log(value)
    },
    search: function () {
      this.getUserIntegralAmount();
      this.getUserIntegralPage();
    },
    getUserIntegralPage: function () {
      this.listLoading = true;
      if (this.searchObj.userId == null || this.searchObj.userId == '') {
        this.$message.warning("请输入玩家ID!!!");
        return;
      }
      let req = {
        pageNumber: this.pagingOption.page,
        pageSize: this.pagingOption.limit,
        userId: this.searchObj.userId,
      };

      return getUserIntegralPage(req).then((response) => {
        console.log(response.rows);
        this.integralList = response.rows;
        this.pagingOption.total = response.total;
        this.listLoading = false;
      });
    },

    getUserIntegralAmount: function () {
      this.listLoading = true;
      if (this.searchObj.userId == null || this.searchObj.userId == '') {
        this.$message.warning("请输入玩家ID!!!");
        return;
      }
      let req = {
        userId: this.searchObj.userId,
      };

      return getUserIntegralAmount(req).then((response) => {
        this.integralAmount = response;
        this.listLoading = false;
      });
    },


    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(() => {
            this.userCurrencyDialogState.show = false
            done();
          })
          .catch(() => {
          });
    },
  }
}
</script>
<style>

.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>
