<template>
  <div class="login-wrap">
    <div class="ms-login">
      <div class="ms-title">Welcome</div>
      <el-form :model="loginInfo" :rules="rules" ref="loginForm" label-width="0px" class="ms-content">
        <el-form-item prop="username">
          <el-input v-model="loginInfo.username" size="small">
            <template #prepend>
              <el-button icon="el-icon-user"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="loginInfo.password" size="small" show-password>
            <template #prepend>
              <el-button icon="el-icon-lock"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <!--        <el-form-item>-->
        <div class="login-btn">
          <el-button style="width: 100%" @click="login" type="primary" size="small">Login</el-button>
        </div>
        <!--        </el-form-item>-->
      </el-form>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Login',
  components: {},
  data() {
    return {
      loginInfo: {},
      rules: {
        username: [
          {required: true, message: 'can not be empty！', trigger: 'blur'},
          {min: 3, max: 50, message: 'length[3~50]', trigger: 'blur'}
        ],
        password: [
          {required: true, message: 'can not be empty！', trigger: 'blur'},
          {min: 6, max: 100, message: 'length[6~100]', trigger: 'blur'}
        ],
      },
    }
  },
  created() {
    // TODO vuex 检查登录状态
    document.onkeydown = event => {
      if (event.code === 'Enter') {
        this.login();
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    login: function () {
      this.$refs.loginForm.validate((result) => {
        if (result) {
          this.$store.dispatch("auth/login", {
            account: this.loginInfo.username,
            password: window.btoa(this.loginInfo.password),

          }).then((user) => {
            if (user) {
              this.$store.dispatch('sys/cleanHeaderTabs').then(() => {
                this.$message.success("sign in success");
                this.$router.push({path: '/home'});
              });
            }
          });
        }
      });
    }
  },
}
</script>

<style scoped>
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(./assets/img/login-bg.jpg);
  background-size: 100%;
}

.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  border-bottom: 1px solid #ddd;
}

.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 350px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
}

.ms-content {
  padding: 30px 30px;
}

.login-btn {
  text-align: center;
}

.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
</style>

