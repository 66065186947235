import request from '@/utils/request'

export function playerPage(data) {
    return request({
        url: '/manage/auth/userPage',
        method: 'post',
        data: data
    })
}

export function createRobot(params) {
    return request({
        url: '/manage/auth/createRobot',
        method: 'get',
        params: params
    })
}
