import { render, staticRenderFns } from "./noticeList.vue?vue&type=template&id=32f63c9a&"
import script from "./noticeList.vue?vue&type=script&lang=js&"
export * from "./noticeList.vue?vue&type=script&lang=js&"
import style0 from "./noticeList.vue?vue&type=style&index=0&id=32f63c9a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports