import request from '@/utils/request'

export function rechargeLevelList(data) {
    return request({
        url: '/manage/payment/rechargeLevelList',
        method: 'post',
        data: data,
    })
}

export function addRechargeLevel(data) {
    return request({
        url: '/manage/payment/addRechargeLevel',
        method: 'post',
        data: data,
    })
}

export function updateRechargeLevel(data) {
    return request({
        url: '/manage/payment/updateRechargeLevel',
        method: 'post',
        data: data,
    })
}

export function removeRechargeById(data) {
    return request({
        url: '/manage/payment/removeRechargeById',
        method: 'post',
        data: data,
    })
}

export function exchangeGoodsList(data) {
    return request({
        url: '/manage/payment/exchangeGoodsList',
        method: 'post',
        data: data,
    })
}

export function updateExchangeGoods(data) {
    return request({
        url: '/manage/payment/updateExchangeGoods',
        method: 'post',
        data: data,
    })
}

export function removeExchangeById(data) {
    return request({
        url: '/manage/payment/removeExchangeById',
        method: 'post',
        data: data,
    })
}

export function addExchangeGoods(data) {
    return request({
        url: '/manage/payment/addExchangeGoods',
        method: 'post',
        data: data,
    })
}

export function exchangeOrderList(data) {
    return request({
        url: '/manage/payment/exchangeOrderList',
        method: 'post',
        data: data,
    })
}

export function deliverOrderGoods(data) {
    return request({
        url: '/manage/payment/deliverOrderGoods',
        method: 'post',
        data: data,
    })
}

export function refundOrderGoods(data) {
    return request({
        url: '/manage/payment/exchangeRefund',
        method: 'post',
        data: data,
    })
}