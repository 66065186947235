import request from '@/utils/request'

export function menuView(query) {
    return request({
        url: '/sys/menu/view',
        method: 'get',
        params: query
    })
}

/**
 * 当前用户菜单树
 */
export function userTreeView(query) {
    return request({
        url: '/sys/menu/tree',
        method: 'get',
        params: query
    })
}

/**
 * 所有菜单树
 */
export function treeView(query) {
    return request({
        url: '/sys/menu/treeView',
        method: 'get',
        params: query
    })
}

/**
 * 所有菜单列表
 */
export function menuList(data) {
    return request({
        url: '/sys/menu/list',
        method: 'get',
        data: data
    })
}


export function createMenu(data) {
    return request({
        url: '/sys/menu',
        method: 'post',
        data: data
    })
}

export function updateMenu(data) {
    return request({
        url: '/sys/menu',
        method: 'put',
        data: data
    })
}

export function removeMenu(id) {
    return request({
        url: '/sys/menu/' + id,
        method: 'delete',
    })
}