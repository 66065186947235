<template>
  <div>
    <el-card class="box-card">
      <div style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">
          <el-input style=" width:200px;" size="medium" v-model="searchObj.userId"
                    placeholder="输入Id" clearable/>
          <el-input size="medium" v-model="searchObj.nickname" placeholder="昵称" clearable
                    style=" width:200px;margin-left: 4px"/>
          <el-select v-model="searchObj.robot" clearable placeholder="是否是机器人"
                     size="medium">
            <el-option label="是" value="true"/>
            <el-option label="否" value="false"/>
          </el-select>
          <el-button class="action-button" @click="search()" type="primary" icon="el-icon-search" size="mini"
                     style="margin-left: 4px"> 搜索
          </el-button>
          <el-button class="action-button" @click="createRobotDialogState.show = true" type="primary" size="mini"
                     style="margin-left: 4px"> 创建机器人
          </el-button>

        </div>
      </div>

      <el-table :data="playerList" border stripe class="table" ref="multipleTable">
        <el-table-column prop="id" label="ID" width="100" align="left"></el-table-column>
        <el-table-column prop="account" width="200" label="账号"></el-table-column>
        <el-table-column prop="registerChannel" width="110" label="注册渠道"></el-table-column>
        <el-table-column prop="nickname" width="150" label="昵称"></el-table-column>
        <el-table-column prop="robot" width="110" label="是否机器人">
          <template slot-scope="scope">
          {{ scope.row.robot === true ? '是' : '否'}}
          </template>
        </el-table-column>
        <el-table-column prop="robotSeq" width="120" label="机器人序号"></el-table-column>
        <el-table-column prop="beeId" width="200" label="beeId"></el-table-column>
        <el-table-column prop="beeAddress" width="200" label="beeAddress"></el-table-column>

        <el-table-column prop="userStatus" min-width="80" label="状态">
          <template slot-scope="scope">
            <el-tag size="mini" :type="userStatus.find(r => r.value === scope.row.userStatus) ?
                     userStatus.find(r => r.value === scope.row.userStatus).state : 'info'">
              {{
                userStatus.find(r => r.value === scope.row.userStatus) ? userStatus.find(r => r.value === scope.row.userStatus).label : "暂无"
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="registerIp" width="150" label="注册IP"></el-table-column>

        <el-table-column prop="createTime" width="180" label="创建时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="lastLoginTime" width="180" label="最后登录时间">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.lastLoginTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="robot" width="100" label="是否机器人">
          <template slot-scope="scope">
            {{ scope.row.robot }}
          </template>
        </el-table-column>
        <el-table-column prop="robotAi" width="100" label="机器AI级别"></el-table-column>
        <el-table-column
            label="操作"
            width="220"
            fixed="right">
          <template slot-scope="scope">
            <!--            <el-button @click="toChangeStatus(scope.row)" type="text" size="small">修改状态</el-button>-->
            <el-button @click="getGameStatic(scope.row.id)" type="text" size="small">统计数据</el-button>
            <!--            <el-button @click="gotoMtList(scope.row.id)" type="text" size="small">MT</el-button>-->
            <!--            <el-button @click="gotoBoxesList(scope.row.id)" type="text" size="small">盲盒</el-button>-->
            <!--            <el-button @click="gotoIntegralList(scope.row.id)" type="text" size="small">积分</el-button>-->
            <!--            <el-button @click="gotoAddressList(scope.row.id)" type="text" size="small">地址</el-button>-->

          </template>
        </el-table-column>
      </el-table>
      <el-pagination v-if="playerList.length > 0" @current-change="getPlayerList"
                     :current-page.sync="pagingOption.page"
                     :page-size="pagingOption.limit" layout="total, prev, pager, next" :total="pagingOption.total">
      </el-pagination>
    </el-card>


    <el-dialog :modal="true" :title="userGameStaticDialogState.title"
               :close-on-click-modal=false
               :visible.sync="userGameStaticDialogState.show" v-if="userGameStaticDialogState.show">
      <el-tabs value="first">
        <el-tab-pane label="筹码场" name="first">
          <el-descriptions class="margin-top" title="Chips统计数据" :column="4" size="mini" border>
            <el-descriptions-item>
              <template slot="label">Total Games</template>
              {{ gameStatistic.chipsVO.scenes }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">Total Hands</template>
              {{ gameStatistic.chipsVO.hands }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">场均战绩</template>
              {{ gameStatistic.chipsVO.avgResult }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">场均带入</template>
              {{ gameStatistic.chipsVO.avgTakeIn }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">VPIP</template>
              {{ gameStatistic.chipsVO.vpip }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">PFR</template>
              {{ gameStatistic.chipsVO.pfr }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">ETF</template>
              {{ gameStatistic.chipsVO.etf }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">RR</template>
              {{ gameStatistic.chipsVO.rr }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">WTSD</template>
              {{ gameStatistic.chipsVO.wtsd }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">CBet</template>
              {{ gameStatistic.chipsVO.cbet }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">WSD</template>
              {{ gameStatistic.chipsVO.wsd }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">CR</template>
              {{ gameStatistic.chipsVO.cr }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">CCPF</template>
              {{ gameStatistic.chipsVO.ccpf }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">AllIn</template>
              {{ gameStatistic.chipsVO.allIn }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">WAR</template>
              {{ gameStatistic.chipsVO.war }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">AF</template>
              {{ gameStatistic.chipsVO.af }}
            </el-descriptions-item>
          </el-descriptions>
          <el-table :data="gameStatistic.chipsVO.hundredBBList" border stripe class="table" ref="multipleTable"
                    height="150" size="mini">
            <el-table-column prop="bb" label="大盲"></el-table-column>
            <el-table-column prop="value" label="大盲白手"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="金币场" name="second">
          <el-descriptions class="margin-top" title="Gold统计数据" :column="4" size="mini" border>
            <el-descriptions-item>
              <template slot="label">Total Games</template>
              {{ gameStatistic.goldVO.scenes }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">Total Hands</template>
              {{ gameStatistic.goldVO.hands }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">场均战绩</template>
              {{ gameStatistic.goldVO.avgResult }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">场均带入</template>
              {{ gameStatistic.goldVO.avgTakeIn }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">VPIP</template>
              {{ gameStatistic.goldVO.vpip }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">PFR</template>
              {{ gameStatistic.goldVO.pfr }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">ETF</template>
              {{ gameStatistic.goldVO.etf }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">RR</template>
              {{ gameStatistic.goldVO.rr }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">WTSD</template>
              {{ gameStatistic.goldVO.wtsd }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">CBet</template>
              {{ gameStatistic.goldVO.cbet }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">WSD</template>
              {{ gameStatistic.goldVO.wsd }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">CR</template>
              {{ gameStatistic.goldVO.cr }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">CCPF</template>
              {{ gameStatistic.goldVO.ccpf }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">AllIn</template>
              {{ gameStatistic.goldVO.allIn }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">WAR</template>
              {{ gameStatistic.goldVO.war }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">AF</template>
              {{ gameStatistic.goldVO.af }}
            </el-descriptions-item>
          </el-descriptions>
          <el-table :data="gameStatistic.goldVO.hundredBBList" border stripe class="table"
                    height="150" size="mini">
            <el-table-column prop="bb" label="大盲"></el-table-column>
            <el-table-column prop="value" label="大盲百手"></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeGameStatisticDialog">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog :modal="true" :title="changeStatusDialogState.title"
               :close-on-click-modal=false
               :visible.sync="changeStatusDialogState.show" v-if="changeStatusDialogState.show">
      <el-form label-position="left" ref="userStatusItem" :model="userStatusItem" label-width="120px"
               :rules="userStatusRules"
               size="small">
        <el-form-item label="用户ID" prop="id">
          <el-input size="medium" disabled v-model="userStatusItem.id"></el-input>
        </el-form-item>
        <el-form-item label="账号状态" prop="banned">
          <el-switch
              v-model="userStatusItem.active"
              active-text="正常"
              inactive-text="封禁中">
          </el-switch>
        </el-form-item>
        <el-form-item label="原因" prop="reason">
          <el-input size="medium" v-model="userStatusItem.reason" placeholder="输入原因，可以是中文"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeUserStatusDialog">取消</el-button>
        <el-button @click="updateUserStatus()" type="primary">确认</el-button>
      </div>
    </el-dialog>

    <el-dialog :modal="true" :title="createRobotDialogState.title"
               :close-on-click-modal=false
               :visible.sync="createRobotDialogState.show" v-if="createRobotDialogState.show">
      <el-form label-position="left" ref="createRobotForm" :model="createRobotForm" label-width="120px"
               :rules="userStatusRules"
               size="small">
        <el-form-item label="创建数量" prop="num">
          <el-input size="medium" v-model="createRobotForm.num"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click=" createRobotDialogState.show = false">取消</el-button>
        <el-button @click="createRobot()" type="primary">确认</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import moment from "moment";
// import {regionDataPlus} from 'element-china-area-data'
import {updateUserStatus} from "@/api/appUser";
import {regionDataPlus} from 'element-china-area-data'
import {closeLoading} from "@/utils/LoadingUtil";
import {createRobot, playerPage} from "@/api/authorization";
import {getGameStatistic} from "@/api/hall";

export default {
  name: "PlayerList",

  data() {
    return {
      options: regionDataPlus,
      playerList: [],

      userStatus: [
        {value: 'ACTIVE', label: "正常", state: 'info', active: true},
        {value: 'BANNED', label: "封禁", state: 'danger', active: false}
      ],

      userGameStaticDialogState: {
        title: "", // dialog 标题
        show: false, // dialog 显示控制
        disabled: false, // dialog 可编辑
        create: false, // dialog 是否为创建新用户（固定部分行不可编辑）
        action: null,
      },

      changeStatusDialogState: {
        title: "修改用户状态", // dialog 标题
        show: false, // dialog 显示控制
        disabled: false, // dialog 可编辑
        create: false, // dialog 是否为创建新用户（固定部分行不可编辑）
      },

      createRobotDialogState: {
        title: "创建机器人", // dialog 标题
        show: false, // dialog 显示控制
        disabled: false, // dialog 可编辑
        create: false, // dialog 是否为创建新用户（固定部分行不可编辑）
      },

      gameStatistic: {
        chipsVO: null,
        goldVO: null,
      },

      userStatusItem: {
        id: null,
        active: null,
        reason: null,
      },

      createRobotForm: {
        num: 0,
      },

      searchObj: {
        userId: null,
        nickname: null,
        robot: null,
      },

      pagingOption: {
        page: 1,
        limit: 10,
        total: 0,
      },

      userStatusRules: {
        reason: [
          {required: true, message: "不能为空！", trigger: "blur"}
        ],
        num: [
          {required: true, message: "不能为空！", trigger: "blur"}
        ],
      },
    };
  },
  created() {
    // this.getPlayerList();
  },

  methods: {
    handleChange(value) {
      console.log(value)
    },
    search: function () {
      this.getPlayerList();
    },
    getPlayerList: function () {
      this.listLoading = true;
      // eslint-disable-next-line no-unused-vars
      let pageRequest = {
        pageNumber: this.pagingOption.page,
        pageSize: this.pagingOption.limit,
        userId: this.searchObj.userId,
        nickname: this.searchObj.nickname,
        robot: this.searchObj.robot,
      };

      return playerPage(pageRequest).then((response) => {
        console.log(response)
        this.playerList = response.rows;
        this.pagingOption.total = response.total;
        this.listLoading = false;
      });
    },

    getGameStatic: function (uid) {
      let req = {
        id: uid
      };
      return getGameStatistic(req).then((response) => {
        this.gameStatistic = response;
        console.log(response);
        this.userGameStaticDialogState = Object.assign(
            {},
            {
              title: "用户统计数据",
              create: false,
              disabled: false,
              show: true
            }
        );
      });
    },

    toChangeStatus: function (obj) {
      this.changeStatusDialogState.show = true
      this.userStatusItem.id = obj.id
      this.userStatusItem.active = this.userStatus.find(r => r.value === obj.status).active
    },

    updateUserStatus() {
      this.$refs['userStatusItem'].validate(valid => {
        if (valid) {
          this.$confirm('确认修改用户状态？').then(() => {
            let req = {
              id: this.userStatusItem.id,
              status: this.userStatus.find(r => r.active === this.userStatusItem.active).value,
              reason: this.userStatusItem.reason,
            }
            return updateUserStatus(req).then(r => {
              if (r === true) {
                this.$message.success("success");
              } else {
                this.$message.error("fail");
              }
              this.getPlayerList();
              closeLoading()
            }).catch(() => {
              closeLoading()
            })
          })
        }
      })
    },

    createRobot() {
      this.$refs['createRobotForm'].validate(valid => {
        if (valid) {
          this.$confirm('确认创建机器人？').then(() => {
            let req = {
              num: this.createRobotForm.num,
            }
            return createRobot(req).then(r => {
              if (r === true) {
                this.$message.success("success");
              } else {
                this.$message.error("fail");
              }
              closeLoading()
            }).catch(() => {
              closeLoading()
            })
          })
        }
      })
    },

    gotoMtList: function (uid) {
      this.$router.push({
        path: '/userMtList',
        query: {userId: uid}
      })
    },

    gotoBoxesList: function (uid) {
      this.$router.push({
        path: '/userBoxesList',
        query: {userId: uid}
      })
    },

    gotoIntegralList: function (uid) {
      this.$router.push({
        path: '/userIntegralList',
        query: {userId: uid}
      })
    },

    gotoAddressList: function (uid) {
      this.$router.push({
        path: '/userWalletAddressList',
        query: {userId: uid}
      })
    },

    toRecord(type, userId) {
      sessionStorage.setItem("userTmpCurrency", JSON.stringify({type: type, userId: userId}));
      this.$router.push({path: '/userCurrencyRecord'})
    },


    dateFormat(timeStamp) {
      if (timeStamp == null) {
        return "";
      }
      return moment(new Date(timeStamp)).format('YYYY-MM-DD HH:mm:ss');
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(() => {
            this.userGameStaticDialogState.show = false
            done();
          })
          .catch(() => {
          });
    },

    closeUserStatusDialog() {
      this.changeStatusDialogState.show = false
    },

    closeGameStatisticDialog() {
      this.userGameStaticDialogState.show = false
    },
  }
}
</script>
<style>

.el-dialog__header {
  background-color: #2c3e50;

}

.el-dialog__header .el-dialog__title {
  color: #ecf8ff;
}
</style>
